import { t } from "i18next";
import { setIsLoading } from "../store";

export async function GetSalesModesTypesAndTags(
    userID: any,
    franchiseID: any,
    shopId: any
) {
    setIsLoading(true);
    const newList: any[] = [];
    const newListSaleMode: any[] = [];
    const newListSaleSupport: any[] = [];
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";

    try {
        const [
            tagsResponse,
            saleModeResponse,
            saleSupportResponse,
        ] = await Promise.all([
            fetch(
                `${
                    process.env.REACT_APP_API_V2_URL
                }/settings/general/tag?userId=${userID}&shopId=${"0"}&franchiseId=${franchiseID}`
            ),
            fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/generalList?userId=${userID}&franchiseId=${franchiseID}&type=mode_of_sale_list`,
                {
                    method: "GET",
                    headers: { "Accept-Language": i18nextLng },
                }
            ),
            fetch(
                `${process.env.REACT_APP_API_V2_URL}/settings/general/saleSupport?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`,
                {
                    method: "GET",
                    headers: { "Accept-Language": i18nextLng },
                }
            ),
        ]);

        // Ensure all responses are OK
        if (
            !tagsResponse.ok ||
            !saleModeResponse.ok ||
            !saleSupportResponse.ok
        ) {
            throw new Error("Failed to fetch one or more resources");
        }

        const tagsData = await tagsResponse.json();
        const saleModeData = await saleModeResponse.json();
        const saleSupportData = await saleSupportResponse.json();

        for (const element of tagsData.data) {
            newList.push(element.Designation);
        }

        for (const element of saleModeData.data) {
            newListSaleMode.push({
                value: element.Id,
                label: element.Designation,
            });
        }

        for (const element of saleSupportData.data) {
            newListSaleSupport.push(element[t("Sale support")]);
        }
        setIsLoading(false);
        return {
            tags: newList,
            saleMode: newListSaleMode,
            ListSaleSupport: newListSaleSupport,
        };
    } catch (error) {
        setIsLoading(false);
        console.error("Error fetching sales modes/types/tags:", error);
        throw error; // Optionally re-throw the error for further handling
    }
}
