import React from "react";
import { AvForm } from "availity-reactstrap-validation";
import { CardBody, Card } from "reactstrap";

import PrintNote from "../PrintNote";
import PrintKitchenTicket from "../PrintKitchenTicket";
import PrintCustomerInformation from "../PrintCustomerInformation";

function UserCard({ rowData }: any) {
    return (
        <Card
            style={{
                fontSize: "17px",
            }}
        >
            <AvForm>
                <CardBody>
                    <PrintNote />
                    <div className="border-footer__clz p-1" />
                    <PrintKitchenTicket rowData={rowData} />
                    {/* <div className="border-footer__clz p-1" />
                    <PrintCustomerAddress rowData={rowData} /> */}
                    <div className="border-footer__clz p-1" />
                    <PrintCustomerInformation rowData={rowData} />
                </CardBody>
            </AvForm>
        </Card>
    );
}

export default UserCard;
