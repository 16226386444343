import React from "react";
import classnames from "classnames";
import {
    CheckboxIcon,
    PlusIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { uuid } from "uuidv4";

import { store } from "../../../../components/VerticalLayout/store";
import {
    setIsPlusIconClicked,
    store as generalSettingStore,
    setIsValidateArchiveOpened,
    setActions,
    setIsActionsClicked,
    setIsEdited,
    setIsLoading,
    setKey,
} from "../store";

import ErrorToast from "@components/Common/ErrorTost";

import { CustomMainColorIconButton } from "@components/Common/CustomMainColorIconButton";
import { CustomMainColorButtonWithIcon } from "@components/Common/CustomMainColorButtonWithIcon";
import { languages } from "@constants/index";

import "../index.css";
import { generalConfigStore, setItemsDisable } from "@store";

type CustomSideFilterType = {
    setLanguage: Function;
    setShopId: Function;
    selectedRows: any[];
    Language: any[];
    setIsInInitializationModalOpened: Function;
    setDataIsUpdated: Function;
    dataIsUpdated: number | boolean;
    isHistoryModalOpened: boolean;
    setIsHistoryModalOpened: Function;
    setLocalFilterActive: Function;
    setIsModalOpened: Function;
    isModalOpened: boolean;
    setIsArchivedLanguagesClicked: Function;
    isArchivedLanguagesClicked: boolean;
    setIsAddLanguageClicked: Function;
    isAddLanguageClicked: boolean;
};

export function CustomSideFilter({
    setLanguage,
    setShopId,
    selectedRows,
    Language,
    setIsInInitializationModalOpened,
    setDataIsUpdated,
    dataIsUpdated,
    isHistoryModalOpened,
    setIsHistoryModalOpened,
    setLocalFilterActive,
    setIsModalOpened,
    isModalOpened,
    setIsArchivedLanguagesClicked,
    isArchivedLanguagesClicked,
    setIsAddLanguageClicked,
    isAddLanguageClicked,
}: CustomSideFilterType) {
    const { t } = useTranslation();
    const { shopID, oneShop, userID, franchiseID, operatorID } = useSnapshot(
        store
    );
    const { itemsDisable } = useSnapshot(generalConfigStore);
    const {
        numberArchived,
        numberActive,
        shopsLanguagesData,
        archivedLanguageFranchise,
        isEdited,
        isActionsClicked,
        modify,
        isLoading,
    } = useSnapshot(generalSettingStore);
    let dataAssociate: any[] = [];
    if (!oneShop) {
        dataAssociate = AssociateLanguages();
    }
    let dataArchive: any[] = [];
    if (!oneShop) {
        dataArchive = ArchivedLanguages();
    } else {
        dataArchive = ArchivedLanguagesFranchise();
    }
    const uuidUser = localStorage.getItem("uuidUser");
    function ArchivedLanguagesFranchise() {
        let idsAssociated: any[] = [];
        if (archivedLanguageFranchise !== undefined) {
            archivedLanguageFranchise.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function ArchivedLanguages() {
        let idsAssociated: any[] = [];
        if (shopsLanguagesData !== undefined) {
            shopsLanguagesData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    idsAssociated.push(el.Id);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function AssociateLanguages() {
        let idsAssociated: any[] = [];
        if (shopsLanguagesData !== undefined) {
            shopsLanguagesData.forEach((element: any) => {
                if (element.subRows !== undefined) {
                    element.subRows.forEach((el: any) => {
                        idsAssociated.push(el.Id);
                    });
                }
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    function isLanguageExist() {
        let isExist = false;
        selectedRows.forEach((el: any) => {
            if (dataAssociate.includes(el.Id)) {
                isExist = true;
            }
        });
        if (isExist) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
            setIsLoading(false);
        } else {
            setIsLoading(false);
            setIsValidateArchiveOpened(true);
        }
    }

    async function activeAllLanguage() {
        setIsLoading(true);
        toast.dismiss();
        let localData: any = {};
        let item: any = [];
        selectedRows.forEach(async (el: any) => {
            if (el.shopId !== "0") {
                if (
                    itemsDisable.find(
                        (elem: any) =>
                            elem.shopId === el.shopId && elem.id === el.Id
                    ) === undefined
                ) {
                    item.push({
                        id: el.Id,
                        state: false,
                        shopId: el.shopId,
                    });
                }
            }

            const id = el.Id;
            Object.assign(localData, {
                [id]: {
                    id: id,
                    language: languages.find(
                        (lg: any) => lg.value === el.abbreviation
                    )?.label,
                    display_name: el.display_name,
                    country: el.country,
                    urlImage: el.url_image,
                    isArchived: false,
                    shopId: el.shopId,
                    isFavorite: el.isFavorite,
                    isActive: el.shopId === "0" ? true : false,
                    abbreviation: el.abbreviation,
                    advancedDisplayName: el.advancedDisplayName,
                },
            });
        });
        let dataLanguage = {
            userId: userID,
            shopId: [shopID],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            isShop: false,
            data: localData,
        };
        const data = new FormData();
        data.append("dataLanguage", JSON.stringify(dataLanguage));
        data.append("image", "");
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/language`;
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, {
                    method: "POST",
                    body: data,
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        if (shopID !== "0") {
                            setItemsDisable(item);
                            localStorage.setItem(
                                "itemsDisable",
                                JSON.stringify(item)
                            );
                        }
                        toast.success(
                            `${t("The language was successfully unarchived")}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setKey(uuid());
                        setIsLoading(false);
                        setDataIsUpdated(!dataIsUpdated);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsLoading(false);
        }
    }

    return (
        <div className="d-flex justify-content-start align-items-center ml-2">
            {modify === true ? (
                <>
                    {isAddLanguageClicked !== true ? (
                        <span
                            onClick={() => {
                                setLanguage([]);
                                setIsAddLanguageClicked(!isAddLanguageClicked);
                                setShopId(oneShop ? shopID : 0);
                                setLocalFilterActive(false);
                            }}
                            className={`${classnames("w-100 cmn_btn_openForm", {
                                "not-allowed-icon__clz": isArchivedLanguagesClicked,
                            })}`}
                        >
                            <CustomMainColorButtonWithIcon
                                icon="PlusIcon"
                                iconPosition="left"
                                rounded
                                variant="primary"
                                style={{ marginLeft: "5px !important" }}
                            >
                                {t("Add a language")}
                            </CustomMainColorButtonWithIcon>
                        </span>
                    ) : (
                        <CustomMainColorIconButton
                            icon="PlusIcon"
                            className="lan_btn_FRreset"
                            rounded
                            disabled={Language.length === 0 ? false : true}
                            onClick={() => {
                                if (isEdited === true)
                                    setIsInInitializationModalOpened(true);
                                else {
                                    setIsPlusIconClicked(true);
                                }
                            }}
                        >
                            <PlusIcon height={20} width={20} fill="white" />
                        </CustomMainColorIconButton>
                    )}
                </>
            ) : null}
            <VerticalDotsIcon
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
                className="cmn_drp_FRmenu"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item cmn_btn_FRlistActivated pl-1 "
                    )}
                    onClick={() => {
                        setIsArchivedLanguagesClicked(false);
                    }}
                >
                    {t("Enabled language(s)")}({numberActive})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item cmn_btn_FRlistArchived pl-1 "
                    )}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsEdited(false);
                                setIsAddLanguageClicked(false);
                                setIsArchivedLanguagesClicked(true);
                            });
                        } else {
                            setIsAddLanguageClicked(false);
                            setIsArchivedLanguagesClicked(true);
                        }
                    }}
                >
                    {t("Archived language(s)")}({numberArchived})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        "dropdown-item cmn_btn_FRhistory pl-1",
                        {
                            "not-allowed-icon__clz": selectedRows.length === 0,
                        }
                    )}`}
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsEdited(false);
                                setIsAddLanguageClicked(false);
                                if (selectedRows.length === 1) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else
                                    setIsHistoryModalOpened(
                                        !isHistoryModalOpened
                                    );
                            });
                        } else {
                            if (selectedRows.length === 1) {
                                ErrorToast(
                                    `${t(
                                        "Attention, it is a multiple action. Please select at least two items"
                                    )!}`
                                );
                            } else
                                setIsHistoryModalOpened(!isHistoryModalOpened);
                        }
                    }}
                >
                    {t("Historical")}
                </div>
                {modify === true ? (
                    <>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                "dropdown-item cmn_btn_FRarchived cmn_btn_FRunarchive pl-1",
                                {
                                    "not-allowed-icon__clz":
                                        isLoading ||
                                        selectedRows.length === 0 ||
                                        (isArchivedLanguagesClicked &&
                                            oneShop &&
                                            selectedRows.some((element: any) =>
                                                dataArchive.includes(element.Id)
                                            )),
                                }
                            )}`}
                            onClick={(e: any) => {
                                setIsLoading(true);
                                if (isEdited === true) {
                                    setIsLoading(false);
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        setIsAddLanguageClicked(false);
                                        if (selectedRows.length === 1) {
                                            ErrorToast(
                                                `${t(
                                                    "Attention, it is a multiple action. Please select at least two items"
                                                )!}`
                                            );
                                        } else
                                            isArchivedLanguagesClicked
                                                ? selectedRows.some(
                                                      (element: any) =>
                                                          dataArchive.includes(
                                                              element.Id
                                                          )
                                                  ) && !oneShop
                                                    ? setIsModalOpened(
                                                          !isModalOpened
                                                      )
                                                    : activeAllLanguage()
                                                : isLanguageExist();
                                    });
                                } else {
                                    if (selectedRows.length === 1) {
                                        ErrorToast(
                                            `${t(
                                                "Attention, it is a multiple action. Please select at least two items"
                                            )!}`
                                        );
                                        setIsLoading(false);
                                    } else {
                                        setIsLoading(false);
                                        isArchivedLanguagesClicked
                                            ? selectedRows.some(
                                                  (element: any) =>
                                                      dataArchive.includes(
                                                          element.Id
                                                      )
                                              ) && !oneShop
                                                ? setIsModalOpened(
                                                      !isModalOpened
                                                  )
                                                : activeAllLanguage()
                                            : isLanguageExist();
                                    }
                                }
                            }}
                        >
                            {isArchivedLanguagesClicked
                                ? t("Unarchive")
                                : t("Archive")}
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
}
