import React from "react";
import ReactSwitch from "react-switch";
import { Tooltip } from "@mui/material";
import { useSnapshot } from "valtio";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import store, { setActiveThirdParties, setThirdPartiesFavored } from "../store";

import ErrorToast from "@components/Common/ErrorTost";
import { CustomIconsAssociateModalType } from "../Types";

export function CustomIconsAssociateModal({
    data,
}: CustomIconsAssociateModalType) {
    const { t } = useTranslation();
    const { thirdPartiesFavored, isLoading } = useSnapshot(store);
    const [thirdPartyState, setThirdPartyState] = React.useState<boolean>(
        data.selectedThirdParty.findIndex(
            (el: any) => el.Id === data.element.selectedRow.Id
        ) !== -1
            ? data.selectedThirdParty[
                  data.selectedThirdParty.findIndex(
                      (el: any) => el.Id === data.element.selectedRow.Id
                  )
              ].isActive
            : true
    );

    React.useEffect(() => {
        setActiveThirdParties({
            Id: data.element.selectedRow.Id,
            state: thirdPartyState,
        });
        // eslint-disable-next-line
    }, [thirdPartyState]);

    React.useEffect(() => {
        data.isOneShopClicked === true
            ? setThirdPartiesFavored({
                  id: data.element.selectedRow.Id,
                  status:
                      data.selectedThirdParty.findIndex(
                          (el: any) => el.Id === data.element.selectedRow.Id
                      ) !== -1
                          ? data.selectedThirdParty[
                                data.selectedThirdParty.findIndex(
                                    (el: any) =>
                                        el.Id === data.element.selectedRow.Id
                                )
                            ].isFavorite
                          : false,
              })
            : setThirdPartiesFavored({
                  id: data.element.selectedRow.Id,
                  status: false,
              });
    }, [
        data.element.selectedRow.Id,
        data.isOneShopClicked,
        data.selectedThirdParty,
    ]);

    return (
        <React.Fragment>
            {data.element.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    <Tooltip
                        title={
                            thirdPartyState === false
                                ? t("Activate")
                                : t("Unactivate")
                        }
                    >
                        <div>
                            <ReactSwitch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={26}
                                offColor="#f7b4b8"
                                offHandleColor="#E30613"
                                checked={thirdPartyState}
                                onChange={(isActivate: boolean) => {
                                    if (
                                        thirdPartiesFavored.find(
                                            (el: any) =>
                                                el.id ===
                                                data.element.selectedRow.Id
                                        ).status === false
                                    ) {
                                        setThirdPartyState(!thirdPartyState);
                                    } else {
                                        ErrorToast(
                                            t(
                                                "Please note, you cannot deactivate a favorite third party"
                                            )
                                        );
                                    }
                                }}
                                onColor="#c2eddd"
                                onHandleColor="#34C38F"
                                width={50}
                                height={20}
                                className={classnames(
                                    "sdv_icn_AFenableDisable ml-3",
                                    {
                                        "not-allowed-icon__clz": isLoading,
                                    }
                                )}
                            />
                        </div>
                    </Tooltip>
                </div>
            ) : null}
        </React.Fragment>
    );
}
