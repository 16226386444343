import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as thirdPartyStore,
    setLocalShop,
    setShopsThirdPartyData,
    setShopsSelectedRow,
    setNumberArchiveMultiShop,
    setNumberActiveMultiShop,
    setAssociatedThirdParty,
    setShopDataIsUpdated,
    setIsHistoryShopsModalOpened,
    setSelectedThirdParties,
    setDataActiveShops,
} from "../store";
import { store as StoreConfirmationActivationDeactivation } from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";

import { CustomSideFilter } from "./CustomSideFilter";
import { CustomIcons } from "./CustomIcons";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { ConfirmationActivationDeactivationMessageModal } from "@components/Common/ConfirmationActivationDeactivationMessageModal";
import { removeArrayOfObjectsDuplicates } from "@helpers/general";
import { ThirdPartyAssociateModal } from "./ThirdPartyAssociateModal";
import HistoryModal from "../HistoryModal";
import ThirdPartiesRow from "./ThirdPartiesRow";
import { thirdPartyShopType } from "../Types";
import ThirdPartyConfigure from "./ThirdPartyConfigure";

import "./index.css";
import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export default function ThirdPartyShops({ props }: thirdPartyShopType) {
    const { activationDeactivationMessageModalOpened } = useSnapshot(
        StoreConfirmationActivationDeactivation
    );
    const { oneShop, userID, franchiseID } = useSnapshot(store);
    const { key, shopDataIsUpdated, isHistoryShopsModalOpened } = useSnapshot(
        thirdPartyStore
    );
    const { t } = useTranslation();
    const [
        isAddNewModeOfSaleButtonClicked,
        setIsAddNewModeOfSaleButtonClicked,
    ] = useState(false);
    const [data, setData] = React.useState<any>({});
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [isShopSelected, setIsShopSelected] = React.useState<boolean>(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [selectedShopsRows, setSelectedShopsRows] = React.useState<any[]>([]);
    const [isAssociateModalOpened, setIsAssociateModalOpened] = React.useState<
        boolean
    >(false);
    const [isConfigureModalOpened, setIsConfigureModalOpened] = React.useState<
        boolean
    >(false);
    const uuidUser = localStorage.getItem("uuidUser");
    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty?userId=${userID}&franchiseId=${franchiseID}&type=archived&uuidUser=${uuidUser}`;
    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty?userId=${userID}&franchiseId=${franchiseID}&type=activated&uuidUser=${uuidUser}`;
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: oneShop ? 6 : 7,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    props={{
                        selectedRowData: e,
                        isArchivedThirdPartyClicked:
                            props.isArchivedThirdPartyClicked,
                        setLimit: props.setLimit,
                        setIsConfigureModalOpened,
                        setSelectedRows,
                    }}
                />
            ),
            disableFilter: false,
        },
        {
            indexOFColumn: 2,
            columnName: t("Third party"),
            customJsx: (e: any) => <ThirdPartiesRow element={e} />,
            filterName: t("Third party"),
            canFilter: true,
        },
    ];

    React.useEffect(() => {
        setDataActiveShops(data?.othersData?.dataActive);
    }, [data]);
    React.useEffect(() => {
        let array: any = [];
        let localShop: any = [];
        selectedRows.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push({
                    id: el.shopId,
                    label: el[t("Shop")],
                });
        });
        setLocalShop(localShop);
        let associatedThirdParty: any[] = [];
        selectedRows.forEach((element: any) => {
            if (element[t("Shop")] !== undefined) {
                element.subRows.forEach((el: any) => {
                    array.push({ [el.shopId]: el.Id });
                    associatedThirdParty.push(el);
                });
            } else if (element.Id !== undefined) {
                array.push({ [element.shopId]: element.Id });
            }
        });
        setAssociatedThirdParty(associatedThirdParty);
        setShopsSelectedRow(selectedRows);
        setSelectedThirdParties(removeArrayOfObjectsDuplicates(array));
    }, [selectedRows, t]);

    React.useEffect(() => {
        let localShop: string[] = [];
        selectedRows.forEach((el: any) => {
            if (el[t("Shop")] !== "" && el[t("Shop")] !== undefined)
                localShop.push(el[t("Shop")]);
        });
        setIsShopSelected(localShop.length >= 1);
        let localArchivedThirdParty: number = 0;
        let localActiveThirdParty: number = 0;
        if (selectedRows.length > 0) {
            selectedRows.forEach((element: any) => {
                if (element[t("Shop")] !== undefined) {
                    localActiveThirdParty += element.thirdPartyNumber.activated;
                    localArchivedThirdParty +=
                        element.thirdPartyNumber.archived;
                }
            });
            if (selectedRows.filter((el: any) => el[t("Shop")]).length > 0) {
                setNumberActiveMultiShop(localActiveThirdParty);
                setNumberArchiveMultiShop(localArchivedThirdParty);
            } else {
                setNumberActiveMultiShop(
                    data.othersData !== undefined
                        ? data.othersData.activated
                        : 0
                );
                setNumberArchiveMultiShop(
                    data.othersData !== undefined ? data.othersData.archived : 0
                );
            }
        } else {
            setNumberActiveMultiShop(
                data.othersData !== undefined ? data.othersData.activated : 0
            );
            setNumberArchiveMultiShop(
                data.othersData !== undefined ? data.othersData.archived : 0
            );
        }

        setShopsThirdPartyData(data.data);
    }, [selectedRows, data, t]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
            >
                <DynamicTable
                    key={key}
                    url={
                        props.isArchivedThirdPartyClicked
                            ? urlArchive
                            : urlActive
                    }
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    canExpand
                    canSelect
                    setData={setData}
                    customSelect
                    setSelectedRows={setSelectedRows}
                    setLocalFilterActive={setLocalFilterActive}
                    canMovedCheckboxLeftOnExpand
                    customJsxSideFilterButton={
                        <CustomSideFilter
                            data={{
                                isShopSelected,
                                selectedRows,
                                isAssociateModalOpened,
                                setIsAssociateModalOpened,
                                setLocalFilterActive,
                                isArchivedThirdPartyClicked:
                                    props.isArchivedThirdPartyClicked,
                                setIsArchivedThirdPartyClicked:
                                    props.setIsArchivedThirdPartyClicked,
                                setSelectedShopsRows,
                                setLimit: props.setLimit,
                            }}
                        />
                    }
                    canResize
                    showGlobalFilter
                    showFilter
                    filterActive={filterActive}
                    actionColumn={ActionColumn}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={
                        setShopDataIsUpdated as
                            | React.Dispatch<
                                  React.SetStateAction<number | boolean>
                              >
                            | undefined
                    }
                    dataIsUpdated={shopDataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="thirdParty"
                />
                {isAssociateModalOpened ? (
                    <ThirdPartyAssociateModal
                        data={{
                            setIsAssociateModalOpened,
                            isAssociateModalOpened,
                            selectedShopsRows,
                        }}
                    />
                ) : null}
            </div>

            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewModeOfSaleButtonClicked}
                    isClicked={isAddNewModeOfSaleButtonClicked}
                />
            ) : null}
            {isHistoryShopsModalOpened ? (
                <HistoryModal
                    data={{
                        setIsModalOpened: setIsHistoryShopsModalOpened,
                        isModalOpened: isHistoryShopsModalOpened,
                    }}
                />
            ) : null}
            {activationDeactivationMessageModalOpened && !oneShop && (
                <ConfirmationActivationDeactivationMessageModal />
            )}
            {isConfigureModalOpened ? (
                <ThirdPartyConfigure
                    data={{
                        isModalOpened: isConfigureModalOpened,
                        setIsModalOpened: setIsConfigureModalOpened,
                        rowData: selectedRows[0],
                        isConsult: false,
                    }}
                />
            ) : null}
        </React.Fragment>
    );
}
