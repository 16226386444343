import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import {
    store as thirdPartyStore,
    setFranchiseDataIsUpdated,
    setShopDataIsUpdated,
    setIsInlineErrorCleared,
    setIsEdited,
    setIsLoading,
} from "../store";
import { setIsDataUpdated } from "@pages/Applications/Kiosk/store";

import { ConfirmationEditMessage } from "@components/Common/ConfirmationEditMessage";
import { ConfirmationModificationModal } from "./ConfirmationModificationModal";
import { ConfirmationMessage } from "../ThirdPartyShops/ConfirmationMessage";
import { ConfirmationModal } from "./ConfirmationModal";
import { CreateDataThirdParty, clearData, thirdPartyFormType } from "../helper";
import ThirdPartyConfig from "./ThirdPartyConfig";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";

export default function ThirdPartyForm({ data }: thirdPartyFormType) {
    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const { userID, franchiseID, shopID, oneShop, operatorID } = useSnapshot(
        store
    );
    const {
        shopsThirdPartyData,
        thirdPartyData,
        isInlineErrorCleared,
        listFranchiseArchived,
        archivedData,
        isInputUpdated,
    } = useSnapshot(thirdPartyStore);
    const shopId = oneShop ? shopID : "0";
    const [isNameExist, setIsNameExist] = React.useState<boolean>(false);
    const [thirdPartyLabel, setThirdPartyLabel] = React.useState<string>("");
    const [title, setTitle] = React.useState<string>("");
    const [identifier, setIdentifier] = React.useState<string>("");
    const [inputThirdPartyLabel, setInputThirdPartyLabel] = React.useState<
        boolean
    >(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [generalThirdParties, setGeneralThirdParties] = React.useState<any[]>(
        []
    );
    const [isMessageModalOpened, setIsMessageModalOpened] = React.useState<
        boolean
    >(false);
    const [existingThirdParty, setExistingThirdParty] = React.useState<any>({});
    const [
        isMessageValidateClicked,
        setIsMessageValidateClicked,
    ] = React.useState<boolean>(false);
    const [
        isThirdPartyAssociatedModified,
        setIsThirdPartyAssociatedModified,
    ] = React.useState<boolean>(false);
    const [
        isArchivedFranchiseModalOpened,
        setIsArchivedFranchiseModalOpened,
    ] = React.useState<boolean>(false);
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const [isArchived, setIsArchived] = React.useState<boolean>(false);
    const [remark, setRemark] = React.useState<string>("");
    const [salesId, setSalesId] = React.useState<any>("");
    const [menusId, setMenusId] = React.useState<any>("");
    const [baseUrl, setBaseUrl] = React.useState<any>("");
    const [ip, setIp] = React.useState<any>("");
    const [apiKey, setApiKey] = React.useState<any>("");
    const [inputMenusId, setInputMenusId] = React.useState<boolean>(false);
    const [inputSalesId, setInputSalesId] = React.useState<boolean>(false);
    const [inputBaseUrl, setInputBaseUrl] = React.useState<boolean>(false);
    const [isInvalidBaseUrl, setIsInvalidBaseUrl] = React.useState<boolean>(
        false
    );
    const [inputPort, setInputPort] = React.useState<boolean>(false);
    const [inputApiKey, setInputApiKey] = React.useState<boolean>(false);
    const [emptyIp, setEmptyIp] = React.useState<boolean>(false);
    const [port, setPort] = React.useState<any>("");
    const [inputIp, setInputIp] = React.useState<boolean>(false);
    const uuidUser = localStorage.getItem("uuidUser");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function addThirdParty() {
        toast.dismiss();
        data.setLimit(1);
        let id = generalThirdParties.find(
            (elt: any) => elt.label === thirdPartyLabel
        ).value;

        let dataThirdParty = CreateDataThirdParty(
            oneShop,
            isMessageValidateClicked,
            shopId,
            thirdPartyData.length === 0,
            thirdPartyData.length === 0,
            userID,
            franchiseID,
            operatorID,
            uuidUser,
            id,
            thirdPartyLabel,
            ip,
            port,
            salesId,
            menusId,
            baseUrl,
            remark,
            false,
            apiKey
        );
        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(oneShop ? dataThirdParty : [dataThirdParty]),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then(async (result) => {
                        if (result.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                isMessageValidateClicked
                                    ? "The association has been successfully completed."
                                    : "The third party has been successfully added"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsDataUpdated!(true);
                        setFranchiseDataIsUpdated(true);
                        setShopDataIsUpdated(true);
                        setIsEdited(false);
                        data.setLocalFilterActive(false);
                        clearData(
                            setThirdPartyLabel,
                            setIp,
                            setPort,
                            setSalesId,
                            setMenusId,
                            setBaseUrl,
                            setRemark,
                            setInputBaseUrl,
                            setInputMenusId,
                            setInputSalesId,
                            setInputPort,
                            setInputIp,
                            setApiKey,
                            setInputApiKey
                        );
                        setIsLoading(false);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsLoading(false);
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    function AssociateThird() {
        let idsAssociated: any[] = [];
        let associated: any[] = [];
        if (shopsThirdPartyData !== undefined) {
            shopsThirdPartyData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === data.editedData.Id) {
                        idsAssociated.push(el.shopId);
                        associated.push(el);
                    }
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return associated;
    }
    async function editThirdParty() {
        data.setLimit(1);

        let idsAssociated: any = [];
        let idsShopsAssociated: any = [];
        shopsThirdPartyData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                idsAssociated.push(element.Id);
                idsShopsAssociated.push(element.shopId);
            });
        });
        let dataThirdParty = CreateDataThirdParty(
            oneShop,
            false,
            shopId,
            data.editedData.isActive,
            data.editedData.isFavorite,
            userID,
            franchiseID,
            operatorID,
            uuidUser,
            data.editedData.Id,
            thirdPartyLabel,
            ip,
            port,
            salesId,
            menusId,
            baseUrl,
            remark,
            false,
            apiKey,
            true
        );
        let dataAssociate: any[] = [];

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify([dataThirdParty]),
        };

        try {
            mutate(
                apiUrlAdd,

                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.error) {
                            throw Error("error");
                        }
                        if (!oneShop) {
                            dataAssociate = AssociateThird();
                        }
                        toast.success(
                            data.userData.shopId === "0" &&
                                dataAssociate.length > 0
                                ? `${t(
                                      "The Franchise Third Party has been successfully modified."
                                  )!}`
                                : `${t(
                                      "The third party has been successfully modified."
                                  )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        if (dataAssociate.length > 0) {
                            setIsThirdPartyAssociatedModified(true);
                            setFranchiseDataIsUpdated(true);
                            setIsLoading(false);
                        } else {
                            setIsDataUpdated!(true);
                            setFranchiseDataIsUpdated(true);
                            setShopDataIsUpdated(true);
                            data.setLocalFilterActive(false);
                            setIsEdited(false);
                            setThirdPartyLabel("");
                            data.setThirdPartyData([]);
                            data.setIsAddThirdPartyClicked!(
                                !data.isAddThirdPartyClicked
                            );
                            setIsEdited(false);
                            setIsLoading(false);
                        }
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
            setIsLoading(false);
        }
    }

    async function getGeneralThirdParty() {
        const newList: any[] = [];
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/generalList?type=thirdParty&userId=${userID}&shopId=${shopID}&franchiseId=${franchiseID}`,

            {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            }
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    for (let element of result.data) {
                        newList.push({
                            value: element.Id,
                            label: element.Designation,
                        });
                    }
                    setGeneralThirdParties(newList);
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    function handleValidateButtonOnClickEvent() {
        data.setLimit(1);
        setIsValidateButtonDisabled(true);
        if (
            data.editedData.Id !== undefined &&
            data.editedData[t("Remark")] === remark &&
            data.editedData.ip === ip &&
            data.editedData.portApi === port &&
            data.editedData.salesId === salesId &&
            data.editedData.menusId === menusId &&
            data.editedData.baseUrl === baseUrl &&
            data.editedData.apiKey === apiKey
        ) {
            setIsEdited(false);
            setIsModalOpened(true);
        } else {
            if (thirdPartyLabel === "") {
                setInputThirdPartyLabel(true);
            }
            if (ip === "") {
                setInputIp(true);
            }
            if (port === "") {
                setInputPort(true);
            }
            if (salesId === "") {
                setInputSalesId(true);
            }
            if (menusId === "") {
                setInputMenusId(true);
            }
            if (baseUrl === "") {
                setInputBaseUrl(true);
            }
            if (apiKey === "") {
                setInputApiKey(true);
            }
            if (!oneShop && thirdPartyLabel !== "" && !isNameExist) {
                if (data.editedData.Id) {
                    editThirdParty();
                } else {
                    addThirdParty();
                }
            } else if (oneShop) {
                if (
                    !isNameExist &&
                    ((thirdPartyLabel === "ADORIA" &&
                        ip !== "" &&
                        salesId !== "" &&
                        menusId !== "" &&
                        baseUrl !== "" &&
                        !isInvalidBaseUrl &&
                        port !== "" &&
                        !inputIp) ||
                        (thirdPartyLabel !== "ADORIA" && apiKey !== ""))
                ) {
                    if (data.editedData.Id) {
                        editThirdParty();
                    } else {
                        let isThirdPartyExistArchivedFranchise = listFranchiseArchived.find(
                            (el: any) =>
                                el[t("Third party")] === thirdPartyLabel
                        );
                        let isThirdPartyExist: boolean = false;

                        if (data.allDataShops !== undefined) {
                            isThirdPartyExist =
                                data.allDataShops.find(
                                    (el: any) =>
                                        el[t("Third party")] === thirdPartyLabel
                                ) !== undefined;
                        }

                        if (isThirdPartyExistArchivedFranchise !== undefined) {
                            setIsMessageModalOpened(true);
                            setIsArchived(true);
                        } else if (
                            data.allDataShops !== undefined &&
                            isThirdPartyExist
                        ) {
                            setIdentifier(
                                // eslint-disable-next-line
                                data.allDataShops.find((el: any) => {
                                    if (
                                        el[t("Third party")] === thirdPartyLabel
                                    )
                                        return el.Id;
                                }).Id
                            );
                            setIsMessageModalOpened(true);
                            setIsArchived(false);
                        } else {
                            addThirdParty();
                        }
                    }
                }
            }
        }
        setTimeout(() => {
            setIsValidateButtonDisabled(false);
        }, 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkIfThirdPartyExist = () => {
        let allThirdParty = [...thirdPartyData, ...archivedData];
        return allThirdParty.some(
            (element) => element[t("Third party")] === thirdPartyLabel
        );
    };

    useEffect(() => {
        setInputIp(false);
        setInputPort(false);
        setInputSalesId(false);
        setInputMenusId(false);
        setInputBaseUrl(false);
        setInputApiKey(false);
        setInputThirdPartyLabel(false);
        if (data.editedData.Id) {
            setTitle(
                t(
                    data.isConsult
                        ? "View a third party"
                        : "Modify a third party"
                )
            );
            setThirdPartyLabel(data.editedData[t("Third party")]);
            setIp(data.editedData.ip);
            setPort(data.editedData.portApi);
            setSalesId(data.editedData.salesId);
            setMenusId(data.editedData.menusId);
            setBaseUrl(data.editedData.baseUrl);
            setRemark(data.editedData[t("Remark")]);
            setApiKey(data.editedData.apiKey);
        } else {
            setTitle(t("Add a third party"));
            setThirdPartyLabel("");
            setIp("");
            setPort("");
            setSalesId("");
            setMenusId("");
            setBaseUrl("");
            setRemark("");
            setApiKey("");
        }
        getGeneralThirdParty();

        // eslint-disable-next-line
    }, [
        data.editedData,
        t,
        data.isAddNewThirdPartyButtonClicked,
        data.isAddThirdPartyClicked,
    ]);

    useEffect(() => {
        if (isInlineErrorCleared === true) {
            setIsInlineErrorCleared(false);
            setGeneralThirdParties([]);
        }
    }, [isInlineErrorCleared]);

    useEffect(() => {
        setIsInlineErrorCleared(false);
    }, [isInputUpdated]);

    useEffect(() => {
        let isThirdPartyExist = checkIfThirdPartyExist();
        if (
            isThirdPartyExist &&
            (data.editedData.Id === undefined ||
                (data.editedData.Id !== undefined &&
                    data.editedData[t("Third party")] !== thirdPartyLabel))
        ) {
            setIsNameExist(true);
        } else {
            setIsNameExist(false);
        }
    }, [thirdPartyLabel, t, checkIfThirdPartyExist, data.editedData]);

    React.useEffect(() => {
        let allSaleShop: any = [];
        if (shopsThirdPartyData !== undefined) {
            shopsThirdPartyData.forEach((el: any) => {
                if (el.subRows !== undefined)
                    el.subRows.forEach((element: any) => {
                        allSaleShop.push(element);
                    });
            });
        }
        setExistingThirdParty(AssociateThird);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [identifier, shopsThirdPartyData]);

    React.useEffect(() => {
        if (isMessageValidateClicked === true) {
            addThirdParty();
            setIsMessageModalOpened(false);
        }
        setIsMessageValidateClicked(false);
    }, [
        data.allDataShops,
        data.userData,
        isMessageValidateClicked,
        addThirdParty,
        t,
        thirdPartyLabel,
        data.editedData,
    ]);

    return (
        <React.Fragment>
            <ThirdPartyConfig
                data={{
                    editedData: data.editedData,
                    setIsConsult: data.setIsConsult,
                    setIsAddThirdPartyClicked: data.setIsAddThirdPartyClicked,
                    isConsult: data.isConsult,
                    isAddThirdPartyClicked: data.isAddThirdPartyClicked,
                    title,
                    ip,
                    baseUrl,
                    port,
                    salesId,
                    menusId,
                    apiKey,
                    remark,
                    generalThirdParties,
                    thirdPartyLabel,
                    setThirdPartyLabel,
                    setIp,
                    setPort,
                    setSalesId,
                    setMenusId,
                    setBaseUrl,
                    setRemark,
                    setInputBaseUrl,
                    setInputMenusId,
                    setInputSalesId,
                    setInputPort,
                    setInputIp,
                    setApiKey,
                    setInputApiKey,
                    setIsInvalidBaseUrl,
                    setInputThirdPartyLabel,
                    setEmptyIp,
                    handleValidateButtonOnClickEvent,
                    inputThirdPartyLabel,
                    isNameExist,
                    inputIp,
                    emptyIp,
                    inputPort,
                    isInvalidBaseUrl,
                    inputBaseUrl,
                    inputSalesId,
                    inputMenusId,
                    inputApiKey,
                    isValidateButtonDisabled,
                }}
            />
            <ConfirmationEditMessage
                setDataIsUpdated={setFranchiseDataIsUpdated}
                isModalOpened={isModalOpened}
                setIsModalOpened={setIsModalOpened}
                isAddClicked={data.isAddThirdPartyClicked}
                setIsAddClicked={data.setIsAddThirdPartyClicked}
                setEditedData={data.setThirdPartyData}
            />
            {isThirdPartyAssociatedModified && (
                <ConfirmationModificationModal
                    data={{
                        setThirdPartyData: data.setThirdPartyData,
                        data: existingThirdParty,
                        resetData: () => {
                            setThirdPartyLabel("");
                        },
                        remark,
                        setIsAddThirdPartyClicked:
                            data.setIsAddThirdPartyClicked,
                        setIsEdited,
                        isModalOpened: isThirdPartyAssociatedModified,
                        setIsModalOpened: setIsThirdPartyAssociatedModified,
                    }}
                />
            )}
            {isMessageModalOpened ? (
                <ConfirmationMessage
                    data={{
                        msg: !isArchived
                            ? t(
                                  "The third party already exists in the franchise. Do you want to associate it with this store?"
                              )
                            : t(
                                  "Attention! the third parties is already archived at the franchise level"
                              ),
                        setIsModalOpened: setIsMessageModalOpened,
                        isModalOpened: isMessageModalOpened,
                        setIsValidateClicked: setIsMessageValidateClicked,
                        isArchived,
                    }}
                />
            ) : null}
            {isArchivedFranchiseModalOpened ? (
                <ConfirmationModal
                    data={{
                        isConfirmationModal: isArchivedFranchiseModalOpened,
                        setIsConfirmationModal: setIsArchivedFranchiseModalOpened,
                    }}
                />
            ) : null}
        </React.Fragment>
    );
}
