import React from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import {
    CheckboxIcon,
    EyeIcon,
    PencilIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import ReactSwitch from "react-switch";
import { useSnapshot } from "valtio";
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { uuid } from "uuidv4";

import store, {
    setIsInputUpdated,
    setThirdPartyToBeArchived,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setIsLoading,
    setFranchiseDataIsUpdated,
    setShopDataIsUpdated,
    setKey,
} from "../store";
import { store as useStore } from "../../../../components/VerticalLayout/store";

import ErrorToast from "@components/Common/ErrorTost";
import { CreateDataThirdParty, handleSwitchChange } from "../helper";
import { ConfirmationArchive } from "../ConfirmationArchive";

export default function IsModify({ data }: any) {
    const { t } = useTranslation();
    const {
        isInputUpdated,
        listFranchiseArchived,
        isActionsClicked,
        isEdited,
        isLoading,
        shopsThirdPartyData,
        dataActive,
    } = useSnapshot(store);
    const uuidUser = localStorage.getItem("uuidUser");

    const { oneShop, shopID, userID, franchiseID, operatorID } = useSnapshot(
        useStore
    );
    const [isArchiveSVModalOpened, setIsArchiveSVModalOpened] = React.useState<
        boolean
    >(false);
    const [change, setChange] = React.useState<boolean>(false);
    const [state, setState] = React.useState<boolean>(false);

    async function handleStateChange(state: any) {
        data.setLimit(1);
        let thirdData = CreateDataThirdParty(
            oneShop,
            false,
            data.selectedRowData.selectedRow.shopId,
            state,
            data.selectedRowData.selectedRow.isFavorite,
            userID,
            franchiseID,
            operatorID,
            uuidUser,
            data.selectedRowData.selectedRow.Id,
            data.selectedRowData.selectedRow[t("Third party")],
            data.selectedRowData.selectedRow.ip,
            data.selectedRowData.selectedRow.port,
            data.selectedRowData.selectedRow.salesId,
            data.selectedRowData.selectedRow.menusId,
            data.selectedRowData.selectedRow.baseUrl,
            data.selectedRowData.selectedRow[t("Remark")],
            false,
            data.selectedRowData.selectedRow.apiKey
        );
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(thirdData),
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    } else {
                        toast.success(
                            state === true
                                ? `${t(
                                      "The third party has been successfully activated"
                                  )}`
                                : `${t(
                                      "The third party has been successfully deactivated"
                                  )}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setFranchiseDataIsUpdated(true);
                        setShopDataIsUpdated(true);
                        setTimeout(() => setIsLoading(false), 3000);
                    }
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setIsLoading(false);
                })
        );
    }
    async function handleTrashButtonClicked(selectedRow: any) {
        data.setLimit(1);
        let idsAssociated: any = [];
        shopsThirdPartyData.forEach((el: any) => {
            el.subRows.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        });
        if (
            selectedRow.shopId === "0" &&
            idsAssociated.includes(selectedRow.Id)
        ) {
            ErrorToast(
                `${t(
                    "Please note that you cannot archive an item assigned to stores."
                )!}`
            );
            setIsLoading(false);
        } else {
            if (
                (selectedRow.shopId === "0" &&
                    !idsAssociated.includes(selectedRow.Id) &&
                    !oneShop) ||
                oneShop
            ) {
                setIsArchiveSVModalOpened(true);
                setIsLoading(false);
            }
        }
    }
    async function handleActiveThirdParty(selectedRowData: any) {
        data.setLimit(1);
        let hasActivate = dataActive.length === 0 || false;
        let thirdData: any = CreateDataThirdParty(
            oneShop,
            false,
            selectedRowData.selectedRow.shopId,
            selectedRowData.selectedRow.shopId === "0"
                ? true
                : selectedRowData.selectedRow.isActive,
            hasActivate,
            userID,
            franchiseID,
            operatorID,
            uuidUser,
            selectedRowData.selectedRow.Id,
            selectedRowData.selectedRow[t("Third party")],
            selectedRowData.selectedRow.ip,
            selectedRowData.selectedRow.port,
            selectedRowData.selectedRow.salesId,
            selectedRowData.selectedRow.menusId,
            selectedRowData.selectedRow.baseUrl,
            selectedRowData.selectedRow[t("Remark")],
            true,
            selectedRowData.selectedRow.apiKey
        );
        thirdData[0].data[selectedRowData.selectedRow.Id].isArchived = false;

        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty`;

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(thirdData),
        };
        mutate(
            apiUrl,
            await fetch(apiUrl, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }
                    toast.success(
                        `${t(
                            "The third party has been successfully unarchived."
                        )}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setIsLoading(false);
                    setKey(uuid());
                })
                .catch((error) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                    setIsLoading(false);
                })
        );
    }
    React.useEffect(() => {
        if (change === true) {
            handleStateChange(state);
            setChange(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change, state]);

    return (
        <React.Fragment>
            {!data.isArchivedThirdPartyClicked ? (
                <Tooltip
                    title={data.isArchivedThirdPartyClicked ? "" : t("Edit")}
                >
                    <div>
                        <PencilIcon
                            onClick={() => {
                                if (isEdited === true) {
                                    setIsActionsClicked(!isActionsClicked);
                                    setActions(() => {
                                        setIsEdited(false);
                                        data.setThirdParty(
                                            data.selectedRowData.selectedRow
                                        );
                                        data.setIsAddThirdPartyClicked(true);
                                        setIsInputUpdated(!isInputUpdated);
                                    });
                                } else {
                                    data.setThirdParty(
                                        data.selectedRowData.selectedRow
                                    );
                                    data.setIsAddThirdPartyClicked(true);
                                    setIsInputUpdated(!isInputUpdated);
                                }
                            }}
                            height={25}
                            width={25}
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                "pointer__clz cmn_icn_FRedit",
                                {
                                    "not-allowed-icon__clz":
                                        data.isArchivedThirdPartyClicked ||
                                        isLoading,
                                },
                                oneShop ? "mr-2" : "ml-3"
                            )}`}
                        />
                    </div>
                </Tooltip>
            ) : null}
            {oneShop ? (
                <Tooltip
                    title={
                        data.isArchivedThirdPartyClicked
                            ? ""
                            : data.thirdPartyState === false
                            ? t("Activate")
                            : t("Unactivate")
                    }
                >
                    <div>
                        <ReactSwitch
                            uncheckedIcon={false}
                            checkedIcon={false}
                            handleDiameter={26}
                            offColor="#f7b4b8"
                            offHandleColor="#E30613"
                            checked={data.thirdPartyState}
                            onChange={async (e: boolean) => {
                                handleSwitchChange(
                                    e,
                                    data,
                                    isEdited,
                                    isActionsClicked,
                                    setChange,
                                    setState
                                );
                            }}
                            onColor="#c2eddd"
                            onHandleColor="#34C38F"
                            width={50}
                            height={20}
                            className={`${classnames(
                                "sdv_icn_FRiconEnableDisable sdv_icn_AFselectorActiveDeactivate sdv_icn_BTiconEnableDisable",
                                {
                                    "not-allowed-icon__clz":
                                        data.isArchivedThirdPartyClicked ||
                                        isLoading,
                                },
                                oneShop ? "mr-2" : "ml-3"
                            )}`}
                        />
                    </div>
                </Tooltip>
            ) : null}
            {!data.isArchivedThirdPartyClicked ? (
                <Tooltip title={t("Archive")}>
                    <div>
                        <TrashIcon
                            onClick={async () => {
                                setIsLoading(true);
                                if (isEdited === true) {
                                    if (
                                        data.selectedRowData.selectedRow
                                            ?.isFavorite === true
                                    ) {
                                        setIsLoading(false);
                                        ErrorToast(
                                            t(
                                                `Please note, you cannot archive a favorite third party`
                                            )
                                        );
                                    } else {
                                        setIsLoading(false);
                                        setIsActionsClicked(!isActionsClicked);
                                        setActions(async () => {
                                            setIsEdited(false);
                                            setThirdPartyToBeArchived(
                                                data.selectedRowData.selectedRow
                                            );
                                            await handleTrashButtonClicked(
                                                data.selectedRowData.selectedRow
                                            );
                                            data.setIsAddThirdPartyClicked(
                                                false
                                            );
                                        });
                                    }
                                } else {
                                    if (
                                        data.selectedRowData.selectedRow
                                            ?.isFavorite === true
                                    ) {
                                        setIsLoading(false);
                                        ErrorToast(
                                            t(
                                                `Please note, you cannot archive a favorite third party`
                                            )
                                        );
                                    } else {
                                        setIsLoading(false);
                                        data.setIsAddThirdPartyClicked(false);
                                        setThirdPartyToBeArchived(
                                            data.selectedRowData.selectedRow
                                        );
                                        await handleTrashButtonClicked(
                                            data.selectedRowData.selectedRow
                                        );
                                    }
                                }
                            }}
                            height={25}
                            width={25}
                            fill="red"
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                "pointer__clz cmn_icn_FRarchived ",
                                {
                                    "not-allowed-icon__clz":
                                        data.isArchivedThirdPartyClicked ||
                                        isLoading,
                                },
                                oneShop ? "mr-2" : "ml-3"
                            )}`}
                        />
                    </div>
                </Tooltip>
            ) : (
                <Tooltip title={t("Unarchived")}>
                    <div>
                        <EyeIcon
                            height={25}
                            width={25}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                setIsLoading(true);
                                data.setSelectedRows([
                                    data.selectedRowData.selectedRow,
                                ]);
                                if (
                                    data.dataArchive.includes(
                                        data.selectedRowData.selectedRow.Id
                                    ) &&
                                    !oneShop
                                ) {
                                    data.setIsModalConfirmationOpened(
                                        !data.isModalConfirmationOpened
                                    );
                                    setIsLoading(false);
                                } else {
                                    handleActiveThirdParty(
                                        data.selectedRowData
                                    );
                                }
                            }}
                            className={`${classnames(
                                "pointer__clz cmn_icn_FRunarchive ",
                                {
                                    "not-allowed-icon__clz":
                                        isLoading ||
                                        (shopID !== "0" &&
                                            listFranchiseArchived.find(
                                                (el: any) =>
                                                    el.Id ===
                                                    data.selectedRowData
                                                        .selectedRow.Id
                                            ) !== undefined),
                                },
                                oneShop ? "mr-2" : "ml-3"
                            )}`}
                        />
                    </div>
                </Tooltip>
            )}
            {isArchiveSVModalOpened ? (
                <ConfirmationArchive
                    data={{
                        setIsArchiveSVModalOpened,
                        isArchiveSVModalOpened,
                        setIsValidateClicked: data.setIsValidateClicked,
                    }}
                />
            ) : null}
        </React.Fragment>
    );
}
