import React from "react";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { useLocation } from "react-router-dom";

import { store } from "../../../components/VerticalLayout/store";
import { setModify } from "./store";

import { MainColorTabsWrapper } from "@components/Common/MainColorTabsWrapper";
import { getPathAuthorization } from "@components/VerticalLayout";
import { StyledTabs } from "./StyledTabs";
import PageTitle from "../../../components/Common/PageTitle";
import MessageInfo from "../../../components/Common/MessageInfo";
import ThirdPartyFranchise from "./ThirdPartyFranchise";
import ThirdPartyShops from "./ThirdPartyShops";

import "./index.css";

export function LocalThirdParty() {
    const location = useLocation();
    setModify(getPathAuthorization(location.pathname).Modifie);
    const { t } = useTranslation();
    const { oneShop } = useSnapshot(store);
    const [limit, setLimit] = React.useState<number>(1);
    const [
        isArchivedThirdPartyClicked,
        setIsArchivedThirdPartyClicked,
    ] = React.useState<boolean>(false);

    const tabs1 = (
        <ThirdPartyFranchise
            props={{
                isArchivedThirdPartyClicked,
                setIsArchivedThirdPartyClicked,
                setLimit,
            }}
        />
    );
    const tabs2 = (
        <StyledTabs
            tabs={[
                {
                    content: (
                        <ThirdPartyFranchise
                            props={{
                                isArchivedThirdPartyClicked,
                                setIsArchivedThirdPartyClicked,
                                setLimit,
                            }}
                        />
                    ),
                    disabled: false,
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark  cmn_btn_franchise"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Third party")}
                        </div>
                    ),
                },
                {
                    content: (
                        <ThirdPartyShops
                            props={{
                                isArchivedThirdPartyClicked,
                                setIsArchivedThirdPartyClicked,
                                setLimit,
                            }}
                        />
                    ),
                    title: (
                        <div
                            className="d-flex justify-content-between text-dark border-0 cmn_btn_boutique"
                            style={{
                                gap: "10px",
                                font: "normal normal 600 16px/21px Segoe UI",
                            }}
                        >
                            {t("Third party shops")}
                        </div>
                    ),
                },
            ]}
        />
    );

    return (
        <React.Fragment>
            <ToastContainer limit={limit} />
            <PageTitle title={t("General settings")} />
            <div className="page-content__clz">
                <div className="sdv_txt_thirdParties">
                    <StyledBreadcrumb
                        items={[
                            {
                                item: "Settings".toUpperCase(),
                                link: `${process.env.REACT_APP_PORTAIL_URL}`,
                            },
                            {
                                item:
                                    localStorage
                                        .getItem("selectedAccount")
                                        ?.toUpperCase() || "",
                                link: oneShop
                                    ? "/setting/customer-account"
                                    : "/compte",
                            },
                            {
                                item: t("General settings").toUpperCase(),
                                link: "#",
                            },
                            {
                                item: !isArchivedThirdPartyClicked
                                    ? t(
                                          "Interconnection Third Party"
                                      ).toUpperCase()
                                    : t(
                                          "Interconnection Third party archived"
                                      ).toUpperCase(),
                                link: "#",
                            },
                        ]}
                    />
                </div>

                <MessageInfo
                    message={t(
                        "In order to allow the store to use the third-party module, it must be assigned to the store and configured in enabled mode."
                    )}
                />
                <MainColorTabsWrapper>
                    {oneShop ? tabs1 : tabs2}
                </MainColorTabsWrapper>
            </div>
        </React.Fragment>
    );
}
export const ThirdParty = React.memo(LocalThirdParty);
