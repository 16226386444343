import { proxy } from "valtio";

type StoreType = {
    isThirdParty: boolean;
    numberArchived: number;
    numberActive: number;
    isAssociateThirdPartyOpened: boolean;
    localShop: any;
    isAssociateThirdPartyClosed: boolean;
    numberActiveMultiShop: number;
    numberArchiveMultiShop: number;
    isThirdPartyAssociatedModifiedClosed: boolean;
    idsLocalShop: string[];
    isAssociatedThirdParty: boolean;
    selectedRows: any[];
    isAssociatedModalClosed: boolean;
    ids: number;
    isThirdPartyAssociatedArchivedClosed: boolean;
    isModified: boolean;
    isActivation: boolean;
    isAssociatedClosed: boolean;
    shopsThirdPartyData: any[];
    thirdPartyData: any;
    activeThirdParties: any[];
    selectedThirdParties: any[];
    isHistoryShopsModalOpened: boolean;
    isHistoryModalOpened: boolean;
    shopsSelectedRow: any;
    isArchiveSVModalOpened: boolean;
    archivedData: any[];
    DesignationThirdPartyFranchise: any;
    dataFranchiseThirdParty: any;
    isArchivedThirdPartyClicked: any;
    shopDataIsUpdated: boolean | number;
    associatedThirdParty: any[];
    franchiseDataIsUpdated: boolean;
    listFranchiseArchived: any[];
    isInputUpdated: boolean;
    selectedHistoryThirdParties: any;
    isInlineErrorCleared: boolean;
    thirdPartiesFavored: any;
    thirdPartyToBeArchived: any;
    isActionsClicked: boolean;
    isEdited: boolean;
    actions: Function;
    validationAction: any;
    modify: boolean;
    key: any;
    isLoading: boolean;
    dataActive: any;
    dataActiveShops: any;
};

export const store = proxy<StoreType>({
    dataActive: [],
    isThirdParty: false,
    numberArchived: 0,
    numberActive: 0,
    isAssociateThirdPartyOpened: false,
    localShop: [],
    isAssociateThirdPartyClosed: false,
    numberActiveMultiShop: 0,
    numberArchiveMultiShop: 0,
    isThirdPartyAssociatedModifiedClosed: false,
    idsLocalShop: [],
    isAssociatedThirdParty: false,
    selectedRows: [],
    isAssociatedModalClosed: false,
    ids: 0,
    isThirdPartyAssociatedArchivedClosed: false,
    isModified: false,
    isActivation: false,
    isAssociatedClosed: false,
    shopsThirdPartyData: [],
    thirdPartyData: {},
    activeThirdParties: [],
    selectedThirdParties: [],
    isHistoryShopsModalOpened: false,
    isHistoryModalOpened: false,
    shopsSelectedRow: [],
    isArchiveSVModalOpened: false,
    archivedData: [],
    DesignationThirdPartyFranchise: [],
    dataFranchiseThirdParty: [],
    isArchivedThirdPartyClicked: false,
    shopDataIsUpdated: false,
    franchiseDataIsUpdated: false,
    associatedThirdParty: [],
    listFranchiseArchived: [],
    isInputUpdated: false,
    selectedHistoryThirdParties: {},
    isInlineErrorCleared: false,
    thirdPartiesFavored: [],
    thirdPartyToBeArchived: {},
    isActionsClicked: false,
    isEdited: false,
    actions: () => { },
    validationAction: {},
    modify: false,
    key: 0,
    isLoading: false,
    dataActiveShops: [],
});

export function setDataActiveShops(dataActiveShops: any): void {
    store.dataActiveShops = dataActiveShops;
}
export function setDataActive(dataActive: any): void {
    store.dataActive = dataActive;
}
export function setIsLoading(isLoading: boolean): void {
    store.isLoading = isLoading;
}

export function setModify(value: boolean): void {
    store.modify = value;
}
export function setIsHistoryModalOpened(isHistoryModalOpened: boolean): any {
    store.isHistoryModalOpened = isHistoryModalOpened;
}

export function setThirdPartiesFavored(thirdPartiesFavored: any): any {
    let localIndex: number = store.thirdPartiesFavored.findIndex((e: any) => {
        return e.id === thirdPartiesFavored.id;
    });
    if (localIndex === -1 || localIndex === undefined) {
        store.thirdPartiesFavored.push(thirdPartiesFavored);
    } else {
        store.thirdPartiesFavored[localIndex] = thirdPartiesFavored;
    }

    if (thirdPartiesFavored.status === true) {
        store.thirdPartiesFavored = store.thirdPartiesFavored.map((el: any) =>
            el.id !== thirdPartiesFavored.id ? { id: el.id, status: false } : el
        );
    }
}

export function setIsHistoryShopsModalOpened(
    isHistoryShopsModalOpened: boolean
): void {
    store.isHistoryShopsModalOpened = isHistoryShopsModalOpened;
}

export function setIsArchivedThirdPartyClicked(
    isArchivedThirdPartyClicked: boolean
): void {
    store.isArchivedThirdPartyClicked = isArchivedThirdPartyClicked;
}
export function setShopsSelectedRow(shopSelectedRow: any): any {
    store.shopsSelectedRow = shopSelectedRow;
}

export function setIsThirdParty(isThirdParty: boolean): void {
    store.isThirdParty = isThirdParty;
}
export function setShopsThirdPartyData(shopsThirdPartyData: any[]): void {
    store.shopsThirdPartyData = shopsThirdPartyData;
}

export function setActiveThirdParties(activeThirdParties: any): any {
    let localIndex: number = store.activeThirdParties.findIndex((e: any) => {
        return e.Id === activeThirdParties.Id;
    });
    if (localIndex === -1 || localIndex === undefined) {
        store.activeThirdParties.push(activeThirdParties);
    } else {
        store.activeThirdParties[localIndex] = activeThirdParties;
    }
}

export function setNumberArchived(number: number): void {
    store.numberArchived = number;
}

export function setNumberActive(number: number): void {
    store.numberActive = number;
}

export function setIsAssociateThirdPartyOpened(
    isAssociateThirdPartyOpened: boolean
): void {
    store.isAssociateThirdPartyOpened = isAssociateThirdPartyOpened;
}
export function setLocalShop(localShop: any[]): void {
    store.localShop = localShop;
}
export function setIsAssociateModalClosed(
    isAssociatedModalClosed: boolean
): void {
    store.isAssociatedModalClosed = isAssociatedModalClosed;
}

export function setNumberActiveMultiShop(number: number): void {
    store.numberActiveMultiShop = number;
}
export function setNumberArchiveMultiShop(number: number): void {
    store.numberArchiveMultiShop = number;
}

export function setIsThirdPartyAssociatedModifiedClosed(
    isThirdPartyAssociatedModifiedClosed: boolean
): void {
    store.isThirdPartyAssociatedModifiedClosed = isThirdPartyAssociatedModifiedClosed;
}
export function setIdsLocalShop(idsLocalShop: string[]): void {
    store.idsLocalShop = idsLocalShop;
}

export function setIsAssociatedThirdParty(
    isAssociatedThirdParty: boolean
): void {
    store.isAssociatedThirdParty = isAssociatedThirdParty;
}
export function setSelectedRow(selectedRows: any[]): void {
    store.selectedRows = selectedRows;
}

export function setIsAssociatedClosed(isAssociatedModalClosed: boolean): void {
    store.isAssociatedModalClosed = isAssociatedModalClosed;
}

export function setIds(id: number): void {
    store.ids = id;
}

export function setIsThirdPartyAssociatedArchivedClosed(
    isThirdPartyAssociatedArchivedClosed: boolean
): void {
    store.isThirdPartyAssociatedArchivedClosed = isThirdPartyAssociatedArchivedClosed;
}
export function setIsModified(isModified: boolean): void {
    store.isModified = isModified;
}
export function setIsActivation(isActivation: boolean): void {
    store.isActivation = isActivation;
}

export function setThirdPartyData(thirdPartyData: any): void {
    store.thirdPartyData = thirdPartyData;
}
export function setSelectedThirdParties(selectedThirdParties: any[]): any {
    store.selectedThirdParties = selectedThirdParties;
}

export function setArchivedData(archivedData: any[]): any {
    store.archivedData = archivedData;
}
export function setIsArchiveSVModalOpened(
    isArchiveSVModalOpened: boolean
): any {
    store.isArchiveSVModalOpened = isArchiveSVModalOpened;
}

export function setDataDesignationThirdParty(
    DesignationThirdPartyFranchise: any[]
): void {
    store.DesignationThirdPartyFranchise = DesignationThirdPartyFranchise;
}
export function setDataFranchiseThirdParty(
    dataFranchiseThirdParty: any[]
): void {
    store.dataFranchiseThirdParty = dataFranchiseThirdParty;
}
export function setShopDataIsUpdated(shopDataIsUpdated: boolean): void {
    store.shopDataIsUpdated = shopDataIsUpdated;
}
export function setFranchiseDataIsUpdated(
    franchiseDataIsUpdated: boolean
): void {
    store.franchiseDataIsUpdated = franchiseDataIsUpdated;
}
export function setAssociatedThirdParty(associatedThirdParty: any): void {
    store.associatedThirdParty = associatedThirdParty;
}
export function setListFranchiseArchived(listFranchiseArchived: any[]): void {
    store.listFranchiseArchived = listFranchiseArchived;
}
export function setIsInputUpdated(isInputUpdated: boolean): any {
    store.isInputUpdated = isInputUpdated;
}
export function setSelectedHistoryThirdParties(
    selectedHistoryThirdParties: any[]
): any {
    store.selectedHistoryThirdParties = selectedHistoryThirdParties;
}
export function setIsInlineErrorCleared(isInlineErrorCleared: any): any {
    store.isInlineErrorCleared = isInlineErrorCleared;
}
export function setThirdPartyToBeArchived(thirdPartyToBeArchived: any): any {
    store.thirdPartyToBeArchived = thirdPartyToBeArchived;
}

export function setIsEdited(isEdited: boolean): any {
    store.isEdited = isEdited;
}

export function setIsActionsClicked(isActionsClicked: boolean): any {
    store.isActionsClicked = isActionsClicked;
}

export function setValidationAction(validationAction: any): any {
    store.validationAction = validationAction;
}

export function setActions(actions: any): any {
    store.actions = actions;
}

export function setKey(key: any): void {
    store.key = key;
}

export default store;
