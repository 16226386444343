import React from "react";
import classnames from "classnames";
import { CheckboxIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { uuid } from "uuidv4";

import { store as useStore } from "../../../../components/VerticalLayout/store";
import {
    store,
    setIsHistoryShopsModalOpened,
    setShopDataIsUpdated,
    setFranchiseDataIsUpdated,
    setIsLoading,
    setKey,
} from "../store";

import ErrorToast from "../../../../components/Common/ErrorTost";
import { ConfirmationArchive } from "../ConfirmationArchive";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CreateDataThirdParty } from "../helper";
import { CustomSideFilterShopType } from "../Types";

export function CustomSideFilter({ data }: CustomSideFilterShopType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID, oneShop } = useSnapshot(useStore);
    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        isHistoryShopsModalOpened,
        listFranchiseArchived,
        modify,
        isLoading,
    } = useSnapshot(store);

    const [error, setError] = React.useState<boolean>(false);
    const [
        isArchivedValidationModalOpened,
        setIsArchivedValidationModalOpened,
    ] = React.useState<boolean>(false);

    const [isValidateClicked, setIsValidateClicked] = React.useState<boolean>(
        false
    );
    const uuidUser = localStorage.getItem("uuidUser");
    function ArchivedLanguagesFranchise() {
        let idsAssociated: any[] = [];
        if (listFranchiseArchived !== undefined) {
            listFranchiseArchived.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    const getArrayOfRows = () => {
        let arrayOfRows: any[] = [];
        let ids: any[] = [];
        data.selectedRows.forEach(async (el: any) => {
            if (
                ids.indexOf(`${el.shopId}${el.Id}`) === -1 &&
                el[t("Shop")] === undefined
            ) {
                arrayOfRows.push(el);
                ids.push(`${el.shopId}${el.Id}`);
            }
        });
        arrayOfRows = [...new Set(arrayOfRows)];

        return arrayOfRows;
    };

    let arrayOfRows = getArrayOfRows();

    async function handleArchiveThirdParty(value: boolean) {
        setIsLoading(true);
        data.setLimit(1);
        let localData: any = [];
        let arrayOfRows = getArrayOfRows();
        let itemsCantDelete: any = arrayOfRows.filter(
            (x: any) => x?.isFavorite === true && x?.isArchived === false
        );
        let itemsCanDelete: any = arrayOfRows.filter(
            (x: any) => x?.isFavorite === false
        );

        arrayOfRows = itemsCanDelete;
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty`;

        if (!data.isArchivedThirdPartyClicked) {
            if (!isValidateClicked) {
                setIsArchivedValidationModalOpened(true);
            }
            if (isValidateClicked && data.selectedRows.length !== 0) {
                arrayOfRows.forEach(async (el: any) => {
                    let thirdData = CreateDataThirdParty(
                        oneShop,
                        false,
                        el.shopId,
                        false,
                        el.isFavorite,
                        userID,
                        franchiseID,
                        operatorID,
                        uuidUser,
                        el.Id,
                        el[t("Third party")],
                        el.ip,
                        el.port,
                        el.salesId,
                        el.menusId,
                        el.baseUrl,
                        el[t("Remark")],
                        value,
                        el.apiKey
                    );

                    localData.push(thirdData);
                });
            }
            try {
                if (localData.length > 0) {
                    await fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(localData),
                    }).then((response) => response.json());
                }
            } catch (e) {
                setError(true);
                setIsLoading(false);
                toast.error(`${t("There's an error")}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
            if (itemsCantDelete.length > 0) {
                data.setLimit(2);
                ErrorToast(
                    t(`Please note, you cannot archive a favorite third party`)
                );
            }
            if (error === false && localData.length > 0) {
                toast.success(
                    !value
                        ? `${t(
                              "The third party has been successfully unarchived."
                          )!}`
                        : `${t(
                              "The third party has been successfully archived."
                          )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        autoClose: 2000,
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
                setIsLoading(false);
                setKey(uuid());
                setShopDataIsUpdated(true);
                setFranchiseDataIsUpdated(true);
            }
        } else {
            if (data.selectedRows.length !== 0) {
                arrayOfRows.forEach(async (el: any) => {
                    let thirdData = CreateDataThirdParty(
                        oneShop,
                        false,
                        el.shopId,
                        false,
                        el.isFavorite,
                        userID,
                        franchiseID,
                        operatorID,
                        uuidUser,
                        el.Id,
                        el[t("Third party")],
                        el.ip,
                        el.port,
                        el.salesId,
                        el.menusId,
                        el.baseUrl,
                        el[t("Remark")],
                        value,
                        el.apiKey
                    );
                    localData.push(thirdData);
                });
            }

            try {
                if (localData.length > 0) {
                    await fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(localData),
                    }).then((response) => response.json());
                }
            } catch (e) {
                setError(true);
                setIsLoading(false);
                toast.error(`${t("There's an error")}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
            if (itemsCantDelete.length > 0) {
                data.setLimit(2);
                ErrorToast(
                    t(`Please note, you cannot archive a favorite third party`)
                );
            }
            if (error === false && localData.length > 0) {
                setShopDataIsUpdated(true);
                setFranchiseDataIsUpdated(true);
                setIsLoading(false);
                setKey(uuid());
                toast.success(
                    !value
                        ? `${t(
                              "The third party has been successfully unarchived."
                          )!}`
                        : `${t(
                              "The third party has been successfully archived."
                          )!}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        theme: "colored",
                        icon: (
                            <CheckboxIcon height={25} width={25} fill="white" />
                        ),
                    }
                );
            }
        }
    }

    React.useEffect(() => {
        if (isValidateClicked) {
            handleArchiveThirdParty(true);
        }
        // eslint-disable-next-line
    }, [isValidateClicked]);

    return (
        <div className="d-flex justify-content-start align-items-center">
            {modify === true ? (
                <>
                    <CustomMainColorButton
                        className="w-100 ml-2 sdv_btn_BTassignThirdParties"
                        rounded
                        disabled={
                            data.isShopSelected &&
                            !data.isArchivedThirdPartyClicked
                                ? false
                                : true
                        }
                        variant="primary"
                        onClick={() => {
                            data.setIsAssociateModalOpened(
                                !data.isAssociateModalOpened
                            );
                            data.setLocalFilterActive!(false);
                            data.setSelectedShopsRows(data.selectedRows);
                        }}
                    >
                        {t("Assign third party")}
                    </CustomMainColorButton>
                </>
            ) : null}
            <VerticalDotsIcon
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
                className="cmn_drp_BTmenu"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_BTlistActivated "
                    )}
                    onClick={() => {
                        data.setIsArchivedThirdPartyClicked(false);
                    }}
                >
                    {t("Enabled third parties")} ({numberActiveMultiShop})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={classnames(
                        "dropdown-item pl-1 cmn_btn_BTlistArchived"
                    )}
                    onClick={() => {
                        data.setIsArchivedThirdPartyClicked(true);
                    }}
                >
                    {t("Archived third parties")} ({numberArchiveMultiShop})
                </div>
                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        "dropdown-item pl-1 cmn_btn_BThistory",
                        {
                            "not-allowed-icon__clz":
                                data.selectedRows.length === 1 ||
                                data.selectedRows.every(
                                    (elt: any) => elt[t("Shop")] !== undefined
                                ),
                        }
                    )}`}
                    onClick={() => {
                        setIsHistoryShopsModalOpened(
                            !isHistoryShopsModalOpened
                        );
                    }}
                >
                    {t("Historical")}
                </div>
                {modify === true ? (
                    <>
                        <div
                            style={{ cursor: "pointer" }}
                            className={`${classnames(
                                "dropdown-item pl-1 cmn_btn_BTarchived cmn_btn_BTunarchive",
                                {
                                    "not-allowed-icon__clz":
                                        isLoading ||
                                        data.selectedRows.length === 0 ||
                                        arrayOfRows.length === 0 ||
                                        (data.isArchivedThirdPartyClicked &&
                                            getArrayOfRows().some(
                                                (element: any) =>
                                                    ArchivedLanguagesFranchise().includes(
                                                        element.Id
                                                    )
                                            )) ||
                                        data.selectedRows.every(
                                            (elt: any) =>
                                                elt[t("Shop")] !== undefined
                                        ),
                                }
                            )}`}
                            onClick={async () => {
                                if (
                                    data.selectedRows.length === 1 &&
                                    (data.selectedRows[0].subRows ===
                                        undefined ||
                                        data.selectedRows[0].subRows.length < 2)
                                ) {
                                    ErrorToast(
                                        `${t(
                                            "Attention, it is a multiple action. Please select at least two items"
                                        )!}`
                                    );
                                } else {
                                    if (data.isArchivedThirdPartyClicked) {
                                        await handleArchiveThirdParty(false);
                                    } else {
                                        setIsArchivedValidationModalOpened(
                                            true
                                        );
                                    }
                                }
                            }}
                        >
                            <span>
                                {data.isArchivedThirdPartyClicked
                                    ? t("Unarchive")
                                    : t("Archive")}
                            </span>
                        </div>
                    </>
                ) : null}
            </div>
            {isArchivedValidationModalOpened ? (
                <ConfirmationArchive
                    data={{
                        isArchiveSVModalOpened: isArchivedValidationModalOpened,
                        setIsArchiveSVModalOpened: setIsArchivedValidationModalOpened,
                        setIsValidateClicked: setIsValidateClicked,
                    }}
                />
            ) : null}
        </div>
    );
}
