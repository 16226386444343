import React from "react";
import { t } from "i18next";
import { AvForm } from "availity-reactstrap-validation";
import { StyledTextInput } from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import ReactSwitch from "react-switch";
import classnames from "classnames";

import {
    generalConfigStore,
    setInitialStepTimeout,
    setTimeOutOptions,
    setKeyboard,
    setIsAutoLaunchActivated,
    setIsFieldRequiredEmpty,
} from "@store";

import { CustomErrorBoundary } from "@components/CustomErrorBoundary";

import "../../index.css";

export function OtherTabs(): JSX.Element {
    const {
        project: {
            template: { timeout, initialStepTimeout, autoLaunch, keyboard },
        },
    } = useSnapshot(generalConfigStore);

    const [timeoutOption] = React.useState(timeout);
    const [
        fieldAutoTimeoutNoValid,
        setFieldAutoTimeoutNoValid,
    ] = React.useState(false);
    const [waitingTimeoutNoValid, setWaitingTimeoutNoValid] = React.useState(
        false
    );
    const [isKeyboardChecked, setIsKeyboardChecked] = React.useState<boolean>(
        keyboard === "azerty" ? false : true
    );
    const [isAutoLaunchChecked, setIsAutoLaunchChecked] = React.useState<
        boolean
    >(autoLaunch);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTimeOutOptions(Number(event.target.value));

        if (Number(event.target.value) < 5) {
            setFieldAutoTimeoutNoValid(true);
            setIsFieldRequiredEmpty(true);
        }
        if (Number(event.target.value) >= 5) {
            setFieldAutoTimeoutNoValid(false);
            setIsFieldRequiredEmpty(false);
        }
    };

    return (
        <CustomErrorBoundary>
            <div
                className="d-flex flex-column p-3 cursor__clz"
                style={{ gap: "30px" }}
            >
                <div className="d-flex align-items-center">
                    <span
                        style={{
                            font: "normal normal  Nunito Sans",
                            fontSize: "30px",
                            marginLeft: "25px",
                        }}
                    >
                        {t("Automatic Order Abandonment Alert")}
                    </span>
                </div>
                <div
                    className="d-flex flex-column justify-content-start ml-5"
                    style={{ gap: "30px" }}
                >
                    <div className="d-flex flex-column p-2">
                        <div
                            style={{
                                fontSize: "25px",
                            }}
                        >
                            {t("Auto abort popup display timeout")}:
                        </div>
                        <div
                            className="d-flex flex-column align-item-center p-2"
                            style={{ gap: "20px" }}
                        >
                            <div
                                className="d-flex flex-row align-item-center"
                                style={{ gap: "30px" }}
                            >
                                <AvForm
                                    style={{
                                        width: "80px",
                                        height: "30px",
                                    }}
                                >
                                    <StyledTextInput
                                        className={classnames(
                                            "ksk_inp_autoAbortPopupDisplayTimeout",
                                            {
                                                invalid__clz: fieldAutoTimeoutNoValid,
                                            }
                                        )}
                                        id="input-timeout-show-popup"
                                        name="input-timeout-show-popup"
                                        placeholder={t("Put")}
                                        type="number"
                                        value={timeoutOption as number}
                                        onChange={handleInputChange}
                                    />
                                </AvForm>
                                <span
                                    className="d-flex align-items-end"
                                    style={{
                                        fontSize: "20px",
                                    }}
                                >
                                    {t("Second(s)")}
                                </span>
                            </div>
                            {fieldAutoTimeoutNoValid ? (
                                <div
                                    style={{
                                        width: "100%",

                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("This field is not valid")!}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-start p-2">
                        <div
                            style={{
                                fontSize: "25px",
                            }}
                        >
                            {t(
                                "Waiting time for the return to the initial stage"
                            )}
                            :
                        </div>
                        <div
                            className="d-flex flex-column align-item-center p-2"
                            style={{ gap: "20px" }}
                        >
                            <div
                                className="d-flex flex-row align-item-center"
                                style={{ gap: "30px" }}
                            >
                                <AvForm
                                    style={{
                                        width: "80px",
                                        height: "30px",
                                    }}
                                >
                                    <StyledTextInput
                                        className={classnames(
                                            "ksk_inp_waitingTimeForTheReturnToTheInitialStage",
                                            {
                                                invalid__clz: waitingTimeoutNoValid,
                                            }
                                        )}
                                        id="waiting-timeout"
                                        name="waiting-timeout"
                                        placeholder={t("Put")}
                                        type="number"
                                        value={initialStepTimeout as number}
                                        defaultValue={initialStepTimeout}
                                        onChange={(
                                            event: React.ChangeEvent<
                                                HTMLInputElement
                                            >
                                        ) => {
                                            setInitialStepTimeout(
                                                Number(event.target.value)
                                            );
                                            if (
                                                Number(event.target.value) < 5
                                            ) {
                                                setWaitingTimeoutNoValid(true);
                                                setIsFieldRequiredEmpty(true);
                                            }
                                            if (
                                                Number(event.target.value) >= 5
                                            ) {
                                                setWaitingTimeoutNoValid(false);
                                                setIsFieldRequiredEmpty(false);
                                            }
                                        }}
                                    />
                                </AvForm>
                                <span
                                    className="d-flex align-items-end"
                                    style={{
                                        fontSize: "20px",
                                    }}
                                >
                                    {t("Second(s)")}
                                </span>
                            </div>
                            {waitingTimeoutNoValid ? (
                                <div
                                    style={{
                                        width: "100%",

                                        fontSize: "80%",
                                        color: "#f46a6a",
                                    }}
                                >
                                    {t("This field is not valid")!}
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center">
                    <span
                        style={{
                            font: "normal normal  Nunito Sans",
                            fontSize: "30px",
                            marginLeft: "25px",
                        }}
                    >
                        {t("Typing keyboard")}
                    </span>
                </div>
                <div
                    className="d-flex justify-content-start ml-5"
                    style={{ gap: "30px" }}
                >
                    <div
                        className="d-flex pl-2"
                        style={{
                            fontSize: "20px",
                        }}
                    >
                        AZERTY
                    </div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#d6d6d6"
                        offHandleColor="#34C38F"
                        onChange={() => {
                            setIsKeyboardChecked(!isKeyboardChecked);
                            setKeyboard(
                                isKeyboardChecked === true ? "azerty" : "qwerty"
                            );
                        }}
                        checked={isKeyboardChecked}
                        onColor="#d6d6d6"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                        className="ksk_icn_typingKeyboard"
                    />
                    <div
                        style={{
                            fontSize: "20px",
                        }}
                    >
                        QWERTY
                    </div>
                </div>
                <div
                    className="d-none justify-content-start  align-items-center"
                    style={{ gap: "30px" }}
                >
                    <div
                        className="d-flex pl-2"
                        style={{
                            font: "normal normal  Nunito Sans",
                            fontSize: "30px",
                            marginLeft: "25px",
                        }}
                    >
                        {t("Auto Launch")}
                    </div>
                    <ReactSwitch
                        uncheckedIcon={false}
                        disabled={false}
                        checkedIcon={false}
                        handleDiameter={26}
                        offColor="#d6d6d6"
                        offHandleColor="#E30613"
                        onChange={() => {
                            setIsAutoLaunchActivated(!isAutoLaunchChecked);
                            setIsAutoLaunchChecked(!isAutoLaunchChecked);
                        }}
                        checked={isAutoLaunchChecked}
                        onColor="#d6d6d6"
                        onHandleColor="#34C38F"
                        width={50}
                        height={20}
                    />
                </div>
            </div>
        </CustomErrorBoundary>
    );
}
