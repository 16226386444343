import React from "react";
import classnames from "classnames";
import { toast } from "react-toastify";
import {
    CheckboxIcon,
    EyeIcon,
    SettingsIcon,
    TrashIcon,
} from "@aureskonnect/react-ui";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Tooltip } from "@mui/material";

import { store as fidelity } from "../../../../components/VerticalLayout/store";
import store, {
    setIsConsultModalOpened,
    setNumberActiveMultiShop,
    setNumberArchiveMultiShop,
} from "../store";

import { ConfirmationArchive } from "../ConfirmationArchive";
import ConsultSvgIcon from "@components/Common/SvgIcons/ConsultSvgIcon";
import ErrorToast from "@components/Common/ErrorTost";
import { getFidelityAndEditorPos } from "@helpers/general";

type CustomIconsType = {
    e: any;
    setDataIsUpdated: Function;
    setSelectedRows: Function;
    isFidelityArchived: boolean;
    selectedRows: any;
    setIsConsult: Function;
    setIsAddFidelityClicked: Function;
    setFidelity: Function;
    dataFidelity: any;
};
export function CustomIcons({
    e,
    setDataIsUpdated,
    setSelectedRows,
    isFidelityArchived,
    selectedRows,
    setIsConsult,
    setIsAddFidelityClicked,
    setFidelity,
    dataFidelity,
}: CustomIconsType) {
    const { t } = useTranslation();

    const {
        numberActiveMultiShop,
        numberArchiveMultiShop,
        fidelityData,
        shopsFidelityData,
        modify,
        isSupportCodeValid,
        selectedShopId,
    } = useSnapshot(store);
    const { franchiseID, userID, oneShop, operatorID } = useSnapshot(fidelity);
    // eslint-disable-next-line
    const [fidelityState, setFidelityState] = React.useState<boolean>(
        e.selectedRow.isActive
    );

    const [isArchiveModalOpened, setIsArchiveModalOpened] = React.useState<
        boolean
    >(false);
    const uuidUser = localStorage.getItem("uuidUser");

    let dataArchive: any[] = [];

    if (!oneShop) {
        // eslint-disable-next-line
        dataArchive = archiveFidelity();
    }

    function archiveFidelity() {
        let idsAssociated: any = [];
        if (fidelityData.data !== undefined) {
            fidelityData.data.forEach((element: any) => {
                idsAssociated.push(element.Id);
            });
        }
        idsAssociated = [...new Set(idsAssociated)];
        return idsAssociated;
    }

    // eslint-disable-next-line
    async function activeFidelity() {
        if (e.selectedRow.fidelityChoiceIndex === 1) {
            const index = e.selectedRow.isArchived.findIndex(
                (shop: any) =>
                    shop.shopId === `${e.selectedRow.affectedShop}` ||
                    shop.shopId === e.selectedRow.affectedShop
            );
            if (index !== -1) {
                e.selectedRow.isArchived[index].status = false;
            }
        }
        toast.dismiss();
        let dataFidelity = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            isShop: true,
            data: {
                [e.selectedRow.Id]: {
                    designation: e.selectedRow[t("Display name")],
                    type: e.selectedRow[t("Loyalty")],
                    number_fidelity_points:
                        e.selectedRow.number_fidelity_points,
                    money_correspondence: e.selectedRow.moneyCorrespondence,
                    devise: e.selectedRow.Devise,
                    pays: e.selectedRow.Pays,
                    authentication_mode: e.selectedRow.authentication_mode,
                    sortedVisibility: e.selectedRow.sortedVisibility,
                    Login: e.selectedRow.Login,
                    password: e.selectedRow[t("Password")],
                    note: e.selectedRow[t("Remark")],
                    isActive: false,
                    isArchived:
                        e.selectedRow.fidelityChoiceIndex !== 1
                            ? false
                            : JSON.stringify(e.selectedRow.isArchived),
                    shopId: e.selectedRow.shopId,
                    dashboardLink: e.selectedRow[t("Dashboard Link")],
                    user: e.selectedRow.user,
                    apiKey: e.selectedRow["API key"],
                    auresKonnectGroup: e.selectedRow[t("Group")],
                    isCentralizationActive: e.selectedRow[t("State")],
                    isVisibiliteActive: e.selectedRow[t("isVisibiliteActive")],

                    fidelityChoiceIndex: e.selectedRow.fidelityChoiceIndex,
                },
            },
        };

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataFidelity),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${t(
                                "The fidelity setting has been successfully unarchive"
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setDataIsUpdated!(true);
                        setTimeout(async () => {
                            await getFidelityAndEditorPos(
                                franchiseID,
                                uuidUser as string
                            );
                        }, 2000);
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    // eslint-disable-next-line
    async function handleStateChange() {
        let id = e.selectedRow.Id;
        let obj = {
            userId: userID,
            shopId: [e.selectedRow.shopId],
            franchiseId: franchiseID,
            operatorId: operatorID,
            uuidUser: uuidUser,
            isShop: false,
            data: {
                [id]: {
                    designation: e.selectedRow[t("Display name")],
                    type: e.selectedRow[t("Loyalty")],
                    number_fidelity_points:
                        e.selectedRow.number_fidelity_points,
                    money_correspondence: e.selectedRow.moneyCorrespondence,
                    devise: e.selectedRow.Devise,
                    pays: e.selectedRow.Pays,
                    authentication_mode: e.selectedRow.authentication_mode,
                    sortedVisibility: e.selectedRow.sortedVisibility,
                    Login: e.selectedRow.Login,
                    password: e.selectedRow[t("Password")],
                    note: e.selectedRow[t("Remark")],
                    isArchived: false,
                    isActive: !fidelityState,
                    shopId: e.selectedRow.shopId,
                    auresKonnectGroup: e.selectedRow[t("Group")],
                    isCentralizationActive: e.selectedRow[t("State")],
                    isVisibiliteActive: e.selectedRow[t("isVisibiliteActive")],

                    fidelityChoiceIndex: e.selectedRow.fidelityChoiceIndex,
                },
            },
        };

        let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity`;
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(obj),
                method: "POST",
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.error) {
                        throw Error(result.message);
                    }

                    toast.success(
                        !fidelityState
                            ? `${t(
                                  "The fidelity setting has been successfully activated"
                              )}`
                            : `${t(
                                  "The fidelity setting has been successfully deactivated"
                              )}`,

                        {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            icon: (
                                <CheckboxIcon
                                    height={25}
                                    width={25}
                                    fill="white"
                                />
                            ),
                        }
                    );
                    setDataIsUpdated!(true);
                })
                .catch((error: any) => {
                    toast.error(`${t("There's an error")!}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        theme: "colored",
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                })
        );
    }

    return (
        <React.Fragment>
            {e.selectedRow?.subRows === undefined ? (
                <div className="d-flex justify-content-around">
                    {modify === false || isFidelityArchived ? (
                        <Tooltip title={t("To consult")}>
                            <div>
                                {" "}
                                <ConsultSvgIcon
                                    className="pointer__clz afa_icn_toConsult"
                                    height={35}
                                    width={35}
                                    style={{
                                        cursor: "pointer",
                                        position: "relative",
                                    }}
                                    fill="black"
                                    onClick={() => {
                                        setFidelity(e.selectedRow);
                                        setIsAddFidelityClicked(true);
                                        setIsConsult(true);
                                    }}
                                />
                            </div>
                        </Tooltip>
                    ) : null}
                    {modify === true ? (
                        <>
                            <Tooltip
                                title={isFidelityArchived ? "" : t("configure")}
                            >
                                <div>
                                    <SettingsIcon
                                        onClick={() => {
                                            if (
                                                e.selectedRow
                                                    .fidelityChoiceIndex === 1
                                            ) {
                                                toast.info(
                                                    `${t(
                                                        "Please note, Softavera loyalty can only be modified by the franchise."
                                                    )!}`,
                                                    {
                                                        position:
                                                            toast.POSITION
                                                                .TOP_CENTER,
                                                        autoClose: 2000,
                                                        theme: "colored",
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                    }
                                                );
                                            }

                                            setIsConsultModalOpened(true);
                                            setSelectedRows([e.selectedRow]);
                                        }}
                                        height={25}
                                        width={25}
                                        style={{
                                            marginTop: "8px",
                                            paddingRight: "5px",
                                        }}
                                        className={`${classnames(
                                            "pointer__clz fed_icn_BTconsult",
                                            {
                                                "not-allowed-icon__clz": isFidelityArchived,
                                            }
                                        )}`}
                                    />
                                </div>
                            </Tooltip>
                            {!isFidelityArchived ? (
                                <Tooltip
                                    title={
                                        e.selectedRow[t("State")] === true
                                            ? ""
                                            : t("Archive")
                                    }
                                >
                                    <div>
                                        <TrashIcon
                                            onClick={() => {
                                                ErrorToast(
                                                    `${t(
                                                        "Please note, loyalty archiving is done exclusively by the franchise."
                                                    )!}`
                                                );
                                            }}
                                            height={25}
                                            width={25}
                                            fill="red"
                                            style={{ cursor: "pointer" }}
                                            className={`${classnames(
                                                "pointer__clz mt-2 cmn_icn_BTarchived"
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={
                                        dataArchive.includes(e.selectedRow.Id)
                                            ? ""
                                            : t("Unarchive")
                                    }
                                >
                                    <div>
                                        <EyeIcon
                                            height={25}
                                            width={25}
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if (
                                                    shopsFidelityData.othersData.allFidelity.data.filter(
                                                        (el: any) =>
                                                            (e.selectedRow
                                                                .fidelityChoiceIndex ===
                                                                1 &&
                                                                el.shopId ===
                                                                    e
                                                                        .selectedRow
                                                                        .affectedShop) ||
                                                            (e.selectedRow
                                                                .fidelityChoiceIndex &&
                                                                el.shopId ===
                                                                    e
                                                                        .selectedRow
                                                                        .shopId)
                                                    )[0].subRows.length !== 0
                                                ) {
                                                    ErrorToast(
                                                        t(
                                                            "Attention,a shop cannot have more than one fidelity settings"
                                                        )
                                                    );
                                                } else {
                                                    activeFidelity();
                                                    setNumberActiveMultiShop(
                                                        numberActiveMultiShop +
                                                            1
                                                    );
                                                    setNumberArchiveMultiShop(
                                                        numberArchiveMultiShop -
                                                            1
                                                    );
                                                }
                                            }}
                                            className={`${classnames(
                                                "pointer__clz mt-2 mr-2 cmn_icn_BTunarchive",
                                                {
                                                    "not-allowed-icon__clz": dataArchive.includes(
                                                        e.selectedRow.Id
                                                    ),
                                                }
                                            )}`}
                                        />
                                    </div>
                                </Tooltip>
                            )}
                        </>
                    ) : null}
                </div>
            ) : null}
            {(isArchiveModalOpened || isSupportCodeValid) &&
            selectedShopId === e.selectedRow.shopId ? (
                <ConfirmationArchive
                    isModalOpened={isArchiveModalOpened || isSupportCodeValid}
                    setIsModalOpened={setIsArchiveModalOpened}
                    e={e}
                    setDataIsUpdated={setDataIsUpdated}
                    selectedRows={selectedRows}
                />
            ) : null}
        </React.Fragment>
    );
}
