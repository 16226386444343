import React from "react";
import ReactSwitch from "react-switch";
import SimpleBar from "simplebar-react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { SettingsIcon, StyledIconButton } from "@aureskonnect/react-ui";

import {
    generalConfigStore,
    designStore,
    setActiveMainContentItems,
    setIsInformationModesPersonalized,
    setSelectedInformationModes,
    setMainContentItems,
    setConfigInformationModes,
    computedStore,
    setIsInformationModesSkipped,
} from "@store";

import { PersonalizedInformationModes } from "@components/Common/Design/MainContentWrapper/PersonalizedInformationModes/MainContent";
import { SalesMethodsOptionsWrapper } from "@components/Common/Design/MainContentWrapper/InformationModes/SalesMethodsOptionsWrapper";

import { store as storeGlobal } from "@components/VerticalLayout/store";

import "@components/Common/Design/index.css";

type MainContentWrapperPropsType = {
    title: string;
};

export function InformationModes({
    title,
}: MainContentWrapperPropsType): JSX.Element {
    const { t } = useTranslation();

    const {
        project: { template, shopId },
        selectedSaleModeValue,
        itemsDisable,
    } = useSnapshot(generalConfigStore);
    const {
        mainContentItems,
        isInformationModesPersonalized,
        activeSlideIndex,
        activeMainContentItems,
    } = useSnapshot(designStore);

    const { elements } = useSnapshot(computedStore);
    const { shopID, oneShop } = useSnapshot(storeGlobal);

    const shop_id = oneShop ? shopID : shopId !== undefined ? shopId : shopID;

    const [
        isSkipThisStepSwitchChecked,
        setIsSkipThisStepSwitchChecked,
    ] = React.useState<boolean>(
        ((template.pages.ways[elements[activeSlideIndex].name] as PageType)
            ?.skipped as ItemContentType)[selectedSaleModeValue]
    );

    React.useEffect(() => {
        setIsSkipThisStepSwitchChecked(
            ((template.pages.ways[elements[activeSlideIndex].name] as PageType)
                ?.skipped as ItemContentType)[selectedSaleModeValue]
        );
    }, [selectedSaleModeValue, activeSlideIndex, template, elements]);

    function handleSkipThisStepOnClickEvent() {
        setIsSkipThisStepSwitchChecked(!isSkipThisStepSwitchChecked);
        setIsInformationModesSkipped(
            !isSkipThisStepSwitchChecked,
            selectedSaleModeValue
        );
    }

    function handleActivateSwitchOnClickEvent(
        item: ProjectMainContentItemType
    ) {
        const array = [...mainContentItems];

        const element = array.find((el) => el.name === item.name);

        if (element !== undefined) {
            const itemIndex = array.indexOf(element);

            array[itemIndex] = {
                ...element,
                active: {
                    ...(item.active as ItemContentType),
                    [selectedSaleModeValue]: !(item.active as ItemContentType)[
                        selectedSaleModeValue
                    ],
                },
            };

            setMainContentItems(array);
            setConfigInformationModes(array);
        }
    }

    function handleOnClickEvent(item: ProjectMainContentItemType) {
        setIsInformationModesPersonalized(!isInformationModesPersonalized);
        setSelectedInformationModes(item);
    }

    React.useEffect(() => {
        setActiveMainContentItems(
            mainContentItems.filter((item: ProjectMainContentItemType) => {
                return (
                    (item.active as ItemContentType)[selectedSaleModeValue] ===
                    true
                );
            })
        );
    }, [mainContentItems, selectedSaleModeValue]);

    React.useEffect(() => {
        if (
            mainContentItems.filter(
                (item: ProjectMainContentItemType) =>
                    (item.active as ItemContentType)[selectedSaleModeValue] ===
                    true
            ).length > 1
        ) {
            setIsSkipThisStepSwitchChecked(false);
            setIsInformationModesSkipped(false, selectedSaleModeValue);
        }
    }, [mainContentItems, selectedSaleModeValue]);

    return (
        <React.Fragment>
            {isInformationModesPersonalized ? (
                <PersonalizedInformationModes />
            ) : (
                <div
                    className="mt-4 rounded border"
                    style={{
                        width: "93%",
                        height: "96%",
                        borderColor: "#CECECE",
                    }}
                >
                    <div
                        className="d-flex justify-content-between"
                        style={{
                            backgroundColor: "#EDEDED",
                            height: "40px",
                        }}
                    >
                        <div
                            className="text-left pl-4 d-flex align-items-center cursor__clz"
                            style={{
                                fontSize: "20px",
                            }}
                        >
                            {t(title)}
                        </div>
                        {activeMainContentItems.length === 1 ? (
                            <div
                                className="py-2 d-flex align-items-center justify-content-center cursor__clz"
                                style={{
                                    fontSize: "17px",
                                }}
                            >
                                {t(
                                    "Do not offer this step in the customer journey"
                                )}

                                <ReactSwitch
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    handleDiameter={26}
                                    offColor="#f7b4b8"
                                    offHandleColor="#E30613"
                                    checked={
                                        isSkipThisStepSwitchChecked !==
                                        undefined
                                            ? isSkipThisStepSwitchChecked
                                            : false
                                    }
                                    onChange={handleSkipThisStepOnClickEvent}
                                    onColor="#c2eddd"
                                    onHandleColor="#34C38F"
                                    width={50}
                                    height={20}
                                />
                            </div>
                        ) : null}
                    </div>

                    <div
                        className="d-flex align-items-center justify-content-between p-4"
                        style={{
                            height: "40px",
                            fontSize: "20px",
                        }}
                    >
                        <SalesMethodsOptionsWrapper />
                    </div>

                    <SimpleBar autoHide={true} style={{ maxHeight: 133 }}>
                        <div
                            className="px-4 py-2"
                            style={{ display: "grid", gap: "10px" }}
                        >
                            {mainContentItems.map(
                                (
                                    item: ProjectMainContentItemType,
                                    index: number
                                ) => {
                                    return (
                                        <div
                                            key={item.id}
                                            className="switch-items-with-sub-steps__clz cursor__clz"
                                        >
                                            <span>{t(item.name)}</span>

                                            <StyledIconButton
                                                icon="SettingsIcon"
                                                className="m-0"
                                                onClick={() => {
                                                    handleOnClickEvent(item);
                                                }}
                                            >
                                                <SettingsIcon
                                                    height={25}
                                                    width={25}
                                                />
                                            </StyledIconButton>
                                            <div className="d-flex justify-content-end">
                                                <ReactSwitch
                                                    uncheckedIcon={false}
                                                    checkedIcon={false}
                                                    handleDiameter={26}
                                                    offColor="#f7b4b8"
                                                    offHandleColor="#E30613"
                                                    checked={
                                                        (mainContentItems[index]
                                                            .active as {
                                                            [
                                                                key: string
                                                            ]: boolean;
                                                        })[
                                                            selectedSaleModeValue
                                                        ]
                                                    }
                                                    onChange={() => {
                                                        handleActivateSwitchOnClickEvent(
                                                            mainContentItems[
                                                                index
                                                            ]
                                                        );
                                                    }}
                                                    onColor="#c2eddd"
                                                    onHandleColor="#34C38F"
                                                    width={50}
                                                    height={20}
                                                    disabled={
                                                        itemsDisable.find(
                                                            (element: any) =>
                                                                element.id ===
                                                                    item.id &&
                                                                element.shopId ===
                                                                    shop_id
                                                        ) !== undefined
                                                    }
                                                />
                                            </div>
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </SimpleBar>
                </div>
            )}
        </React.Fragment>
    );
}
