import React from "react";
import { useTranslation } from "react-i18next";
import { CheckboxIcon, CrossIcon } from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import { Modal } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";
import { mutate } from "swr";

import { store } from "@components/VerticalLayout/store";
import { setIsEdited, setIsLoading, setShopDataIsUpdated } from "../store";

import {
    CustomCardFooter,
    CustomCardHeader,
} from "../../../../components/Common/CustomCardStyled";
import { capitalize } from "../../../../helpers/general";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { ThirdPartyBody } from "../ThirdPartyFranchise/ThirdPartyBody";
import { CreateDataThirdParty } from "../helper";
import { ThirdPartyConfigureType } from "../Types";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

export default function ThirdPartyConfigure({ data }: ThirdPartyConfigureType) {
    const { t } = useTranslation();
    const { userID, operatorID, franchiseID } = useSnapshot(store);
    const uuidUser = localStorage.getItem("uuidUser");
    const [thirdPartyLabel, setThirdPartyLabel] = React.useState<string>(
        data.rowData[t("Third party")]
    );
    const [inputThirdPartyLabel, setInputThirdPartyLabel] = React.useState<
        boolean
    >(false);

    const [remark, setRemark] = React.useState<string>(
        data.rowData[t("Remark")]
    );
    const [salesId, setSalesId] = React.useState<any>(data.rowData.salesId);
    const [menusId, setMenusId] = React.useState<any>(data.rowData.menusId);
    const [baseUrl, setBaseUrl] = React.useState<any>(data.rowData.baseUrl);
    const [ip, setIp] = React.useState<any>(data.rowData.ip);
    const [apiKey, setApiKey] = React.useState<any>(data.rowData.apiKey);
    const [inputMenusId, setInputMenusId] = React.useState<boolean>(false);
    const [inputSalesId, setInputSalesId] = React.useState<boolean>(false);
    const [inputBaseUrl, setInputBaseUrl] = React.useState<boolean>(false);
    const [isInvalidBaseUrl, setIsInvalidBaseUrl] = React.useState<boolean>(
        false
    );
    const [inputPort, setInputPort] = React.useState<boolean>(false);
    const [inputApiKey, setInputApiKey] = React.useState<boolean>(false);
    const [emptyIp, setEmptyIp] = React.useState<boolean>(false);
    const [port, setPort] = React.useState<any>(data.rowData.portApi);
    const [inputIp, setInputIp] = React.useState<boolean>(false);

    async function configureThirdParty() {
        toast.dismiss();
        if (thirdPartyLabel === "") {
            setInputThirdPartyLabel(true);
        }
        if (ip === "") {
            setInputIp(true);
        }
        if (port === "") {
            setInputPort(true);
        }
        if (salesId === "") {
            setInputSalesId(true);
        }
        if (menusId === "") {
            setInputMenusId(true);
        }
        if (baseUrl === "") {
            setInputBaseUrl(true);
        }
        if (apiKey === "") {
            setInputApiKey(true);
        }
        if (
            (thirdPartyLabel === "ADORIA" &&
                ip !== "" &&
                salesId !== "" &&
                menusId !== "" &&
                baseUrl !== "" &&
                !isInvalidBaseUrl &&
                port !== "" &&
                !inputIp) ||
            (thirdPartyLabel !== "ADORIA" && apiKey !== "")
        ) {
            let dataThirdParty = CreateDataThirdParty(
                false,
                false,
                data.rowData.shopId,
                data.rowData.isActive,
                data.rowData.isFavorite,
                userID,
                franchiseID,
                operatorID,
                uuidUser,
                data.rowData.Id,
                thirdPartyLabel,
                ip,
                port,
                salesId,
                menusId,
                baseUrl,
                remark,
                false,
                apiKey
            );

            const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty`;
            const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify([dataThirdParty]),
            };

            try {
                mutate(
                    apiUrlAdd,

                    await fetch(apiUrlAdd, requestOptions)
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.error) {
                                throw Error("error");
                            }
                            toast.success(
                                `${t(
                                    "The third party has been successfully modified."
                                )!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    theme: "colored",
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            data.setIsModalOpened(false);
                            setShopDataIsUpdated(true);
                            setIsEdited(false);
                            setThirdPartyLabel("");
                            setIsEdited(false);
                            setIsLoading(false);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
            }
        }
    }
    return (
        <React.Fragment>
            <Modal
                centered
                isOpen={data.isModalOpened}
                className="information_fidelity_modal__clz mt-5"
            >
                <AvForm className="card information_fidelity__clz">
                    <CustomCardHeader className="d-flex align-items-center justify-content-between">
                        <h5 className="pt-3">
                            {capitalize(t("Third party setting"))}
                        </h5>
                        <CrossIcon
                            className="icon-close-notification__clz"
                            height={13}
                            width={13}
                            onClick={() => {
                                data.setIsModalOpened(false);
                            }}
                        />
                    </CustomCardHeader>
                    <ThirdPartyBody
                        data={{
                            editedData: data.rowData,
                            ip,
                            baseUrl,
                            port,
                            salesId,
                            menusId,
                            apiKey,
                            remark,
                            generalThirdParties: [],
                            thirdPartyLabel,
                            setThirdPartyLabel,
                            setIp,
                            setPort,
                            setSalesId,
                            setMenusId,
                            setBaseUrl,
                            setRemark,
                            setInputBaseUrl,
                            setInputMenusId,
                            setInputSalesId,
                            setInputPort,
                            setInputIp,
                            setApiKey,
                            setInputApiKey,
                            setIsInvalidBaseUrl,
                            setInputThirdPartyLabel,
                            setEmptyIp,
                            inputThirdPartyLabel,
                            isNameExist: false,
                            inputIp,
                            emptyIp,
                            inputPort,
                            isInvalidBaseUrl,
                            inputBaseUrl,
                            inputSalesId,
                            inputMenusId,
                            inputApiKey,
                            isConsult: data.isConsult,
                            isConfig: true,
                        }}
                    />
                    <CustomCardFooter className="pt-1 pb-2 mt-4">
                        <CustomSecondaryColorButton
                            outline
                            rounded
                            className="mr-2 fed_btn_FRcancel cmn_btn_cancel"
                            variant="light"
                            onClick={() => {
                                data.setIsModalOpened(false);
                                setIsEdited(false);
                            }}
                        >
                            <span> {t("Cancel")}</span>
                        </CustomSecondaryColorButton>
                        <CustomMainColorButton
                            variant="primary"
                            onClick={() => {
                                configureThirdParty();
                                setIsEdited(false);
                            }}
                            rounded
                        >
                            {t("Validate")}
                        </CustomMainColorButton>
                    </CustomCardFooter>
                </AvForm>
            </Modal>
        </React.Fragment>
    );
}
