import React from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { CheckboxIcon, StyledH2, StyledModal } from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { uuid } from "uuidv4";

import { store } from "@components/VerticalLayout/store";
import {
    setFranchiseDataIsUpdated,
    setIds,
    setSelectedRow,
    setShopDataIsUpdated,
    store as dataStore,
    setKey,
    setIsLoading,
} from "../store";

import ErrorToast from "@components/Common/ErrorTost";
import { CustomIconsAssociateModal } from "./CustomIconsAssociateModal";
import { ConfirmationMessage } from "./ConfirmationMessage";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import NProgressWrapper from "@components/Common/NProgressWrapper";
import { ThirdPartyAssociateModalType } from "../Types";
import { CreateDataThirdParty } from "../helper";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export function ThirdPartyAssociateModal({
    data,
}: ThirdPartyAssociateModalType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID, oneShop } = useSnapshot(store);
    const {
        idsLocalShop,
        activeThirdParties,
        shopsSelectedRow,
        localShop,
        associatedThirdParty,
        shopDataIsUpdated,
        thirdPartiesFavored,
        isLoading,
    } = useSnapshot(dataStore);
    const shopId = 0;
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const uuidUser = localStorage.getItem("uuidUser");
    const [isValidateClicked, setIsValidateClicked] = React.useState<
        boolean | number
    >(false);
    const [isModalOpened, setIsModalOpened] = React.useState<boolean>(false);
    const [isModalArchivedOpened, setIsModalArchivedOpened] = React.useState<
        boolean
    >(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [selectedThirdParty, setSelectedThirdParty] = React.useState<any[]>(
        []
    );
    const [
        isValidateButtonDisabled,
        setIsValidateButtonDisabled,
    ] = React.useState<boolean>(false);
    const url =
        localShop.length === 1
            ? `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdPartyToBeAssociate?userId=${userID}&shopId=${localShop[0].id}&franchiseId=${franchiseID}`
            : `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;

    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 6,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIconsAssociateModal
                    data={{
                        element: e,
                        isOneShopClicked:
                            data.selectedShopsRows.filter(
                                (el: any) => el.subRows !== undefined
                            ).length < 2,
                        selectedThirdParty,
                    }}
                />
            ),
        },
    ];

    async function handleAssociateThirdPartyButtonClick() {
        setIsLoading(true);
        let localData: any = [];
        let localShopsSelectedRow: any = [];
        let archived: any = {};
        if (isValidateButtonDisabled === false) {
            setIsValidateButtonDisabled(true);
            const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty`;
            if (shopsSelectedRow.length > 0) {
                shopsSelectedRow.forEach((element: any) => {
                    element?.archivedThirdParty?.forEach((el: any) => {
                        Object.assign(archived, {
                            [el.shopId]: el.Id,
                        });
                    });
                    localShopsSelectedRow.push(element.subRows);
                });
            }
            let isReassignment: boolean[] = [];
            let favoriteSelectedThirdParty: any = [];
            let shopSelectedThirdParty: any = [];
            for (let element of data.selectedShopsRows) {
                if (selectedRows.length !== 0) {
                    selectedRows.forEach(() => {
                        favoriteSelectedThirdParty.push(
                            activeThirdParties.find(
                                (element: any) => element.status === true
                            )
                        );
                    });
                    if (favoriteSelectedThirdParty !== undefined) {
                        let shopFavoriteThirdParty: any =
                            element.subRows &&
                            element.subRows.find(
                                (el: any) => el.isFavorite === true
                            );

                        if (shopFavoriteThirdParty !== undefined) {
                            let shopFavoriteThirdParty: any = element.subRows.find(
                                (el: any) => el.isFavorite === true
                            );
                            shopFavoriteThirdParty.isFavorite = false;
                            if (
                                selectedRows.find(
                                    (el: any) =>
                                        el.Id === shopFavoriteThirdParty.Id
                                ) === undefined
                            ) {
                                selectedRows.push(shopFavoriteThirdParty);
                                shopSelectedThirdParty.push(
                                    shopFavoriteThirdParty
                                );
                            }
                        }
                    }
                    let indexInAssociated: number = -1;
                    selectedRows.forEach(async (el: any) => {
                        indexInAssociated =
                            element.subRows !== undefined &&
                            element.subRows.findIndex(
                                (item: any) => item.Id === el.Id
                            );
                        let isLocalAssociated: boolean =
                            (element.subRows !== undefined &&
                                element.subRows[indexInAssociated] !==
                                    undefined &&
                                element.subRows[indexInAssociated].isActive !==
                                    activeThirdParties.find((element: any) => {
                                        return element.Id === el.Id;
                                    }).state) ||
                            (element.subRows !== undefined &&
                                element.subRows[indexInAssociated] !==
                                    undefined &&
                                element.subRows[indexInAssociated]
                                    .isFavorite !==
                                    activeThirdParties.find((element: any) => {
                                        return element.Id === el.Id;
                                    }).isFavorite);

                        isReassignment.push(isLocalAssociated);
                        let thirdData = CreateDataThirdParty(
                            oneShop,
                            false,
                            element.shopId,
                            activeThirdParties.find(
                                (element: any) => element.Id === el.Id
                            ).state,
                            thirdPartiesFavored.find(
                                (element: any) => element.id === el.Id
                            ) !== undefined
                                ? thirdPartiesFavored.find(
                                      (element: any) => element.id === el.Id
                                  ).status
                                : el.isFavorite,
                            userID,
                            franchiseID,
                            operatorID,
                            uuidUser,
                            el.Id,
                            el[t("Third party")],
                            el.ip,
                            el.port,
                            el.salesId,
                            el.menusId,
                            el.baseUrl,
                            el.remark,
                            el.isArchived,
                            el.apiKey
                        );
                        let objectData: any =
                            shopSelectedThirdParty[0] !== undefined &&
                            shopSelectedThirdParty[0].Id === el.Id
                                ? thirdData
                                : {
                                      ...thirdData,
                                      affectation:
                                          isLocalAssociated !== true
                                              ? true
                                              : "reassignment",
                                  };

                        localData.push(objectData);
                    });
                }
            }
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(localData),
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            if (result.error) {
                                throw Error("error");
                            }
                            toast.success(
                                !isReassignment.includes(true)
                                    ? `${t("The assignment was successful")}`
                                    : `${t("The reassignment was successful")}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setKey(uuid());
                            setIsValidateClicked(false);
                            data.setIsAssociateModalOpened(
                                !data.isAssociateModalOpened
                            );
                            setFranchiseDataIsUpdated(true);
                            setShopDataIsUpdated(true);
                            setSelectedRows([]);
                            setIsValidateButtonDisabled(false);
                            setIsLoading(false);
                        })
                );
            } catch (e) {
                toast.error(`${t("There's an error")!}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setIsLoading(false);
            }
        }
    }
    function CountIds() {
        let number = 0;
        idsLocalShop.forEach((el: any) => {
            selectedRows.forEach((v: any) => {
                if (v.Id === el) {
                    number = number + 1;
                }
            });
        });
        setIds(number);
    }

    function checkIfThirdPartyExistAndArchived() {
        let archived: any[] = [];
        if (shopsSelectedRow.length > 0) {
            shopsSelectedRow.forEach((element: any) => {
                element?.archivedThirdPartys?.forEach((el: any) => {
                    archived.push(el.Id);
                });
            });
        }
        return archived;
    }

    React.useEffect(() => {
        let localSelectedThirdParty: any = [];
        data.selectedShopsRows.forEach((el: any) => {
            if (el.subRows !== undefined)
                el.subRows.forEach((element: any) => {
                    localSelectedThirdParty.push(element);
                });
        });
        setSelectedThirdParty(localSelectedThirdParty);
    }, [data.selectedShopsRows, selectedRows, activeThirdParties]);

    React.useEffect(() => {
        if (isValidateClicked === true) handleAssociateThirdPartyButtonClick();
        // eslint-disable-next-line
    }, [isValidateClicked]);

    return (
        <React.Fragment>
            <StyledModal
                toggle={true}
                isOpen={data.isAssociateModalOpened!}
                centered
                className="modal-wrapper__clz"
                backdrop="static"
                fade={false}
                scrollable
            >
                <ModalHeader
                    className="text-capitalize sdv_icn_AFclose"
                    toggle={() => {
                        data.setIsAssociateModalOpened(
                            !data.isAssociateModalOpened
                        );
                    }}
                >
                    <StyledH2 className="text-uppercase sdv_txt_AFlistOfThirdPartiess">
                        {t("List of third parties")}
                    </StyledH2>
                </ModalHeader>
                <ModalBody>
                    <DynamicTable
                        url={url}
                        requestHeader={{ "Accept-Language": i18nextLng }}
                        canSelect
                        customSelect
                        canResize
                        setSelectedRows={setSelectedRows}
                        actionColumn={ActionColumn}
                        arrayOfCustomColumns={arrayOfCustomColumns}
                        showGlobalFilter
                        canMovedCheckboxLeftOnExpand
                        setDataIsUpdated={
                            setShopDataIsUpdated as
                                | React.Dispatch<
                                      React.SetStateAction<number | boolean>
                                  >
                                | undefined
                        }
                        dataIsUpdated={shopDataIsUpdated}
                        elevationTable={1}
                        minHeight="500px"
                        maxHeight="500px"
                        name="associateModeOfSale"
                    />
                </ModalBody>
                <ModalFooter className="border-top-0">
                    <CustomSecondaryColorButton
                        outline
                        variant="light"
                        onClick={() => {
                            data.setIsAssociateModalOpened(
                                !data.isAssociateModalOpened
                            );
                        }}
                        rounded
                        className="sdv_btn_AFcancel"
                    >
                        {t("Cancel")}
                    </CustomSecondaryColorButton>
                    <CustomMainColorButton
                        rounded
                        variant="primary"
                        onClick={() => {
                            let archived = checkIfThirdPartyExistAndArchived();
                            if (selectedRows.length === 0) {
                                ErrorToast(
                                    `${t(
                                        "Attention! You must select at least one third party"
                                    )!}`
                                );
                            } else {
                                CountIds();
                                try {
                                    setSelectedRow(selectedRows);
                                    selectedRows.forEach((el: any) => {
                                        associatedThirdParty.forEach(
                                            (element: any) => {
                                                if (
                                                    activeThirdParties.find(
                                                        (elt: any) =>
                                                            elt.Id === el.Id
                                                    ) !== undefined &&
                                                    element.Id === el.Id &&
                                                    element.isActive !==
                                                        activeThirdParties.find(
                                                            (elt: any) =>
                                                                elt.Id === el.Id
                                                        ).state
                                                ) {
                                                    setIsModalOpened(
                                                        !isModalOpened
                                                    );
                                                    // eslint-disable-next-line
                                                    throw "Break";
                                                }
                                            }
                                        );
                                    });
                                    selectedRows.forEach((el: any) => {
                                        if (archived.includes(el.Id)) {
                                            setIsModalArchivedOpened(
                                                !isModalArchivedOpened
                                            );
                                            // eslint-disable-next-line
                                            throw "Break";
                                        }
                                    });
                                    handleAssociateThirdPartyButtonClick();
                                } catch (e) {
                                    if (e !== "Break") throw e;
                                }
                            }
                        }}
                        className="sdv_btn_AFvalidate"
                    >
                        {t("Validate")}
                    </CustomMainColorButton>
                </ModalFooter>
            </StyledModal>
            {isModalOpened ? (
                <ConfirmationMessage
                    data={{
                        msg: t(
                            'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                        ),
                        setIsModalOpened,
                        isModalOpened,
                        setIsValidateClicked,
                    }}
                />
            ) : null}
            {isModalArchivedOpened ? (
                <ConfirmationMessage
                    data={{
                        msg: t(
                            "Attention! One or more selected third parties are already assigned and archived at the store level.Would you unarchive them?"
                        ),
                        setIsModalOpened,
                        isModalOpened,
                        setIsValidateClicked,
                    }}
                />
            ) : null}
            <NProgressWrapper isLoading={isLoading} />
        </React.Fragment>
    );
}
