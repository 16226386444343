import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    CheckboxIcon,
    StyledLabel,
} from "@aureskonnect/react-ui";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useSnapshot } from "valtio";
import { uuid } from "uuidv4";

import { store } from "../../../../components/VerticalLayout/store";
import {
    setShopDataIsUpdated,
    setFranchiseDataIsUpdated,
    store as logoStore,
    setIsLoading,
    setKey,
} from "../store";

import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { CreateDataThirdParty } from "../helper";
import { ConfirmationDeleteMessageType } from "../Types";

export function ConfirmationActiveThirdParty({
    data,
}: ConfirmationDeleteMessageType) {
    const { t } = useTranslation();
    const { userID, franchiseID, operatorID, oneShop } = useSnapshot(store);
    const { shopsThirdPartyData } = useSnapshot(logoStore);
    const uuidUser = localStorage.getItem("uuidUser");
    function ArchivedThirdParty(e: any) {
        let idsAssociated = ["0"];
        if (shopsThirdPartyData !== undefined) {
            shopsThirdPartyData.forEach((element: any) => {
                element.subRows.forEach((el: any) => {
                    if (el.Id === e.Id) idsAssociated.push(el.shopId);
                });
            });
        }
        idsAssociated = [...new Set(idsAssociated)];

        return idsAssociated;
    }

    async function activeThirdParty(isValidation: boolean) {
        setIsLoading(true);
        toast.dismiss();
        let localData: any[] = [];
        Object.values(data.selectedRows).forEach(async (element: any) => {
            const id = element.Id;
            let shopsArchived = ArchivedThirdParty(element);
            if (isValidation) {
                shopsArchived.forEach((el: any) => {
                    let dataThirdParty = CreateDataThirdParty(
                        oneShop,
                        false,
                        el,
                        false,
                        false,
                        userID,
                        franchiseID,
                        operatorID,
                        uuidUser,
                        id,
                        element[t("Third party")],
                        element.ip,
                        element.port,
                        element.salesId,
                        element.menusId,
                        element.baseUrl,
                        element[t("Remark")],
                        false,
                        element.apiKey
                    );
                    localData.push(dataThirdParty);
                });
            } else {
                let dataThirdParty = CreateDataThirdParty(
                    oneShop,
                    false,
                    "0",
                    false,
                    false,
                    userID,
                    franchiseID,
                    operatorID,
                    uuidUser,
                    id,
                    element[t("Third party")],
                    element.ip,
                    element.port,
                    element.salesId,
                    element.menusId,
                    element.baseUrl,
                    element[t("Remark")],
                    false,
                    element.apiKey
                );
                localData.push(dataThirdParty);
            }
        });
        const apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty`;
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(localData),
        };
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.error) {
                            throw Error(result.message);
                        }

                        toast.success(
                            `${t(
                                "The third party has been successfully unarchived."
                            )!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setIsLoading(false);
                        setKey(uuid());
                        data.setDataIsUpdated!(true);
                        setShopDataIsUpdated(true);
                        setFranchiseDataIsUpdated(true);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal
            toggle={true}
            isOpen={data.isModalConfirmationOpened!}
            centered
        >
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    data.setIsModalConfirmationOpened(
                        !data.isModalConfirmationOpened
                    );
                    data.setDataIsUpdated!(true);
                }}
            >
                <StyledH2 className="text-uppercase">{t("Alert")}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel className="pl-3 m-0">
                    {t(
                        "Warning! The third party is archived at the store level. Do you want to activate it in the stores as well?"
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        activeThirdParty(false);
                        data.setIsModalConfirmationOpened(
                            !data.isModalConfirmationOpened
                        );
                        data.setDataIsUpdated!(true);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        activeThirdParty(true);
                        data.setIsModalConfirmationOpened(
                            !data.isModalConfirmationOpened
                        );
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
