import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledModal,
    StyledH2,
    StyledLabel,
    CheckboxIcon,
} from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import {
    store as thirdPartyStore,
    setFranchiseDataIsUpdated,
    setShopDataIsUpdated,
} from "../store";

import { capitalize } from "@helpers/general";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { CreateDataThirdParty } from "../helper";
import { ConfirmationFavoriteType } from "../Types";

export function ConfirmationFavorite({ data }: ConfirmationFavoriteType) {
    const { t } = useTranslation();
    const { franchiseID, userID, operatorID, oneShop } = useSnapshot(store);
    const { thirdPartyData, shopsThirdPartyData } = useSnapshot(
        thirdPartyStore
    );
    const uuidUser = localStorage.getItem("uuidUser");
    async function favoriteThirdPartyShop(isFavorite: boolean) {
        toast.dismiss();
        let dataSS: any = [];
        thirdPartyData.forEach((elt: any) => {
            shopsThirdPartyData.forEach((el: any) => {
                el.subRows.forEach((thirdParty: any) => {
                    if (data.selectedRowData.Id === thirdParty.Id) {
                        let thirdData = CreateDataThirdParty(
                            oneShop,
                            false,
                            thirdParty.shopId,
                            true,
                            true,
                            userID,
                            franchiseID,
                            operatorID,
                            uuidUser,
                            thirdParty.Id,
                            thirdParty[t("Third party")],
                            thirdParty.ip,
                            thirdParty.port,
                            thirdParty.salesId,
                            thirdParty.menusId,
                            thirdParty.baseUrl,
                            thirdParty[t("Remark")],
                            false,
                            thirdParty.apiKey
                        );

                        dataSS.push({ ...thirdData, isShop: false });
                    } else if (
                        elt.Id === thirdParty.Id &&
                        el.subRows.findIndex(
                            (elm: any) => elm.Id === data.selectedRowData.Id
                        ) !== -1
                    ) {
                        let thirdData = CreateDataThirdParty(
                            oneShop,
                            false,
                            thirdParty.shopId,
                            thirdParty.isActive,
                            false,
                            userID,
                            franchiseID,
                            operatorID,
                            uuidUser,
                            thirdParty.Id,
                            thirdParty[t("Third party")],
                            thirdParty.ip,
                            thirdParty.port,
                            thirdParty.salesId,
                            thirdParty.menusId,
                            thirdParty.baseUrl,
                            thirdParty[t("Remark")],
                            false,
                            thirdParty.apiKey
                        );
                        dataSS.push({ ...thirdData, isShop: false });
                    }
                });
            });
        });

        const apiUrlAdd = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty`;
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(dataSS),
        };
        try {
            mutate(
                apiUrlAdd,
                await fetch(apiUrlAdd, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.error) {
                            throw Error("error");
                        }
                        toast.success(
                            `${
                                isFavorite
                                    ? `${t(
                                          "Shop favorite state has been successfully activated"
                                      )}`
                                    : `${t(
                                          "Shop favorite state has been successfully deactivated"
                                      )}`
                            }`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                theme: "colored",
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        setFranchiseDataIsUpdated!(true);
                        setShopDataIsUpdated(true);
                    })
            );
        } catch (e) {
            toast.error(`${t("There's an error")!}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    return (
        <StyledModal toggle={true} isOpen={data.isModalOpened!} centered>
            <ModalHeader
                className="text-capitalize"
                toggle={() => {
                    data.setIsModalOpened(!data.isModalOpened);
                }}
            >
                <StyledH2>{capitalize(t("Alert"))}</StyledH2>
            </ModalHeader>
            <ModalBody>
                <StyledLabel
                    className="pl-3 m-0"
                    style={{ whiteSpace: "pre-line" }}
                >
                    {t(
                        'Do you want to apply these changes for all stores? \n Attention ! If you click on "Validate", the specific settings of the shops will be modified.'
                    )}
                </StyledLabel>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <CustomSecondaryColorButton
                    outline
                    variant="light"
                    onClick={() => {
                        data.setIsModalOpened(!data.isModalOpened);
                    }}
                    rounded
                >
                    {t("Cancel")}
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    className="mr-2"
                    variant="primary"
                    onClick={() => {
                        favoriteThirdPartyShop(
                            !data.selectedRowData.isFavorite
                        );
                        data.setIsModalOpened(!data.isModalOpened);
                    }}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </ModalFooter>
        </StyledModal>
    );
}
