import React from "react";
import { useTranslation } from "react-i18next";
import { CrossIcon } from "@aureskonnect/react-ui";
import { AvForm } from "availity-reactstrap-validation";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import {
    store as thirdPartyStore,
    setIsActionsClicked,
    setActions,
    setIsEdited,
    setThirdPartyData,
} from "../store";

import {
    CustomCardFooter,
    CustomCardHeader,
} from "@components/Common/CustomCardStyled";
import { CustomSecondaryColorButton } from "@components/Common/CustomSecondaryColorButton";
import { CustomMainColorButton } from "@components/Common/CustomMainColorButton";
import { ThirdPartyBody } from "./ThirdPartyBody";
import { ThirdPartyConfigType } from "../Types";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";

export default function ThirdPartyConfig({ data }: ThirdPartyConfigType) {
    const { t } = useTranslation();
    const { isActionsClicked, isEdited } = useSnapshot(thirdPartyStore);
    return (
        <AvForm className="card">
            <CustomCardHeader
                className="d-flex align-items-center justify-content-between"
                style={{ maxHeight: "76px" }}
            >
                <h5 className="pt-3 sdv_txt_FRtitleFormThirdParties">
                    {data.title}
                </h5>
                <CrossIcon
                    className="icon-close-notification__clz sdv_icn_FRcloseForm"
                    height={13}
                    width={13}
                    onClick={() => {
                        data.setIsConsult(false);
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsEdited(false);
                                setThirdPartyData([]);
                                data.setIsAddThirdPartyClicked(
                                    !data.isAddThirdPartyClicked
                                );
                                setIsEdited(false);
                            });
                        } else {
                            setThirdPartyData([]);
                            data.setIsAddThirdPartyClicked!(
                                !data.isAddThirdPartyClicked
                            );
                            setIsEdited(false);
                        }
                    }}
                />
            </CustomCardHeader>
            <ThirdPartyBody data={{ ...data, isConfig: false }} />
            <CustomCardFooter className="pt-1 pb-2">
                <CustomSecondaryColorButton
                    outline
                    rounded
                    className="mr-2 sdv_btn_FRcancel cmn_btn_cancel"
                    variant="light"
                    onClick={() => {
                        if (isEdited === true) {
                            setIsActionsClicked(!isActionsClicked);
                            setActions(() => {
                                setIsEdited(false);
                                setThirdPartyData([]);
                                data.setIsAddThirdPartyClicked(
                                    !data.isAddThirdPartyClicked
                                );
                            });
                        } else {
                            setThirdPartyData([]);
                            data.setIsAddThirdPartyClicked(
                                !data.isAddThirdPartyClicked
                            );
                        }
                        data.setIsConsult(false);
                    }}
                >
                    <span> {t("Cancel")}</span>
                </CustomSecondaryColorButton>
                <CustomMainColorButton
                    rounded
                    variant="primary"
                    disabled={data.isValidateButtonDisabled}
                    onClick={() => {
                        data.handleValidateButtonOnClickEvent();
                    }}
                    className={`sdv_btn_FRvalidate cmn_btn_validate ${classnames(
                        {
                            "not-allowed-input-vr__clz": data.isConsult,
                        }
                    )}`}
                >
                    {t("Validate")}
                </CustomMainColorButton>
            </CustomCardFooter>
        </AvForm>
    );
}
