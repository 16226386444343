import i18n from "i18next";
import { format, fromUnixTime, subYears } from "date-fns";
import moment from "moment";
import { extendMoment } from "moment-range";

import crypto from "crypto";
import { detailedDiff } from "deep-object-diff";
import {
    DAYS_IN_ONE_YEAR,
    EMAIL_VALIDATION_REGEX,
    HAS_NO_LOYALTY,
    POS_EDITOR_CESAR,
} from "@constants/index";

import frTranslation from "@locales/fr/translation.json";
import {
    setFidelityType,
    setPosEditorType,
    setResourceIp,
} from "@components/VerticalLayout/store";
import { mutate } from "swr";
import { setProject } from "@store";
import { set } from "lodash";

const { range } = extendMoment(moment as any);
export function getUppercasedFullnameFirstLetters(aString: string): string {
    let nameAsArray = aString.split(" ");

    if (nameAsArray.length === 2) {
        return `${nameAsArray[0].charAt(0)}${nameAsArray[1].charAt(
            0
        )}`.toUpperCase();
    } else {
        return `${nameAsArray[0].charAt(0)}`.toUpperCase();
    }
}
export function getTypePrinter(str: string) {
    let res: string = "";
    switch (str) {
        case "ip":
            res = "IP";
            break;
        case "series":
            res = "Série";
            break;
        case "usb":
            res = "USB";
            break;
    }
    return res;
}
export function searchPosInArrayObject(array: any, column: string, req: any) {
    let pos = -1;
    if (array.length > 0 && array !== undefined) {
        array.forEach((el: any, i: number) => {
            if (
                (el[column] && i18n.t(el[column]) === req) ||
                (el.frDesignation && el.frDesignation === req)
            ) {
                pos = i;
            }
        });
    }

    return pos;
}
export async function UpdateResourceIp(userId: number, franchiseId: string) {
    let apiUrl = `${process.env.REACT_APP_API_V2_URL}/settings/general/ip_resources?userId=${userId}&franchiseId=${franchiseId}`;
    try {
        mutate(
            apiUrl,
            await fetch(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    authorization: `Bareer ${localStorage.getItem("jwt")}`,
                },
                method: "GET",
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        throw Error("Error while delete selection!");
                    }
                    setResourceIp(data.data);
                })
        );
    } catch (error) { }
    // eslint-disable-next-line
}
export function checkIpIfExist(
    allData: any,
    ip: string,
    idPer: string,
    shopId: string,
    ty?: string
) {
    let check: boolean = true;
    try {
        let fetchedList: any = [];
        if (ty !== undefined && ty === "printer") {
            fetchedList = allData
                // eslint-disable-next-line array-callback-return
                .filter((x: any) => {
                    if (
                        (x.shopId === shopId &&
                            idPer.length === 0 &&
                            x.type !== "printer") ||
                        (x.shopId === shopId &&
                            idPer.length > 0 &&
                            x.id !== idPer &&
                            x.type !== "printer")
                    ) {
                        return x.ip;
                    }
                })
                .map((x: any) => {
                    return x.ip;
                });
        } else {
            fetchedList = allData
                // eslint-disable-next-line array-callback-return
                .filter((x: any) => {
                    if (
                        (x.shopId === shopId && idPer.length === 0) ||
                        (x.shopId === shopId &&
                            idPer.length > 0 &&
                            (x.id ?? x.Id !== idPer))
                    ) {
                        return x.ip;
                    }
                })
                .map((x: any) => {
                    return x.ip;
                });
        }

        if (fetchedList.indexOf(ip) === -1) {
            check = false;
        }

        return check;
    } catch (error) { }
}
export function checkIfHasFavorite(array: any, shopId: string) {
    return (
        array?.filter((x: any) => x.shopId === shopId && x.isFavorite === true)
            ?.length === 0 || false
    );
}
export function getSimpleArrayFromObjectArray(array: any, column: string) {
    let res: any = [];
    if (array.length > 0) {
        array.forEach((el: any) => {
            res.push(el[column]);
        });
    }

    return res;
}
export function getRandomInt(max: number): number {
    return Math.floor(Math.random() * Math.floor(max));
}

export function getArrayOfRandomNumbers(arraySize: number): number[] {
    return Array.from(Array(arraySize)).map(function () {
        return Math.floor((Math.random() * 100) % 100);
    });
}

export function getNumbersArrayUniqueValues(
    arrayOfNumber: number[]
): Array<number> {
    return arrayOfNumber.filter(function (item, pos) {
        return arrayOfNumber.indexOf(item) === pos;
    });
}

export function getStringsArrayUniqueValues(
    arrayOfStrings: string[]
): Array<string> {
    return arrayOfStrings.filter(function (item, pos) {
        return arrayOfStrings.indexOf(item) === pos;
    });
}

// @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl
// @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
export function formatCurrency(
    currencyData: number,
    minimumFractionDigits: number = 2,
    currency: string = "EUR",
    locale: string = "fr-FR"
): string {
    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits,
    }).format(currencyData);
}

export function getRoundedArrayNumbers(data: number[]): number[] {
    return data?.map((info: number) => parseFloat(info.toFixed(2)));
}

export function getRandomValueFromArray(array: any[]): any {
    return array[Math.floor(Math.random() * array.length)];
}

export function getAdvancedPeriodStringParam(
    period: string,
    advancedDate: { startDate: string; endDate: string }
): string {
    if (advancedDate.startDate !== "" && advancedDate.endDate !== "") {
        return `start_date=${advancedDate.startDate}&end_date=${advancedDate.endDate}`;
    }

    if (period === i18n.t("Current week")) {
        return "week=1";
    }
    if (period === i18n.t("Current month")) {
        return "month=1";
    }
    if (period === i18n.t("Current year")) {
        return "year=1";
    }
    if (period === i18n.t("Yesterday")) {
        return "yesterday=1";
    }
    if (period === i18n.t("The last 7 days")) {
        return "last_seven_days=1";
    }
    if (period === i18n.t("The last 14 days")) {
        return "last_fourteen_days=1";
    }
    if (period === i18n.t("The last 30 days")) {
        return "last_thirty_days=1";
    }
    if (period === i18n.t("The last 90 days")) {
        return "last_ninety_days=1";
    }
    if (period === i18n.t("The last 180 days")) {
        return "last_180_days=1";
    }
    if (period === i18n.t("The last 365 days")) {
        return "last_365_days=1";
    }
    if (period === i18n.t("Today")) {
        return "day=1";
    }
    return "week=1";
}

export function getPeriodStringParam(period: string): string {
    if (period === i18n.t("Current week")) {
        return "week=1";
    }
    if (period === i18n.t("Current month")) {
        return "month=1";
    }
    if (period === i18n.t("Current year")) {
        return "year=1";
    }
    if (period === i18n.t("Today")) {
        return "day=1";
    }
    return "week=1";
}

export function getTypeParam(type: string): string {
    if (
        [
            i18n.t("Turnover"),
            i18n.t("Turnover incl-tax"),
            i18n.t("Incl-tax"),
        ].includes(type)
    ) {
        return "turnover";
    }
    if ([i18n.t("Turnover ex-tax"), i18n.t("Ex-tax")].includes(type)) {
        return "turnover_ex_tax";
    }
    if (type === i18n.t("Tickets")) {
        return "tickets";
    }
    if (type === i18n.t("Average basket")) {
        return "average_basket";
    }
    if (type === i18n.t("Average turnover")) {
        return "average_turnover";
    }

    if ([i18n.t("Quantity sold"), i18n.t("Item number")].includes(type)) {
        return "quantity-sold";
    }

    if (type === i18n.t("Quantity")) {
        return "quantity";
    }

    if (type === i18n.t("Duration")) {
        return "duration";
    }

    return "turnover";
}

// @see https://flaviocopes.com/how-to-uppercase-first-letter-javascript/
export function capitalize(s: string) {
    return s?.charAt(0).toUpperCase() + s?.slice(1);
}

export function getSelection(
    currentSelections: any[] | undefined,
    selection: string
): SelectionType {
    let obj: SelectionType = {
        designation: selection,
        selection: [],
    };

    let franchises: string[] = [];
    let shops: string[] = [];

    currentSelections?.forEach(function (selection: string) {
        let selectionSplit = selection.split("|");
        franchises.push(selectionSplit[0]);
        shops.push(selection);
    });

    Array.from(new Set(franchises)).forEach(function (franchise: string) {
        let currentSelection: any = {
            franchise_id: franchise,
            shops_id: [],
        };

        shops.forEach(function (shop: string) {
            if (shop.split("|")[0] === franchise) {
                let shop_id = shop.split("|")[1];
                currentSelection.shops_id.push(shop_id);
            }
        });

        obj.selection.push(currentSelection);
    });

    return obj;
}

export function getFavoritesObj(
    currentFavorites: any[] | undefined
): SelectionType {
    let obj: SelectionType = {
        designation: "my_favorites",
        selection: [],
    };

    let franchises: string[] = [];
    let shopsWithFranchises: string[] = [];

    currentFavorites?.forEach(function (fav: any) {
        franchises.push(fav.franchise_id);
        shopsWithFranchises.push(fav);
    });

    Array.from(new Set(franchises)).forEach(function (franchise: string) {
        let currentFavorite: any = {
            franchise_id: franchise,
            shops_id: [],
        };

        shopsWithFranchises.forEach(function (shop: any) {
            if (shop.franchise_id === franchise) {
                currentFavorite.shops_id.push(shop.shop_id);
            }
        });

        obj.selection.push(currentFavorite);
    });

    return obj;
}

export function getColorsArray(arraySize: number): string[] {
    return [
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#C0C0C0",
        "#C87389",
        "#FCE517",
        "#FF56F9",
        "#ACFF98",
    ].slice(0, arraySize);
}

export function getPhysicalRegulations(data: SeriesDataType[]) {
    return data?.filter((regulation: any) => {
        return [
            i18n.t("Cash box"),
            i18n.t("Coin acceptor"),
            i18n.t("Restaurant coupon"),
            i18n.t("Check"),
        ].includes(i18n.t(regulation.designation));
    });
}

export function getNumericRegulations(data: SeriesDataType[]) {
    return data?.filter((regulation: any) => {
        return [
            i18n.t("Bank card"),
            i18n.t("Softpay"),
            i18n.t("Fidelity"),
        ].includes(i18n.t(regulation.designation));
    });
}

export function getShopSalesSupport(data: SeriesDataType[]) {
    return data?.filter((shop: any) => {
        return [i18n.t("Box"), i18n.t("Thick headed")].includes(shop.name);
    });
}

export function getClickCollectSalesSupport(data: SeriesDataType[]) {
    return data?.filter((clickCollect: any) => {
        return [
            i18n.t("Web"),
            i18n.t("Web mobile"),
            i18n.t("Application"),
            i18n.t("Tablette"),
        ].includes(clickCollect.name);
    });
}

export function getPlatformSalesSupport(data: SeriesDataType[]) {
    return data?.filter((platform: any) => {
        return [i18n.t("UBER"), i18n.t("Tablette")].includes(platform.name);
    });
}

function getComparedPeriodToLastXDays(lastXDays: number): string {
    let array: number[] = [];
    for (let i = 0; i < lastXDays; i++) {
        array.push(i);
    }
    let comparedXdaysArray = array
        .map((day: number) =>
            Math.trunc(
                new Date(
                    new Date(
                        moment()
                            .subtract(lastXDays + 1, "days")
                            .format("Y/MM/DD")
                    ).getTime() -
                    86400000 * day
                ).getTime() / 1000
            )
        )
        .sort();
    return `${moment
        .unix(comparedXdaysArray[0])
        .format("DD/MM/YYYY")} - ${moment
            .unix(comparedXdaysArray[lastXDays - 1])
            .format("DD/MM/YYYY")}`;
}

export function generateComparedToStringFromAdvancedPeriod(
    period: string,
    advancedDate: { startDate: string; endDate: string }
): string {
    if (advancedDate.startDate !== "" && advancedDate.endDate !== "") {
        return `${format(
            subYears(fromUnixTime(Number(advancedDate.startDate)), 1),
            "dd/MM/yyyy"
        )} - ${format(
            subYears(fromUnixTime(Number(advancedDate.endDate)), 1),
            "dd/MM/yyyy"
        )}`;
    } else if (period === i18n.t("Days")) {
        return i18n.t("yesterday");
    } else if (period === i18n.t("Current week")) {
        return i18n.t("last week");
    } else if (period === i18n.t("Current year")) {
        return i18n.t("last year");
    } else if (period === i18n.t("Current month")) {
        return i18n.t("last month");
    } else if (period === i18n.t("Yesterday")) {
        return i18n.t("The day before yesterday");
    } else if (period === i18n.t("The last 7 days")) {
        return getComparedPeriodToLastXDays(7);
    } else if (period === i18n.t("The last 14 days")) {
        return getComparedPeriodToLastXDays(14);
    } else if (period === i18n.t("The last 30 days")) {
        return getComparedPeriodToLastXDays(30);
    } else if (period === i18n.t("The last 90 days")) {
        return getComparedPeriodToLastXDays(90);
    } else if (period === i18n.t("The last 180 days")) {
        return getComparedPeriodToLastXDays(180);
    } else if (period === i18n.t("The last 365 days")) {
        return getComparedPeriodToLastXDays(365);
    }
    return i18n.t("yesterday");
}

export function getSalesModesQueryString(salesModes: string[]): string {
    try {
        if (salesModes) {
            return encodeURI(
                salesModes
                    ?.filter((element: string) => {
                        return ![
                            "All sales methods",
                            "Tous les modes de vente",
                        ].includes(i18n.t(capitalize(element)));
                    })
                    .join(",")
            );
        }
        return "";
    } catch (error: any) {
        console.log(`There's an error: ${error.message}`);
        return "";
    }
}

export function getSalesSupportQueryString(salesSupports: string[]): string {
    try {
        if (salesSupports) {
            return encodeURI(
                salesSupports
                    ?.filter((element: string) => {
                        return (
                            i18n.t(capitalize(element)) !==
                            i18n.t("All sales supports") &&
                            i18n.t(capitalize(element)) !==
                            i18n.t("Click_&_collect") &&
                            i18n.t(capitalize(element)) !==
                            "Tous les supports de vente" &&
                            i18n.t(capitalize(element)) !== "Click & collect"
                        );
                    })
                    .map((el: string) => el?.toUpperCase())
                    .join(",")
            );
        }
        return "";
    } catch (error: any) {
        console.log(`There's an error: ${error.message}`);
        return "";
    }
}

export function getRegulationModesQueryString(
    regulationModes: string[]
): string {
    try {
        return encodeURI(
            regulationModes
                .filter((element: string) => {
                    return ![
                        i18n.t("Digital Payment Method"),
                        i18n.t("Physical Payment Method"),
                        i18n.t("All regulation methods"),
                    ].includes(i18n.t(capitalize(element)));
                })
                .map(getUntranslatedString)
                .join(",")
        );
    } catch (error: any) {
        console.log(`There's an error: ${error.message}`);
        return "";
    }
}

export function getUntranslatedString(string: string) {
    let titles = [
        "All sales methods",
        "All sales supports",
        "delivery",
        "to take",
        "on the spot",
        "seating plan",
        "Box",
        "Thick headed",
        "Uber eats",
        "Web",
        "Application",
        "DELIVERO",
        "JUSTEAT",
        "WEBEXTERNE",
        "UBER",
        "Midday service",
        "Evening service",
        "Coin operated species",
        "Restaurant tickets",
        "Credit note (-)",
        "Credit note (+)",
        "Cash box",
        "Bank card",
        "Check",
        "Middleware",
        "drive",
    ];

    let index: number = titles.findIndex(
        (el: string) => i18n.t(capitalize(el)) === string
    );

    return titles[index];
}

export function getTranslatedDesignationString(designation: string): string {
    return [
        "All sales methods",
        "All sales supports",
        "delivery",
        "to take",
        "on the spot",
        "seating plan",
        "Box",
        "Thick headed",
        "Uber eats",
        "Web",
        "Application",
        "DELIVERO",
        "JUSTEAT",
        "WEBEXTERNE",
        "UBER",
        "Midday service",
        "Evening service",
        "Coin operated species",
        "Restaurant tickets",
        "Credit note (-)",
        "Credit note (+)",
        "Cash box",
        "Bank card",
        "Check",
        "drive",
    ].filter(
        (el: string) =>
            el.toLocaleLowerCase() === designation.toLocaleLowerCase()
    ).length > 0
        ? i18n.t(capitalize(designation.toLowerCase()))
        : designation;
}

export function getFamiliesFilterQueryString(filter: string[]): string {
    return encodeURI(
        filter
            .filter((element: string) => {
                return ![
                    i18n.t("All sales methods"),
                    i18n.t("All sales supports"),
                    i18n.t("All services"),
                ].includes(i18n.t(element));
            })
            .map(getUntranslatedString)
            .join(",")
    );
}

// https://momentjs.com/docs/#/get-set/iso-weekday/
// We pretend that the first day of week is allways a `monday`
function getWeekFirstDayDate(d: any) {
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
}

function getWeekDaysInTimestamp() {
    let days: Date[] = [];
    let day = getWeekFirstDayDate(new Date());
    for (let i = 0; i <= 6; i++) {
        let nextDay = new Date(new Date(day).setDate(day.getDate() + i));
        days.push(nextDay);
    }

    return days
        .map((datum: Date) => {
            return Math.trunc(datum.getTime() / 1000);
        })
        .map(String);
}

function getAdvancedDays(startDate: string, dayNumber: number): string[] {
    let days: string[] = [];

    for (let i = 0; i <= dayNumber; i++) {
        let nextDay = capitalize(
            moment
                .unix(Number(startDate))
                .add("days", i)
                .format("ddd DD-MM-YYYY")
        );
        days.push(nextDay);
    }

    return days;
}

function getMonthsOfYear(date: Date) {
    let year = date.getFullYear();
    return moment.months().map((month: string, index: number) => {
        return capitalize(moment.utc([year, index]).format("MMM YYYY"));
    });
}

function getAdvancedPeriodCategories(advancedDate: {
    startDate: string;
    endDate: string;
}) {
    let startYear = Number(
        moment.unix(Number(advancedDate.startDate)).format("YYYY")
    );
    let endYear = Number(
        moment.unix(Number(advancedDate.endDate)).format("YYYY")
    );

    let daysBetweenDates = moment
        .unix(Number(advancedDate.endDate))
        .diff(moment.unix(Number(advancedDate.startDate)), "days");

    if (startYear !== endYear) {
        if (endYear - startYear > 1) {
            let months = [];
            for (let i = startYear; i <= endYear; i++) {
                months.push(String(i));
            }
            return months;
        } else if (daysBetweenDates > DAYS_IN_ONE_YEAR) {
            let years = [];
            for (let i = startYear; i <= endYear; i++) {
                years.push(String(i));
            }
            return years;
        } else {
            let firstYearMonths = moment
                .monthsShort()
                .slice(
                    Number(
                        moment.unix(Number(advancedDate.startDate)).format("M")
                    ) - 1,
                    12
                )
                .map((month) =>
                    capitalize(
                        `${month} ${moment
                            .unix(Number(advancedDate.startDate))
                            .format("YYYY")}`
                    )
                );

            let secondYearMonths = moment
                .monthsShort()
                .slice(
                    0,
                    Number(
                        moment.unix(Number(advancedDate.endDate)).format("M")
                    )
                )
                .map((month) =>
                    capitalize(
                        `${month} ${moment
                            .unix(Number(advancedDate.endDate))
                            .format("YYYY")}`
                    )
                );

            return [...firstYearMonths, ...secondYearMonths];
        }
    }

    return moment
        .monthsShort()
        .slice(
            Number(moment.unix(Number(advancedDate.startDate)).format("M")) - 1,
            Number(moment.unix(Number(advancedDate.endDate)).format("M"))
        )
        .map((month) =>
            capitalize(
                `${month} ${moment
                    .unix(Number(advancedDate.startDate))
                    .format("YYYY")}`
            )
        );
}

export function getLastXDaysInTimestamp(lastXDays: number): string[] {
    let array: number[] = [];
    for (let i = 0; i < lastXDays; i++) {
        array.push(i);
    }
    return array
        .map((day: number) => {
            return Math.trunc(
                moment(
                    new Date(
                        moment().subtract(1, "days").unix() * 1000
                    ).getTime() -
                    86400000 * day
                ).unix()
            );
        })
        .sort()
        .map(String);
}

function getLastXMonths(lastXDays: number): string[] {
    let startDate =
        new Date(
            moment().subtract(lastXDays, "days").format("Y/MM/DD")
        ).getTime() / 1000;
    let endDate =
        new Date(moment().subtract(1, "days").format("Y/MM/DD")).getTime() /
        1000;

    let startYear = Number(moment.unix(Number(startDate)).format("YYYY"));
    let endYear = Number(moment.unix(Number(endDate)).format("YYYY"));

    if (startYear !== endYear) {
        let firstYearMonths = moment
            .monthsShort()
            .slice(Number(moment.unix(Number(startDate)).format("M")) - 1, 12)
            .map((month) =>
                capitalize(
                    `${month} ${moment.unix(Number(startDate)).format("YYYY")}`
                )
            );

        let secondYearMonths = moment
            .monthsShort()
            .slice(0, Number(moment.unix(Number(endDate)).format("M")))
            .map((month) =>
                capitalize(
                    `${month} ${moment.unix(Number(endDate)).format("YYYY")}`
                )
            );

        return [...firstYearMonths, ...secondYearMonths];
    }

    return moment
        .monthsShort()
        .slice(
            Number(moment.unix(Number(startDate)).format("M")) - 1,
            Number(moment.unix(Number(endDate)).format("M"))
        )
        .map((month) =>
            capitalize(
                `${month} ${moment.unix(Number(startDate)).format("YYYY")}`
            )
        );
}

function getDaysInCurrentMonth() {
    return Array.from(Array(moment().daysInMonth()), (_, i) => String(i + 1));
}

export function getFormattedCategoriesByPeriod(
    period: string,
    advancedDate: {
        startDate: string;
        endDate: string;
    }
) {
    if (advancedDate.startDate !== "" && advancedDate.endDate !== "") {
        let daysBetweenDates = moment
            .unix(Number(advancedDate.endDate))
            .diff(moment.unix(Number(advancedDate.startDate)), "days");

        if (daysBetweenDates === 0) {
            return [
                "06",
                "07",
                "08",
                "09",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "00",
                "01",
                "02",
                "03",
                "04",
                "05",
            ];
        } else if (daysBetweenDates <= 31) {
            return getAdvancedDays(advancedDate.startDate, daysBetweenDates);
        } else {
            return getAdvancedPeriodCategories(advancedDate);
        }
    } else if ([i18n.t("Today"), i18n.t("Yesterday")].includes(period)) {
        return [
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "00",
            "01",
            "02",
            "03",
            "04",
            "05",
        ];
    } else if (period === i18n.t("Current week")) {
        return getWeekDaysInTimestamp();
    } else if (period === i18n.t("Current month")) {
        return getDaysInCurrentMonth();
    } else if (period === i18n.t("Current year")) {
        return getMonthsOfYear(new Date());
    } else if (period === i18n.t("The last 7 days")) {
        return getLastXDaysInTimestamp(7);
    } else if (period === i18n.t("The last 14 days")) {
        return getLastXDaysInTimestamp(14);
    } else if (period === i18n.t("The last 30 days")) {
        return getLastXDaysInTimestamp(30);
    } else if (period === i18n.t("The last 90 days")) {
        return getLastXMonths(90);
    } else if (period === i18n.t("The last 180 days")) {
        return getLastXMonths(180);
    } else if (period === i18n.t("The last 365 days")) {
        return getLastXMonths(365);
    } else {
        return [];
    }
}

export function getStatisticsData(
    data: any[],
    categories: string[],
    period: string,
    advancedDate: {
        startDate: string;
        endDate: string;
    }
): ReturnedArrayType {
    let array: ReturnedArrayType = [];

    let shopsNumber = data.length;

    data?.forEach((item: any) => {
        let name: string = "";
        let localData: number[] = [];

        categories.forEach((category: any, index: number) => {
            if (shopsNumber === 1) {
                let designation = item.designation.split("|");

                name = getTranslatedDesignationString(designation[0]);
            } else {
                name = getTranslatedDesignationString(item.designation);
            }

            item.value?.forEach((datum: any) => {
                let localDesignation = String(datum.designation);
                let localCategory = category;

                if (
                    advancedDate.startDate !== "" &&
                    advancedDate.endDate !== ""
                ) {
                    let daysBetweenDates = moment
                        .unix(Number(advancedDate.endDate))
                        .diff(
                            moment.unix(Number(advancedDate.startDate)),
                            "days"
                        );
                    if (daysBetweenDates === 0) {
                        localDesignation = String(datum.designation);
                        localCategory = category;
                    } else if (daysBetweenDates <= 31) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("ddd DD-MM-YYYY")
                        );
                    } else {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("MMM YYYY")
                        );
                    }
                } else if (
                    [
                        i18n.t("Current week"),
                        i18n.t("The last 7 days"),
                        i18n.t("The last 14 days"),
                        i18n.t("The last 30 days"),
                    ].includes(period)
                ) {
                    localDesignation = capitalize(
                        moment
                            .unix(Number(datum.designation))
                            .format("ddd DD-MM-YYYY")
                    );
                    localCategory = capitalize(
                        moment.unix(Number(category)).format("ddd DD-MM-YYYY")
                    );
                } else if (period === i18n.t("Current month")) {
                    localDesignation = capitalize(
                        moment.unix(Number(datum.designation)).format("D")
                    );
                } else if (period === i18n.t("Current year")) {
                    localDesignation = capitalize(
                        moment
                            .unix(Number(datum.designation))
                            .format("MMM YYYY")
                    );
                } else if (
                    [
                        i18n.t("The last 90 days"),
                        i18n.t("The last 180 days"),
                        i18n.t("The last 365 days"),
                    ].includes(period)
                ) {
                    localDesignation = capitalize(
                        moment
                            .unix(Number(datum.designation))
                            .format("MMM YYYY")
                    );
                }
                if (
                    localDesignation === localCategory ||
                    localDesignation.split(" ").includes(localCategory)
                ) {
                    localData.push(datum.value);
                }
            });

            if (!localData[index]) {
                localData[index] = 0;
            }
        });

        array.push({
            name,
            data: localData,
        });
    });

    return array;
}

export function getProductsData(
    data: any[],
    categories: string[],
    period: string,
    advancedDate: {
        startDate: string;
        endDate: string;
    }
): ReturnedArrayType {
    let products: any[] = [];

    data?.forEach((item: any) => {
        let localProducts: any[] = [];

        categories.forEach((category: any, index: number) => {
            item.value?.forEach((datum: any) => {
                let localDesignation = String(datum.designation);
                let localCategory = category;

                if (
                    advancedDate.startDate !== "" &&
                    advancedDate.endDate !== ""
                ) {
                    let daysBetweenDates = moment
                        .unix(Number(advancedDate.endDate))
                        .diff(
                            moment.unix(Number(advancedDate.startDate)),
                            "days"
                        );
                    if (daysBetweenDates === 0) {
                        localDesignation = String(datum.designation);
                        localCategory = category;
                    } else if (daysBetweenDates <= 31) {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("ddd DD-MM-YYYY")
                        );
                    } else {
                        localDesignation = capitalize(
                            moment
                                .unix(Number(datum.designation))
                                .format("MMM YYYY")
                        );
                    }
                } else if (
                    [
                        i18n.t("Current week"),
                        i18n.t("The last 7 days"),
                        i18n.t("The last 14 days"),
                        i18n.t("The last 30 days"),
                    ].includes(period)
                ) {
                    localDesignation = capitalize(
                        moment
                            .unix(Number(datum.designation))
                            .format("ddd DD-MM-YYYY")
                    );
                    localCategory = capitalize(
                        moment.unix(Number(category)).format("ddd DD-MM-YYYY")
                    );
                } else if (period === i18n.t("Current month")) {
                    localDesignation = capitalize(
                        moment.unix(Number(datum.designation)).format("D")
                    );
                } else if (period === i18n.t("Current year")) {
                    localDesignation = capitalize(
                        moment
                            .unix(Number(datum.designation))
                            .format("MMM YYYY")
                    );
                } else if (
                    [
                        i18n.t("The last 90 days"),
                        i18n.t("The last 180 days"),
                        i18n.t("The last 365 days"),
                    ].includes(period)
                ) {
                    localDesignation = capitalize(
                        moment
                            .unix(Number(datum.designation))
                            .format("MMM YYYY")
                    );
                }
                if (
                    localDesignation === localCategory ||
                    localDesignation.split(" ").includes(localCategory)
                ) {
                    localProducts.push(datum.products);
                }
            });

            if (!localProducts[index]) {
                localProducts[index] = [];
            }
        });

        localProducts.forEach((products: any) => {
            products.map((product: any) => {
                return getTranslatedDesignationString(product.designation);
            });
        });
        products.push(localProducts);
    });

    return products;
}

export function getStatisticsLineChartData(
    data: any[],
    categories: string[],
    period: string,
    advancedDate: {
        startDate: string;
        endDate: string;
    }
): number[] {
    let localData: number[] = [];

    categories.forEach((category: any, index: number) => {
        data?.forEach((item: any) => {
            let localCategory = category;
            let localDesignation = item.designation;

            if (advancedDate.startDate !== "" && advancedDate.endDate !== "") {
                let daysBetweenDates = moment
                    .unix(Number(advancedDate.endDate))
                    .diff(moment.unix(Number(advancedDate.startDate)), "days");

                if (daysBetweenDates === 0) {
                    localDesignation = item.designation;
                    localCategory = category;
                } else if (daysBetweenDates <= 31) {
                    localDesignation = capitalize(
                        moment
                            .unix(Number(item.designation))
                            .format("ddd DD-MM-YYYY")
                    );
                } else {
                    localDesignation = capitalize(
                        moment.unix(Number(item.designation)).format("MMM YYYY")
                    );
                }
            } else if (
                [
                    i18n.t("Current week"),
                    i18n.t("The last 7 days"),
                    i18n.t("The last 14 days"),
                    i18n.t("The last 30 days"),
                ].includes(period)
            ) {
                localDesignation = capitalize(
                    moment
                        .unix(Number(item.designation))
                        .format("ddd DD-MM-YYYY")
                );
                localCategory = capitalize(
                    moment.unix(Number(category)).format("ddd DD-MM-YYYY")
                );
            } else if (period === i18n.t("Current month")) {
                localDesignation = capitalize(
                    moment.unix(Number(item.designation)).format("D")
                );
            } else if (period === i18n.t("Current year")) {
                localDesignation = capitalize(
                    moment.unix(Number(item.designation)).format("MMM YYYY")
                );
            } else if (
                [
                    i18n.t("The last 90 days"),
                    i18n.t("The last 180 days"),
                    i18n.t("The last 365 days"),
                ].includes(period)
            ) {
                localDesignation = capitalize(
                    moment.unix(Number(item.designation)).format("MMM YYYY")
                );
            }

            if (
                localDesignation === localCategory ||
                localDesignation.split(" ").includes(localCategory)
            ) {
                localData.push(item.value);
            }
        });

        if (!localData[index]) {
            localData[index] = 0;
        }
    });
    return localData;
}

// @see https://www.logisticinfotech.com/blog/find-weeks-of-month-with-javascript-moment-and-moment-range/
export function getWeeksOfMonth(date: Date) {
    let year = date.getFullYear();
    let month = date.getMonth();
    let startDate = moment.utc([year, month]);

    // Get the first and last day of the month
    let firstDay = moment(startDate).startOf("month");
    let endDay = moment(startDate).endOf("month");

    // Create a range for the month we can iterate through
    let monthRange = range(firstDay, endDay);

    // Get all the weeks during the current month
    let weeks = [];

    for (let mday of monthRange.by("days")) {
        if (weeks.indexOf(mday.week()) === -1) {
            weeks.push(mday.week());
        }
    }

    // Create a range for each week
    let calendar = [];

    for (let index = 0; index < weeks.length; index++) {
        let weekNumber = weeks[index];

        let firstWeekDay = moment()
            .year(year)
            .month(month)
            .week(weekNumber)
            .day(0);
        let lastWeekDay = moment()
            .year(year)
            .month(month)
            .week(weekNumber)
            .day(6);

        if (month === 11 && weeks.length - 1 === index) {
            firstWeekDay = moment()
                .year(year)
                .month(month)
                .week(weeks[index - 1])
                .day(0);
            firstWeekDay.add(7, "day");
            lastWeekDay = moment()
                .year(year)
                .month(month)
                .week(weeks[index - 1])
                .day(6);
            lastWeekDay.add(6, "day");
        }

        if (firstWeekDay.isBefore(firstDay)) {
            firstWeekDay = firstDay;
        }

        if (lastWeekDay.isAfter(endDay)) {
            lastWeekDay = endDay;
        }

        calendar.push({
            week: index + 1,
            firstWeekDay: firstWeekDay.format("DD-MM-YYYY"),
            lastWeekDay: lastWeekDay.format("DD-MM-YYYY"),
        });
    }

    return calendar;
}

export function getAdvancedWeeksOfMonth(advancedDate: {
    startDate: string;
    endDate: string;
}) {
    let startYear = (moment
        .unix(Number(advancedDate.startDate))
        .format("YYYY") as unknown) as number;
    let endYear = (moment
        .unix(Number(advancedDate.endDate))
        .format("YYYY") as unknown) as number;
    let startMonth =
        ((moment
            .unix(Number(advancedDate.startDate))
            .format("M") as unknown) as number) - 1;
    let endMonth =
        ((moment
            .unix(Number(advancedDate.endDate))
            .format("M") as unknown) as number) - 1;

    if (startMonth !== endMonth) {
        let startDateFirstMonth = moment.utc([startYear, startMonth]);
        let endDayFirstMonth = moment(startDateFirstMonth).endOf("month");
        let startDateSecondMonth = moment.utc([endYear, endMonth]);
        let firstMonthRange = range(
            moment.unix(Number(advancedDate.startDate)),
            endDayFirstMonth
        );
        let secondMonthRange = range(
            startDateSecondMonth,
            moment.unix(Number(advancedDate.endDate))
        );

        let firstMonthweeks = [];

        for (let mday of firstMonthRange.by("days")) {
            if (firstMonthweeks.indexOf(mday.week()) === -1) {
                firstMonthweeks.push(mday.week());
            }
        }

        let secondMonthweeks = [];

        for (let mday of secondMonthRange.by("days")) {
            if (secondMonthweeks.indexOf(mday.week()) === -1) {
                secondMonthweeks.push(mday.week());
            }
        }

        let firstMonthCalendar: any[] = [];

        for (let index = 0; index < firstMonthweeks.length; index++) {
            let weekNumber = firstMonthweeks[index];

            let firstWeekDay = moment()
                .year(startYear)
                .month(startMonth)
                .week(weekNumber)
                .day(0);

            let lastWeekDay = moment()
                .year(startYear)
                .month(startMonth)
                .week(weekNumber)
                .day(6);

            if (startMonth === 11 && firstMonthweeks.length - 1 === index) {
                firstWeekDay = moment()
                    .year(startYear)
                    .month(startMonth)
                    .week(firstMonthweeks[index - 1])
                    .day(0);
                firstWeekDay.add(7, "day");
                lastWeekDay = moment()
                    .year(startYear)
                    .month(startMonth)
                    .week(firstMonthweeks[index - 1])
                    .day(6);
                lastWeekDay.add(6, "day");
            }

            firstMonthCalendar.push({
                week: Math.ceil(firstWeekDay.date() / 7) + 1,
                firstWeekDay: firstWeekDay.format("DD-MM-YYYY"),
                lastWeekDay: lastWeekDay.format("DD-MM-YYYY"),
                month: startMonth,
            });
        }

        let secondMonthCalendar: any[] = [];

        for (let index = 0; index < secondMonthweeks.length; index++) {
            let weekNumber = secondMonthweeks[index];

            let firstWeekDay = moment()
                .year(endYear)
                .month(endMonth)
                .week(weekNumber)
                .day(0);

            let lastWeekDay = moment()
                .year(endYear)
                .month(endMonth)
                .week(weekNumber)
                .day(6);

            if (endMonth === 11 && secondMonthweeks.length - 1 === index) {
                firstWeekDay = moment()
                    .year(endYear)
                    .month(endMonth)
                    .week(secondMonthweeks[index - 1])
                    .day(0);
                firstWeekDay.add(7, "day");
                lastWeekDay = moment()
                    .year(endYear)
                    .month(endMonth)
                    .week(secondMonthweeks[index - 1])
                    .day(6);
                lastWeekDay.add(6, "day");
            }

            secondMonthCalendar.push({
                week: index + 1,
                firstWeekDay: firstWeekDay.format("DD-MM-YYYY"),
                lastWeekDay: lastWeekDay.format("DD-MM-YYYY"),
                month: endMonth,
            });
        }

        return [...firstMonthCalendar, ...secondMonthCalendar];
    }

    // Create a range for the month we can iterate through
    let monthRange = range(
        moment.unix(Number(advancedDate.startDate)),
        moment.unix(Number(advancedDate.endDate))
    );

    // Get all the weeks during the current month
    let weeks = [];

    for (let mday of monthRange.by("days")) {
        if (weeks.indexOf(mday.week()) === -1) {
            weeks.push(mday.week());
        }
    }

    // Create a range for each week
    let calendar: any[] = [];

    for (let index = 0; index < weeks.length; index++) {
        let weekNumber = weeks[index];

        let firstWeekDay = moment()
            .year(startYear)
            .month(startMonth)
            .week(weekNumber)
            .day(0);

        let lastWeekDay = moment()
            .year(startYear)
            .month(endMonth)
            .week(weekNumber)
            .day(6);

        if (startMonth === 11 && weeks.length - 1 === index) {
            firstWeekDay = moment()
                .year(startYear)
                .month(startMonth)
                .week(weeks[index - 1])
                .day(0);
            firstWeekDay.add(7, "day");
            lastWeekDay = moment()
                .year(startYear)
                .month(endMonth)
                .week(weeks[index - 1])
                .day(6);
            lastWeekDay.add(6, "day");
        }

        calendar.push({
            week: index + 1,
            firstWeekDay: firstWeekDay.format("DD-MM-YYYY"),
            lastWeekDay: lastWeekDay.format("DD-MM-YYYY"),
            month: startMonth,
        });
    }

    return calendar;
}

export function formatChartLabels(
    value: string,
    advancedDate: {
        startDate: string;
        endDate: string;
    },
    period: string
) {
    if (advancedDate.startDate !== "" && advancedDate.endDate !== "") {
        let daysBetweenDates = moment
            .unix(Number(advancedDate.endDate))
            .diff(moment.unix(Number(advancedDate.startDate)), "days");

        if (daysBetweenDates === 0) {
            return `${value}h`;
        } else {
            return value;
        }
    } else if (
        [
            i18n.t("Current week"),
            i18n.t("The last 7 days"),
            i18n.t("The last 14 days"),
            i18n.t("The last 30 days"),
        ].includes(period)
    ) {
        return capitalize(moment.unix(Number(value)).format("ddd DD-MM-YY"));
    } else if ([i18n.t("Today"), i18n.t("Yesterday")].includes(period)) {
        return `${value}h`;
    } else if (period === i18n.t("Current month")) {
        return `${i18n.t(value)} ${capitalize(moment().format("MMM YYYY"))}`;
    }

    return value;
}

export function getUniqueArrayOfSimpleValues(array: Array<any>) {
    return Array.from(new Set(array));
}

export function formatExcelData(
    headers: string[],
    data: any[],
    percentageData: any[],
    averageLine: any[],
    type?: any
) {
    let alignment = {
        horizontal: "left",
    };

    let cellStyle = {
        font: { sz: "15" },
        border: {
            top: {
                style: "thin",
                color: { auto: 1 },
            },
            bottom: {
                style: "thin",
                color: { auto: 1 },
            },
            left: {
                style: "thin",
                color: { auto: 1 },
            },
            right: {
                style: "thin",
                color: { auto: 1 },
            },
        },
        alignment,
    };

    let titleStyle = {
        font: { sz: "15", bold: true },
        border: {
            top: {
                style: "thin",
                color: { auto: 1 },
            },
            bottom: {
                style: "thin",
                color: { auto: 1 },
            },
            left: {
                style: "thin",
                color: { auto: 1 },
            },
            right: {
                style: "thin",
                color: { auto: 1 },
            },
        },
    };

    let coloredHeaderStyle = {
        font: { sz: "15", bold: true, color: { rgb: "ffffff" } },
        border: {
            top: {
                style: "thin",
                color: { auto: 1 },
            },
            bottom: {
                style: "thin",
                color: { auto: 1 },
            },
            left: {
                style: "thin",
                color: { auto: 1 },
            },
            right: {
                style: "thin",
                color: { auto: 1 },
            },
        },
        fill: {
            patternType: "solid",
            fgColor: { rgb: "000" },
        },
    };

    return {
        columns: headers.map((header: string, index: number) => {
            if (index === 0) {
                return {
                    title: header,
                    width: { wch: header.length + 10 },
                    style: {
                        ...coloredHeaderStyle,
                        font: { sz: "15", bold: true },
                    },
                };
            }
            return {
                title: header,
                width: { wch: header.length + 10 },
                style: coloredHeaderStyle,
            };
        }),
        data: [
            ...data.map((datum: any) => {
                return datum.map((d: any, index: number) => {
                    if (index === 0) {
                        return {
                            value: d,
                            width: { wch: String(d).length + 10 },
                            style: titleStyle,
                        };
                    }
                    return {
                        value: d,
                        style: cellStyle,
                    };
                });
            }),
            [
                {
                    value: i18n.t("Total"),
                    style: {
                        ...titleStyle,
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: "808080" },
                        },
                        font: {
                            sz: "15",
                            bold: true,
                            color: { rgb: "ffffff" },
                        },
                    },
                },
                ...percentageData.map((datum: any) => ({
                    value: datum,
                    style: {
                        ...cellStyle,
                        fill: {
                            patternType: "solid",
                            fgColor: { rgb: "808080" },
                        },
                        font: { sz: "15", color: { rgb: "ffffff" } },
                    },
                })),
            ],
            type !== i18n.t("Average basket")
                ? [
                    {
                        value: i18n.t("Average"),
                        style: {
                            ...titleStyle,
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "a9a9a9" },
                            },
                        },
                    },
                    ...averageLine.map((datum: any) => ({
                        value: datum,
                        style: {
                            ...cellStyle,
                            fill: {
                                patternType: "solid",
                                fgColor: { rgb: "a9a9a9" },
                            },
                        },
                    })),
                ]
                : [],
        ],
    };
}

export function createNewStateComparedToPrevState(
    prevStateLabel: string,
    untranslatedLabel: string = "",
    translatedLabel: string = ""
) {
    if (prevStateLabel === translatedLabel) {
        return {
            label: untranslatedLabel,
            value: untranslatedLabel,
        };
    } else {
        return {
            label: translatedLabel,
            value: translatedLabel,
        };
    }
}

export function getCurrentLanguageItemTranslation(item: string = "") {
    const currentLanguage = i18n.language;

    if (currentLanguage === "en") {
        return Object.keys(frTranslation).filter((tr: string) => tr === item);
    } else {
        return Object.values(frTranslation).filter((tr: string) => tr === item);
    }
}

export function getAdvancedLegendParam(legend: string): string {
    switch (legend) {
        case i18n.t("Sales mode"):
            return "sales_mode";
        case i18n.t("Sales support"):
            return "sales_support";
        case i18n.t("Payment methods"):
            return "regulations";
        case i18n.t("Order city"):
            return "customer_city";
        case i18n.t("Families"):
            return "families";
        case i18n.t("Sub-families"):
            return "sub_families";
        case i18n.t("Products"):
            return "articles";
        default:
            return i18n.t("General");
    }
}

export function groupArrayByObjectKey(key: any) {
    return function (array: any) {
        return array.reduce((objectsByKeyValue: any, obj: any) => {
            const value = obj[key];
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(
                obj
            );
            return objectsByKeyValue;
        }, {});
    };
}

export function getAdvancedProductsMaxAvgBasketValue(data: any[]) {
    const groupByAvgBasket = groupArrayByObjectKey("average_basket");
    let localArray: number[] = Object.keys(groupByAvgBasket(data)).map(Number);

    return Math.max.apply(Math, localArray);
}

export function getAdvancedProductsAvgBasketRangeArray(data: any[]) {
    return [
        Math.min.apply(Math, data.flat().map(Number)),
        Math.max.apply(Math, data.flat().map(Number)),
    ];
}

export function getTableRowBackgroundColor(index: number): string {
    return [
        "rgb(205, 232, 244)",
        "rgb(251, 229, 209)",
        "rgb(220, 240, 214)",
        "rgb(239, 219, 243)",
    ][index];
}

export function removeArrayOfObjectsDuplicates(array: any[]) {
    let jsonObject = array.map((el: any) => JSON.stringify(el));

    let uniqueSet = new Set(jsonObject);

    return Array.from(uniqueSet).map((el: string) => JSON.parse(el));
}

export function mesurePasswordStrengthAsNumber(password: string): number {
    let passwordStrengthMeter: boolean =
        password.match(/[0-9]/g) !== null &&
        password.match(/[a-z]/g) !== null &&
        password.match(/[A-Z]/g) !== null &&
        password.match(/[^A-Za-z0-9]/g) !== null;

    const EMPTY_PASSWORD = 0;
    const WEAK_PASSWORD = 1;
    const FAIR_PASSWORD = 2;
    const GOOD_PASSWORD = 3;
    const STRONG_PASSWORD = 4;

    if (password.length === 0) {
        return EMPTY_PASSWORD;
    } else if (password.length === 8 && passwordStrengthMeter) {
        return FAIR_PASSWORD;
    } else if (
        password.length > 8 &&
        password.length < 12 &&
        passwordStrengthMeter
    ) {
        return GOOD_PASSWORD;
    } else if (password.length >= 12 && passwordStrengthMeter) {
        return STRONG_PASSWORD;
    } else {
        return WEAK_PASSWORD;
    }
}

export function getPasswordStrength(
    result: number
): {
    strength: string;
    color: string;
} {
    switch (result) {
        case 1:
            return { strength: i18n.t("Weak"), color: "danger" };
        case 2:
            return { strength: i18n.t("Fair"), color: "warning" };
        case 3:
            return { strength: i18n.t("Good"), color: "success" };
        case 4:
            return { strength: i18n.t("Strong"), color: "success" };
        default:
            return { strength: i18n.t("Weak"), color: "danger" };
    }
}

export function getAdvancedSeeMorePeriod(
    period: string,
    advancedDate: {
        startDate: string;
        endDate: string;
    }
) {
    if (advancedDate.startDate !== "" && advancedDate.endDate !== "") {
        return {
            startDate: advancedDate.startDate,
            endDate: advancedDate.endDate,
        };
    } else if (period === i18n.t("Today")) {
        return {
            startDate: String(Math.trunc(new Date().getTime() / 1000)),
            endDate: String(Math.trunc(new Date().getTime() / 1000)),
        };
    } else if (period === i18n.t("Yesterday")) {
        return {
            startDate: String(moment().subtract(1, "days").unix()),
            endDate: String(moment().subtract(1, "days").unix()),
        };
    } else if (period === i18n.t("Current week")) {
        return {
            startDate: String(moment().startOf("week").unix()),
            endDate: String(moment().endOf("week").unix()),
        };
    } else if (period === i18n.t("Current month")) {
        return {
            startDate: String(moment().startOf("month").unix()),
            endDate: String(moment().endOf("month").unix()),
        };
    } else if (period === i18n.t("Current year")) {
        return {
            startDate: String(moment().startOf("year").unix()),
            endDate: String(moment().endOf("year").unix()),
        };
    } else if (period === i18n.t("The last 7 days")) {
        return {
            startDate: getLastXDaysInTimestamp(7)[0],
            endDate: getLastXDaysInTimestamp(7)[6],
        };
    } else if (period === i18n.t("The last 14 days")) {
        return {
            startDate: getLastXDaysInTimestamp(14)[0],
            endDate: getLastXDaysInTimestamp(14)[13],
        };
    } else if (period === i18n.t("The last 30 days")) {
        return {
            startDate: getLastXDaysInTimestamp(30)[0],
            endDate: getLastXDaysInTimestamp(30)[29],
        };
    } else if (period === i18n.t("The last 90 days")) {
        return {
            startDate: getLastXDaysInTimestamp(90)[0],
            endDate: getLastXDaysInTimestamp(90)[89],
        };
    } else if (period === i18n.t("The last 180 days")) {
        return {
            startDate: getLastXDaysInTimestamp(180)[0],
            endDate: getLastXDaysInTimestamp(180)[179],
        };
    } else if (period === i18n.t("The last 365 days")) {
        return {
            startDate: getLastXDaysInTimestamp(365)[0],
            endDate: getLastXDaysInTimestamp(365)[364],
        };
    }

    return {
        startDate: String(moment().startOf("week").unix()),
        endDate: String(moment().endOf("week").unix()),
    };
}

export function isEmailValid(email: string) {
    return EMAIL_VALIDATION_REGEX.test(email);
}

export function formatDecimalNumber(
    number: number,
    minimumFractionDigits: number = 1,
    locale: string = "fr-FR"
): string {
    return number.toLocaleString(locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits,
    });
}

export function getUniqueId(length: number) {
    let result: string = "";
    let characters: string =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength: number = characters.length;
    for (let i: number = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
}
export function groupItemByLanguage(
    // eslint-disable-next-line
    items: any[],
    property: string
    // eslint-disable-next-line
): any[] {
    // eslint-disable-next-line
    return items.reduce(function (acc: any, obj: any) {
        if (!acc[property]) {
            acc[property] = [];
        }
        acc[property].push({
            ...obj.languages[property],
            id: obj.id,
            name: obj.name,
        });
        return acc;
    }, {})[property];
}

export function arrayGroupedString(arr: any) {
    let aux: any = [];
    arr.forEach((element: any) => {
        if (aux.indexOf(Number(element)) === -1) {
            aux.push(element);
        }
    });
    return aux;
}

export function getImageContentById(
    id: string,
    images: ImageItemType[],
    activeLanguage?: string
): string | undefined {
    const image = images.find((item: ImageItemType) => item.id === id);
    if (image !== undefined) {
        if (typeof image.content === "string") {
            return image.content;
        } else {
            // eslint-disable-next-line
            return image.content[activeLanguage!].path;
        }
    }
}

// eslint-disable-next-line
export function moveArrayElementToTheEnd(element: any, array: any[]) {
    const itemPositionInArray = array.findIndex((el) => el.id === element.id);

    return [
        ...array.slice(0, itemPositionInArray),
        ...array.slice(itemPositionInArray + 1, array.length),
        element,
    ];
}

// eslint-disable-next-line
export function moveArrayElementToTheBegin(element: any, array: any[]) {
    const itemPositionInArray = array.findIndex((el) => el.id === element.id);

    return [
        element,
        ...array.slice(0, itemPositionInArray),
        ...array.slice(itemPositionInArray + 1, array.length),
    ];
}

export function truncateString(string: string, maxLength: number): string {
    return string?.length > maxLength
        ? `${string?.slice(0, maxLength)}...`
        : string;
}

// @see https://bobbyhadz.com/blog/javascript-remove-duplicates-from-array-of-objects
export function removeDuplicatesFromArrayOfObjectsByKey(
    // eslint-disable-next-line
    array: any[],
    key: string
    // eslint-disable-next-line
): any[] {
    const uniqueSetByKey = new Set();

    return array.filter((element) => {
        const isDuplicate = uniqueSetByKey.has(element[key]);

        uniqueSetByKey.add(element[key]);

        if (!isDuplicate) {
            return true;
        }

        return false;
    });
}

export function getTopBannerImagePath(
    id: string,
    files: ImageItemType[]
): { url: string; type: string } | undefined {
    const topBannerBackgroundImageContent = getImageContentById(
        id as string,
        files
    );
    if (topBannerBackgroundImageContent !== undefined) {
        const image = files.find((item: ImageItemType) => item.id === id);
        const topBannerBackgroundImageSrc = {
            url:
                topBannerBackgroundImageContent?.includes("http") ||
                    topBannerBackgroundImageContent?.includes("https")
                    ? topBannerBackgroundImageContent
                    : `./images/${topBannerBackgroundImageContent}`,
            type:
                image !== undefined &&
                    image.type !== undefined &&
                    image.type.includes("video")
                    ? "video"
                    : "image",
        };
        return topBannerBackgroundImageSrc;
    }
}

export function getBackgroundPathById(
    id: string,
    files: ImageItemType[]
):
    | {
        url: string;
        type: string;
    }
    | undefined {
    const backgroundImageContent = getImageContentById(id as string, files);

    if (backgroundImageContent !== undefined) {
        const image = files.find((item: ImageItemType) => item.id === id);

        const localBackgroundImage = {
            url:
                backgroundImageContent?.includes("http") ||
                    backgroundImageContent?.includes("https")
                    ? backgroundImageContent
                    : `./images/${backgroundImageContent}`,
            type:
                image !== undefined &&
                    image.type !== undefined &&
                    image.type.includes("video")
                    ? "video"
                    : "image",
        };
        return localBackgroundImage;
    }
}
export function arrayDiff(arr1: any, arr2: any) {
    let res: any = [];
    let arrSup: any = [];
    let arrInf: any = [];
    if (arr1.length === arr2.length) {
        arrSup = arr1;
        arrInf = arr2;
    } else if (arr1.length > arr2.length) {
        arrSup = arr1;
        arrInf = arr2;
    } else {
        arrSup = arr2;
        arrInf = arr1;
    }
    for (let index = 0; index < arrSup.length; index++) {
        let el = arrSup[index];
        if (arrInf.indexOf(el) === -1) {
            res.push(el);
        }
    }
    return res;
}

export function decryptWord(wordToDecrypt: string): string {
    if (
        wordToDecrypt === "" ||
        wordToDecrypt === undefined ||
        wordToDecrypt === null
    )
        return "";
    let decryptedWord = wordToDecrypt;
    const key = crypto
        .createHash("sha256")
        .update(String(`${process.env.REACT_APP_SecurityKey}`), "utf8")
        .digest("hex")
        .substr(0, 32);
    const iv = crypto
        .createHash("sha256")
        .update(String(`${process.env.REACT_APP_InitVector}`), "utf8")
        .digest("hex")
        .substr(0, 16);
    const buff = Buffer.from(decryptedWord, "base64");
    decryptedWord = buff.toString("utf8");
    const decryptor = crypto.createDecipheriv(
        String("AES256"),
        // String(`${process.env.REACT_APP_Algorithm}`),
        key,
        iv
    );
    const decryptedData =
        decryptor.update(decryptedWord, "base64", "utf8") +
        decryptor.final("utf8");
    return decryptedData;
}

export function updateProjectByPath(data: any, path: any, value: any): void {
    const projectToUpdated = {
        ...JSON.parse(JSON.stringify(data)),
    };

    set(projectToUpdated, path.join("."), value);

    setProject(projectToUpdated);
}

export function getSalesSupportUpdated(
    oldData: any,
    newData: any,
    selectedSaleSupport: any,
    saleSupport: string
) {
    const diff = detailedDiff(oldData, newData);
    let isSaleSupportKIOSKDeleted = false;
    let isSaleSupportKIOSKAdded = false;
    let isSaleMethodHasSaleSupportKIOSK = false;

    let saleSupportDeleted: any = [];
    if (Object.keys(diff.deleted).length > 0) {
        if (Object.keys(diff.deleted).includes(saleSupport) === true) {
            Object.keys(diff.deleted[saleSupport]).forEach(
                (element: number) => {
                    saleSupportDeleted.push(oldData[saleSupport][element]);
                }
            );

            if (saleSupportDeleted.includes(selectedSaleSupport)) {
                isSaleSupportKIOSKDeleted = true;
            }
        }
    }
    if (Object.keys(diff.updated).length > 0) {
        if (Object.keys(diff.updated).includes(saleSupport) === true) {
            const saleSupportUpdated = Object.values(diff.updated[saleSupport]);

            if (saleSupportUpdated.includes(selectedSaleSupport) === false) {
                if (
                    oldData[saleSupport].includes(selectedSaleSupport) === true
                ) {
                    isSaleSupportKIOSKDeleted = true;
                } else {
                    isSaleSupportKIOSKDeleted = false;
                }
            } else if (
                saleSupportUpdated.includes(selectedSaleSupport) === true
            ) {
                isSaleSupportKIOSKAdded = true;
            }
        }
    }
    if (Object.keys(diff.added).length > 0) {
        if (
            Object.keys(diff.added).includes(saleSupport) &&
            Object.values(diff.added[saleSupport]) !== undefined
        ) {
            const saleSupportAdded = Object.values(diff.added[saleSupport]);
            if (saleSupportAdded.includes(selectedSaleSupport) === true) {
                isSaleSupportKIOSKAdded = true;
            }
        }
    }

    const modifiedDisplayName = detailedDiff(
        oldData.advancedDisplayName,
        newData.advancedDisplayName
    );
    if (
        isSaleSupportKIOSKAdded === false &&
        isSaleSupportKIOSKDeleted === false &&
        newData[saleSupport].includes("KIOSK") === true
    ) {
        return {
            modifiedDisplayName,
            isSaleSupportKIOSKAdded,
            isSaleSupportKIOSKDeleted,
            isSaleMethodHasSaleSupportKIOSK,
        };
    } else if (
        isSaleSupportKIOSKAdded === false &&
        isSaleSupportKIOSKDeleted === false &&
        newData[saleSupport].includes("KIOSK") === false
    ) {
        return {};
    } else {
        return {
            modifiedDisplayName,
            isSaleSupportKIOSKAdded,
            isSaleSupportKIOSKDeleted,
        };
    }
}

export function toCamelCase(word: string): string {
    return word.replace(/^([A-Z])|\s(\w)/g, function (
        match: any,
        p1: string,
        p2: string,
        offset: any
    ): string {
        if (p2) return p2.toUpperCase();
        return p1.toLowerCase();
    });
}

export async function getFidelityAndEditorPos(
    franchiseId: string,
    uuidUser: string
) {
    const getFidelityEditorPOSTypesUrl: Response = await fetch(
        `${process.env.REACT_APP_API_V2_URL}/settings/general/fidelity-pos-editor-types?franchiseId=${franchiseId}&uuidUser=${uuidUser}`,
        {
            method: "GET",
        }
    );

    const fideltyEditorPOSTypes = await getFidelityEditorPOSTypesUrl.json();
    let dataOfFidelity: any = {};
    let dataOfPosEditor: any = {};
    Object.entries(fideltyEditorPOSTypes.data).forEach(
        ([key, value]: [key: string, value: any]) => {
            dataOfFidelity = {
                ...dataOfFidelity,
                [key]: value.fidelityType ?? HAS_NO_LOYALTY,
            };

            dataOfPosEditor = {
                ...dataOfPosEditor,
                [key]: value.posEditor ?? POS_EDITOR_CESAR,
            };
        }
    );

    setFidelityType(dataOfFidelity);
    setPosEditorType(dataOfPosEditor);
}
