import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
    DynamicTable,
    customColumnProps,
} from "@maherunlocker/custom-react-table";
import { useSnapshot } from "valtio";

import { store } from "../../../../components/VerticalLayout/store";
import {
    store as storeThirdParty,
    setThirdPartyData,
    setFranchiseDataIsUpdated,
    setNumberActive,
    setNumberArchived,
    setListFranchiseArchived,
    setArchivedData,
    setIsHistoryModalOpened,
    setIsInlineErrorCleared,
    setIsActionsClicked,
    setIsEdited,
    setActions,
    setValidationAction,
    setSelectedThirdParties,
    setKey,
    setDataActive,
} from "../store";
import { store as StoreConfirmationActivationDeactivation } from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";

import ThirdPartyForm from "./ThirdPartyForm";
import HistoryModal from "../HistoryModal";
import { ConfirmationActiveThirdParty } from "./ConfirmationActiveThirdParty";
import { ConfirmationActivationDeactivationMessageModal } from "@components/Common/ConfirmationActivationDeactivationMessageModal";
import { useCallbackPrompt } from "@hooks/useCallbackPrompt";
import { removeArrayOfObjectsDuplicates } from "@helpers/general";
import { ConfirmationUpdatingDataMessageModal } from "@components/Common/ConfirmationUpdatingDataMessageModal";
import { ConfirmationInitializationMessage } from "@components/Common/ConfirmationInitializationMessage";
import { CustomSideFilter } from "./CustomSideFilter";
import { CustomIcons } from "./CustomIcons";
import { ConfirmationFavorite } from "./ConfirmationFavorite";
import { thirdPartyType } from "../Types";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";

export default function ThirdPartyFranchise({ props }: thirdPartyType) {
    const { t } = useTranslation();
    const { oneShop, userID, franchiseID, shopID } = useSnapshot(store);
    const { activationDeactivationMessageModalOpened } = useSnapshot(
        StoreConfirmationActivationDeactivation
    );
    const {
        franchiseDataIsUpdated,
        isHistoryModalOpened,
        isActionsClicked,
        isEdited,
        actions,
        validationAction,
        key,
    } = useSnapshot(storeThirdParty);
    const didMountRef = React.useRef(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
        isEdited
    );
    const shopId: string = oneShop ? shopID : "0";
    const [isAddThirdPartyClicked, setIsAddThirdPartyClicked] = useState(false);
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const [
        isAddNewThirdPartyButtonClicked,
        setIsAddNewThirdPartyButtonClicked,
    ] = useState(false);
    const [
        isInInitializationModalOpened,
        setIsInInitializationModalOpened,
    ] = React.useState<boolean>(false);
    const [filterActive, setLocalFilterActive] = React.useState<boolean>(false);
    const [thirdParty, setThirdParty] = useState<any>({});
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [data, setData] = React.useState<any>({});
    const [
        isModalConfirmationOpened,
        setIsModalConfirmationOpened,
    ] = React.useState<boolean>(false);
    const [isFavoriteModified, setIsFavoriteModified] = React.useState<boolean>(
        false
    );
    const [isConsult, setIsConsult] = React.useState<boolean>(false);
    const [, setDataState] = useState<any>({});
    const urlArchive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=archived`;
    const urlActive: string = `${process.env.REACT_APP_API_V2_URL}/settings/general/thirdParty?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}&type=activated`;
    const ActionColumn = (e: any) => {
        return e.selectedRow.subRows === undefined ? <div></div> : null;
    };
    let allDataShops =
        data?.othersData !== undefined && !props.isArchivedThirdPartyClicked
            ? data?.othersData?.allData?.data
            : [];

    let arrayOfCustomColumns: customColumnProps[] = [
        {
            indexOFColumn: 4,
            columnName: t("Actions"),
            customJsx: (e: any) => (
                <CustomIcons
                    data={{
                        selectedRowData: e,
                        setThirdParty,
                        setIsAddThirdPartyClicked,
                        setIsModalConfirmationOpened,
                        isModalConfirmationOpened,
                        setSelectedRows,
                        isArchivedThirdPartyClicked:
                            props.isArchivedThirdPartyClicked,
                        setLimit: props.setLimit,
                        setIsConsult,
                        setDataState,
                    }}
                />
            ),
        },
    ];
    React.useEffect(() => {
        setDataActive(data?.othersData?.dataActive);
    }, [data]);
    React.useEffect(() => {
        let localShop: string[] = [];

        selectedRows.forEach((el: any) => {
            localShop.push(el[t("Shop")]);
        });
        setNumberActive(
            data.othersData !== undefined ? data.othersData.activated : 0
        );
        setNumberArchived(
            data.othersData !== undefined ? data.othersData.archived : 0
        );

        setListFranchiseArchived(
            data.othersData !== undefined &&
                data.othersData.dataArchive !== undefined
                ? data?.othersData?.dataArchive.data
                : []
        );

        setArchivedData(
            data.othersData !== undefined &&
                data.othersData.archivedData !== undefined
                ? data.othersData.archivedData.data
                : []
        );
        setThirdPartyData(data.data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, props.isArchivedThirdPartyClicked]);

    React.useEffect(() => {
        let array: any = [];

        let associatedThirdParty: any[] = [];

        selectedRows.forEach((element: any) => {
            if (element[t("Shop")] !== undefined) {
                element.subRows.forEach((el: any) => {
                    array.push({ [el.shopId]: el.Id });
                    associatedThirdParty.push(el);
                });
            } else {
                array.push({ [element.shopId]: element.Id });
            }
        });

        setSelectedThirdParties(removeArrayOfObjectsDuplicates(array));
    }, [selectedRows, t]);

    React.useEffect(() => {
        if (filterActive) {
            setIsAddThirdPartyClicked(false);
        }
    }, [filterActive]);

    React.useEffect(() => {
        setActions(confirmNavigation);
        setIsActionsClicked(showPrompt as boolean);
        setValidationAction(cancelNavigation);
    }, [showPrompt, confirmNavigation, cancelNavigation]);

    React.useEffect(() => {
        setIsAddThirdPartyClicked(false);
        setThirdParty([]);
        if (didMountRef.current) {
            setFranchiseDataIsUpdated(true);
        } else {
            didMountRef.current = true;
        }
        setKey(Math.floor(Math.random() * 10000));
    }, [i18nextLng]);

    return (
        <React.Fragment>
            <div
                style={{
                    display: "grid",
                    minHeight: oneShop ? "650px" : "600px",
                    gridTemplateColumns: isAddThirdPartyClicked
                        ? "2fr 1fr "
                        : "auto",
                    gridColumnGap: "4px",
                    border: 0,
                }}
                key={key}
            >
                <DynamicTable
                    url={
                        props.isArchivedThirdPartyClicked
                            ? urlArchive
                            : urlActive
                    }
                    requestHeader={{ "Accept-Language": i18nextLng }}
                    canExpand={false}
                    canSelect
                    canResize
                    setData={setData}
                    customSelect
                    canMovedCheckboxLeftOnExpand
                    setSelectedRows={setSelectedRows}
                    customJsxSideFilterButton={
                        <CustomSideFilter
                            data={{
                                selectedRows,
                                thirdParty,
                                setIsInInitializationModalOpened,
                                setLocalFilterActive,
                                setIsAddThirdPartyClicked,
                                isAddThirdPartyClicked,
                                setIsModalConfirmationOpened,
                                isModalConfirmationOpened,
                                isArchivedThirdPartyClicked:
                                    props.isArchivedThirdPartyClicked,
                                setIsArchivedThirdPartyClicked:
                                    props.setIsArchivedThirdPartyClicked,
                                setLimit: props.setLimit,
                                setThirdParty,
                            }}
                        />
                    }
                    showGlobalFilter
                    showFilter
                    actionColumn={ActionColumn}
                    setLocalFilterActive={setLocalFilterActive}
                    filterActive={filterActive}
                    arrayOfCustomColumns={arrayOfCustomColumns}
                    setDataIsUpdated={
                        setFranchiseDataIsUpdated as
                            | React.Dispatch<
                                  React.SetStateAction<number | boolean>
                              >
                            | undefined
                    }
                    dataIsUpdated={franchiseDataIsUpdated}
                    elevationTable={1}
                    minHeight={oneShop ? "500px" : "450px"}
                    maxHeight={oneShop ? "500px" : "450px"}
                    name="thirdParty"
                />
                {isAddThirdPartyClicked ? (
                    <ThirdPartyForm
                        data={{
                            allDataShops,
                            isAddThirdPartyClicked,
                            setIsAddThirdPartyClicked,
                            editedData: thirdParty,
                            isAddNewThirdPartyButtonClicked,
                            setThirdPartyData: setThirdParty,
                            userData: {
                                userId: userID,
                                shopId: shopId,
                                franchiseId: franchiseID,
                            },
                            setLocalFilterActive,
                            setLimit: props.setLimit,
                            setIsConsult,
                            isConsult,
                        }}
                    />
                ) : null}
            </div>
            {isInInitializationModalOpened === true ? (
                <ConfirmationInitializationMessage
                    isModalOpened={isInInitializationModalOpened}
                    setIsModalOpened={setIsInInitializationModalOpened}
                    setIsClicked={setIsAddNewThirdPartyButtonClicked}
                    isClicked={isAddNewThirdPartyButtonClicked}
                    setIsEdited={setIsEdited}
                    setIsInlineErrorCleared={setIsInlineErrorCleared}
                />
            ) : null}
            {isHistoryModalOpened ? (
                <HistoryModal
                    data={{
                        isModalOpened: isHistoryModalOpened,
                        setIsModalOpened: setIsHistoryModalOpened,
                    }}
                />
            ) : null}
            {isModalConfirmationOpened === true ? (
                <ConfirmationActiveThirdParty
                    data={{
                        setIsModalConfirmationOpened,
                        isModalConfirmationOpened,
                        setDataIsUpdated: setFranchiseDataIsUpdated,
                        selectedRows,
                    }}
                />
            ) : null}
            {isFavoriteModified ? (
                <ConfirmationFavorite
                    data={{
                        isModalOpened: isFavoriteModified,
                        setIsModalOpened: setIsFavoriteModified,
                        selectedRowData: {},
                    }}
                />
            ) : null}

            {isActionsClicked === true ? (
                <ConfirmationUpdatingDataMessageModal
                    isModalOpened={isActionsClicked}
                    setIsModalOpened={setIsActionsClicked}
                    validationAction={validationAction}
                    actions={actions}
                    setIsEdited={setIsEdited}
                />
            ) : null}
            {activationDeactivationMessageModalOpened && oneShop && (
                <ConfirmationActivationDeactivationMessageModal />
            )}
        </React.Fragment>
    );
}
