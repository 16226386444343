import { diff } from "deep-diff";

export const getDiff = (type: any, kioskProject: any, initialProject: any) => {
    const kioskContent = JSON.parse(
        JSON.stringify(kioskProject.template.content[type])
    );
    const initialContent = JSON.parse(
        JSON.stringify(initialProject.template.content[type])
    );
    const includesItem = Object.keys(kioskContent).includes("items");
    let newData: any = [];

    if (includesItem) {
        // Crée un tableau newData en associant les éléments de kioskContent avec ceux de initialContent
        newData = kioskContent.items.reduce((acc: any[], el: any) => {
            const element = initialContent.items.find(
                (elt: any) => elt.id === el.id
            );
            if (element !== undefined) {
                acc.push({ ...element });
            }
            return acc;
        }, []);
    }

    // Calcule la différence entre les éléments de kioskContent et newData
    let difference: any = includesItem
        ? diff(newData, kioskContent.items) || []
        : [];

    let newDiff = [...difference];
    if (difference.length !== 0) {
        // Crée un nouveau tableau newDiff en mappant les éléments de difference et en ajoutant le chemin par défaut
        newDiff = difference.map((element: any) => {
            let defaultArray = ["template", "content", `${type}`, "items"];
            if (element.path !== undefined) {
                defaultArray.push(...element.path);
            }
            return { ...element, path: defaultArray };
        });
    }

    return newDiff;
};

// export const getDiff = (type: any, kioskProject: any, initialProject: any) => {
//     let newData: any = [];
//     if (
//         Object.keys(
//             JSON.parse(
//                 JSON.stringify(kioskProject.template.content[type] as any)
//             )
//         ).includes("items")
//     ) {
//         (kioskProject.template.content[type] as any).items.forEach(
//             (el: any) => {
//                 let element = (initialProject.template.content[
//                     type
//                 ] as any).items.find((elt: any) => elt.id === el.id);
//                 if (element !== undefined) {
//                     newData.push(JSON.parse(JSON.stringify(element)));
//                 }
//             }
//         );
//     }

//     let difference: any =
//         Object.keys(
//             JSON.parse(
//                 JSON.stringify(kioskProject.template.content[type] as any)
//             )
//         ).includes("items") &&
//         diff(
//             JSON.parse(
//                 JSON.stringify(
//                     (kioskProject.template.content[type] as any).items
//                 )
//             ),
//             newData
//         )
//             ? diff(
//                   newData,
//                   JSON.parse(
//                       JSON.stringify(
//                           (kioskProject.template.content[type] as any).items
//                       )
//                   )
//               )
//             : [];
//     let newDiff = [...difference];
//     if (difference.length !== 0) {
//         newDiff = difference.map((element: any) => {
//             let defaultArray = ["template", "content", `${type}`, "items"];
//             if (element.path !== undefined) {
//                 element.path.forEach((item: any) => defaultArray.push(item));
//             }
//             return { ...element, path: defaultArray };
//         });
//     }

//     return newDiff;
// };
