import React from "react";
import { useTranslation } from "react-i18next";
import {
    StyledLabel,
    StyledTextInput,
    StyledSelectInput,
} from "@aureskonnect/react-ui";
import { AvGroup } from "availity-reactstrap-validation";
import classnames from "classnames";
import { useSnapshot } from "valtio";

import { store } from "@components/VerticalLayout/store";
import { setIsEdited } from "../store";

import { CustomCardBody } from "@components/Common/CustomCardStyled";
import { clearData } from "../helper";
import AdoriaConfig from "./AdoriaConfig";
import KoustConfig from "./KoustConfig";
import { ThirdPartyBodyType } from "../Types";

import "@assets/swal.css";
import "@assets/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";

export function ThirdPartyBody({ data }: ThirdPartyBodyType) {
    const { t } = useTranslation();
    const { oneShop } = useSnapshot(store);
    return (
        <CustomCardBody
            className={`pl-3 pr-5 `}
            style={{
                maxHeight: "470px",
                overflowY: " auto",
            }}
        >
            <AvGroup className={`${data.isConsult ? "pe-none__clz" : ""}`}>
                <StyledLabel htmlFor="text" className="required__clz mt-3">
                    {t("Third party")}
                </StyledLabel>
                <StyledSelectInput
                    isClearable
                    isDisabled={data.editedData.Id !== undefined ? true : false}
                    options={data.generalThirdParties}
                    autoComplete="off"
                    placeholder={`${t("Select")}…`}
                    value={
                        data.thirdPartyLabel === ""
                            ? null
                            : {
                                  label: data.thirdPartyLabel,
                                  value: data.thirdPartyLabel,
                              }
                    }
                    onChange={(e: any) => {
                        clearData(
                            data.setThirdPartyLabel,
                            data.setIp,
                            data.setPort,
                            data.setSalesId,
                            data.setMenusId,
                            data.setBaseUrl,
                            data.setRemark,
                            data.setInputBaseUrl,
                            data.setInputMenusId,
                            data.setInputSalesId,
                            data.setInputPort,
                            data.setInputIp,
                            data.setApiKey,
                            data.setInputApiKey
                        );
                        data.setThirdPartyLabel(e.label);
                        setIsEdited(true);
                        data.setInputThirdPartyLabel(false);
                    }}
                    className={classnames("", {
                        invalid__clz:
                            data.inputThirdPartyLabel || data.isNameExist,
                        "not-allowed-input__clz":
                            data.editedData.id !== undefined &&
                            data.generalThirdParties.includes(
                                data.editedData[t("Third party")]
                            ),
                    })}
                    noOptionsMessage={() => t("No options")}
                    name={t("Third parties")}
                    autoFocus
                />
                {data.inputThirdPartyLabel ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("Please select a third party")}
                    </div>
                ) : null}
                {data.isNameExist ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("The third party is already exists")!}
                    </div>
                ) : null}
            </AvGroup>
            {(oneShop || data.isConfig) && data.thirdPartyLabel === "ADORIA" ? (
                <AdoriaConfig data={data} />
            ) : null}
            {(oneShop || data.isConfig) &&
            data.thirdPartyLabel !== "ADORIA" &&
            data.thirdPartyLabel !== "" ? (
                <KoustConfig data={data} />
            ) : null}
            <AvGroup className={`${data.isConsult ? "pe-none__clz" : ""}`}>
                <StyledLabel className="mt-3" htmlFor="name">
                    {t("Remark")}
                </StyledLabel>
                <StyledTextInput
                    autocomplete="off"
                    id="remark"
                    name="remark"
                    placeholder={t("Write")}
                    type="text"
                    onChange={(e: any) => {
                        data.setRemark(e.target.value);
                        setIsEdited(true);
                    }}
                    value={data.remark}
                />
            </AvGroup>
        </CustomCardBody>
    );
}
