import { setActivationDeactivationMessageModalOpened, setMessage, setYesFunction } from "@components/Common/ConfirmationActivationDeactivationMessageModal/store";
import { t } from "i18next";
import React from "react";
import { setActions, setIsActionsClicked, setIsEdited, setIsLoading } from "./store";
import ErrorToast from "@components/Common/ErrorTost";

export type thirdPartyFormType = {
    data: {
        allDataShops: any;
        isAddThirdPartyClicked: boolean;
        setIsAddThirdPartyClicked: Function;
        editedData: React.SetStateAction<any>;
        isAddNewThirdPartyButtonClicked: boolean;
        setThirdPartyData: Function;
        userData: {
            userId: string | number;
            shopId: string | number;
            franchiseId: string | number;
        };
        setLocalFilterActive: Function;
        setLimit: Function;
        isConsult: boolean;
        setIsConsult: Function;
    }
};

export const translatedThirdParty: any = {
    [t("ADORIA")]: {
        fr: "ADORIA",
        en: "ADORIA",
        nl: "ADORIA",
        es: "ADORIA",
        pt: "ADORIA",
        it: "ADORIA",
    },
    [t("KOUST")]: {
        fr: "KOUST",
        en: "KOUST",
        nl: "KOUST",
        es: "KOUST",
        pt: "KOUST",
        it: "KOUST",
    },
    [t("INPULSE")]: {
        fr: "INPULSE",
        en: "INPULSE",
        nl: "INPULSE",
        es: "INPULSE",
        pt: "INPULSE",
        it: "INPULSE",
    },
    [t("DVORE")]: {
        fr: "DVORE",
        en: "DVORE",
        nl: "DVORE",
        es: "DVORE",
        pt: "DVORE",
        it: "DVORE",
    },
};

export const advancedDisplayNameDefault = {
    languages: {
        fr: "",
        en: "",
        nl: "",
        es: "",
        pt: "",
        it: "",
    },
};
const createThirdPartyEntry = (
    shopId: any,
    isActive: Boolean,
    isFavorite: boolean,
    userID: any,
    franchiseID: any,
    operatorID: any,
    uuidUser: any,
    id: any,
    thirdParty: any,
    ip: any,
    portApi: any,
    salesId: any,
    menusId: any,
    baseUrl: any,
    remark: any,
    isArchived: boolean,
    apiKey: any

) => ({
    userId: userID,
    shopId: [shopId],
    franchiseId: franchiseID,
    operatorId: operatorID,
    uuidUser: uuidUser,
    data: {
        [id]: {
            id: id,
            thirdParty: thirdParty,
            ip: ip,
            portApi: portApi,
            salesId: salesId,
            menusId: menusId,
            baseUrl: baseUrl,
            remark: remark,
            shopId: shopId,
            isFavorite: false, //isFavorite Adjust as needed
            isActive: isActive,
            isArchived: isArchived,
            apiKey: apiKey
        },
    },
});

export function CreateDataThirdParty(
    oneShop: boolean,
    isMessageValidateClicked: boolean,
    shopId: any,
    isActive: Boolean,
    isFavorite: boolean,
    userID: any,
    franchiseID: any,
    operatorID: any,
    uuidUser: any,
    id: any,
    thirdParty: any,
    ip: any,
    portApi: any,
    salesId: any,
    menusId: any,
    baseUrl: any,
    remark: any,
    isArchived: boolean,
    apiKey: any,
    isEdit?: boolean,

) {
    let dataThirdParty;

    // Create the dataThirdParty based on conditions
    if (oneShop && (isMessageValidateClicked || (!isMessageValidateClicked && isArchived === true)) && isEdit === undefined) {
        dataThirdParty = [
            isMessageValidateClicked ? {
                ...createThirdPartyEntry(
                    shopId,
                    isActive,
                    isFavorite,
                    userID,
                    franchiseID,
                    operatorID,
                    uuidUser,
                    id,
                    thirdParty,
                    ip,
                    portApi,
                    salesId,
                    menusId,
                    baseUrl,
                    remark,
                    isArchived,
                    apiKey
                ), affectation: isMessageValidateClicked
            } : {
                ...createThirdPartyEntry(
                    shopId,
                    isActive,
                    isFavorite,
                    userID,
                    franchiseID,
                    operatorID,
                    uuidUser,
                    id,
                    thirdParty,
                    ip,
                    portApi,
                    salesId,
                    menusId,
                    baseUrl,
                    remark,
                    isArchived,
                    apiKey
                )
            }
        ];
    } else if (oneShop && !isMessageValidateClicked && isEdit === undefined) {
        dataThirdParty = [
            createThirdPartyEntry(
                "0",
                isActive,
                isFavorite,
                userID,
                franchiseID,
                operatorID,
                uuidUser,
                id,
                thirdParty,
                ip,
                portApi,
                salesId,
                menusId,
                baseUrl,
                remark,
                isArchived, apiKey
            ),
            createThirdPartyEntry(
                shopId,
                isActive,
                isFavorite,
                userID,
                franchiseID,
                operatorID,
                uuidUser,
                id,
                thirdParty,
                ip,
                portApi,
                salesId,
                menusId,
                baseUrl,
                remark,
                isArchived,
                apiKey
            ),
        ];
    } else {
        dataThirdParty =
            createThirdPartyEntry(
                shopId,
                isActive,
                isFavorite,
                userID,
                franchiseID,
                operatorID,
                uuidUser,
                id,
                thirdParty,
                ip,
                portApi,
                salesId,
                menusId,
                baseUrl,
                remark,
                isArchived, apiKey
            )
    }
    return dataThirdParty;
}


export const fechtingLanguageList = async (
    setLanguage: any,
    userID: any,
    shopId: any,
    franchiseID: any
) => {
    const i18nextLng = localStorage.getItem("i18nextLng") || "fr";
    const data: any = await (
        await fetch(
            `${process.env.REACT_APP_API_V2_URL}/settings/general/language?userId=${userID}&shopId=${shopId}&franchiseId=${franchiseID}`,
            {
                method: "GET",
                headers: { "Accept-Language": i18nextLng },
            }
        )
    ).json();

    const lang: any =
        data.data !== undefined
            ? data.data.map((element: any) => {
                return element;
            })
            : [];
    setLanguage(lang);
};

export const clearData = (
    setThirdPartyLabel: Function,
    setIp: Function,
    setPortApi: Function,
    setSalesId: Function,
    setMenusId: Function,
    setBaseUrl: Function,
    setRemark: Function,
    setInputBaseUrl: Function,
    setInputMenusId: Function,
    setInputSalesId: Function,
    setInputPort: Function,
    setInputIp: Function,
    setApiKey: Function,
    setInputApiKey: Function
) => {
    setThirdPartyLabel("");
    setIp("");
    setPortApi("");
    setSalesId("");
    setMenusId("");
    setBaseUrl("");
    setRemark("");
    setInputIp(false);
    setInputPort(false);
    setInputSalesId(false);
    setInputMenusId(false);
    setInputBaseUrl(false);
    setApiKey("")
    setInputApiKey(false)

};
export async function handleSwitchChange(e: any, data: any, isEdited: boolean, isActionsClicked: boolean, setChange: Function, setState: Function

) {
    if (isEdited === true) {
        setIsActionsClicked(!isActionsClicked);
        setActions(() => {
            if (data.selectedRowData.selectedRow.isFavorite) {
                ErrorToast(
                    t(
                        "Please note, you cannot deactivate a favorite third party"
                    )
                );
            } else {
                setIsEdited(false);
                data.setIsAddThirdPartyClicked(false);
                setMessage(
                    data.thirdPartyState
                        ? t(
                            "Caution, the Third party may be used by other modules.\n Would you like to confirm the deactivation of the selected Third party?"
                        )
                        : t(
                            "Would you like to confirm the activation of the selected Third party?"
                        )
                );
                setYesFunction(() => {
                    data.setThirdPartyState(!data.thirdPartyState);
                    setState(e);
                    setChange(true)
                    data.setDataState({
                        id: data.selectedRowData.selectedRow.Id,
                        state: !data.thirdPartyState,
                    });
                });
                setActivationDeactivationMessageModalOpened(true);
            }
        });
    } else {
        if (data.selectedRowData.selectedRow.isFavorite) {
            ErrorToast(
                t(
                    "Please note, you cannot deactivate a favorite third party"
                )
            );
        } else {
            data.setIsAddThirdPartyClicked(false);
            setMessage(
                data.thirdPartyState
                    ? t(
                        "Caution, the Third party may be used by other modules.\n Would you like to confirm the deactivation of the selected Third party?"
                    )
                    : t(
                        "Would you like to confirm the activation of the selected Third party?"
                    )
            );
            setYesFunction(() => {
                setIsLoading(true);
                data.setThirdPartyState(!data.thirdPartyState);
                setState(e);
                setChange(true); data.setDataState({
                    id: data.selectedRowData.selectedRow.Id,
                    state: !data.thirdPartyState,
                });
            });

            setActivationDeactivationMessageModalOpened(true);
        }
    }
}

